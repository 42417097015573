import colors from 'theme/colors'

const styles = {
	sideBar: {
		backgroundColor: colors.secondary.orchid,
		alignItems: 'center',
		paddingTop: '15px',
		paddingBottom: '15px',
		justifyContent: 'space-between',
		width: '200px',
		height: '100vh',
		position: 'fixed',
		top: 0,
		right: 0,
		zIndex: 1,
	},
}

export default styles
