import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Text,
  Button,
  ScrollView,
  FlatList,
  // TODO: dummy text added to move to next line eslint errors,
} from "native-base";
import { AiOutlinePlus } from "react-icons/ai";
import colors from "theme/colors";
import { CREATE_OPTIONS } from "global/constants";
import AmplitudeHelper from "utils/analytics";

const CreateItem = ({ item }) => {
  const { t } = useTranslation("common");
  return (
    <Link style={{ textDecorationLine: "none" }} to={item.path}>
      <Button
        variant="unstyled"
        justifyContent="flex-start"
        onPress={() => {
          AmplitudeHelper.logEvent(item.amplitudeEvent);
        }}
      >
        <Text variant="xs">{t(item.name)}</Text>
      </Button>
    </Link>
  );
};

const CreateContent = ({ isCollapsed, setIsCollapsed }) => (
  <ScrollView w="100%" h="100%" showsVerticalScrollIndicator={false}>
    <FlatList
      data={CREATE_OPTIONS}
      showsVerticalScrollIndicator={false}
      renderItem={({ item, index }) => (
        <CreateItem
          item={item}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
      )}
    />
  </ScrollView>
);

const AddEmployeeButton = (props) => {
  const { t } = useTranslation("common");
  const [isCollapsed, setIsCollapsed] = useState(true);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignSelf: "center",
      maxWidth: props.contentWidth || 400,
    },
    dropdown: {
      button: {
        width: "auto",
        height: "auto",
        border: `solid ${props.borderWidth || 1}px ${
          props.borderColor || "#d9d9d9"
        }`,
        borderRadius: 4,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 10,
        cursor: "pointer",
        backgroundColor: props.buttonColor || "white",
        zIndex: 0,
      },
    },
    collapsible: {
      width: props.contentWidth || 240,
      marginTop: "max(1.7%, 40px)",
      boxShadow: "0.5px 2px 10px #d1d1d1",
      backgroundColor: "white",
      position: "absolute",
      right: 2,
      zIndex: 1,
      overflowX: "hidden",
      overflowY: "hidden",
      overflow: "hidden",
      container: {
        maxWidth: props.contentWidth || 240,
        backgroundColor: props.contentBackgroundColor || "white",
        overflow: "hidden",
        overflowX: "hidden",
        overflowY: "hidden",
        transition: "all 0.2s ease-out",
        height: isCollapsed
          ? 0
          : props.maxContentHeight || CREATE_OPTIONS.length * 40,
      },
    },
    background: {
      height: "100vh",
      width: "100vw",
      backgroundColor: "rgba(52, 52, 52, 0.0)",
      position: "fixed",
      left: 0,
      top: 0,
      cursor: "default",
      zIndex: 0,
    },
  };

  return (
    <div style={styles.container}>
      <Button
        variant="solid"
        leftIcon={<AiOutlinePlus size="17px" color={colors.neutral.cotton} />}
        onPress={() => {
          AmplitudeHelper.logEvent("manage_employee_clicked");
          setIsCollapsed(!isCollapsed);
        }}
      >
        <Text variant="xs" color="white">
          {t("home.filter.add")}
        </Text>
      </Button>
      {!isCollapsed && (
        <div
          style={styles.background}
          onClick={() => {
            setIsCollapsed(true);
          }}
        />
      )}
      <div style={styles.collapsible}>
        <div style={styles.collapsible.container}>
          <CreateContent
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
          />
        </div>
      </div>
    </div>
  );
};

export default AddEmployeeButton;
