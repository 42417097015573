import { useEffect } from 'react';
import { getStore } from 'store/storeUtils';
import Modal from 'atoms/Modal';
import ToastAlert from 'atoms/ToastAlert';
import { LOGIN_MODALS } from 'global/constants';
import {
	Text, Button, VStack, Divider, useToast, Stack
} from 'native-base';
import colors from 'theme/colors';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { apiLogin } from 'api/apiCall';

const LoginModal = () => {
	const { t } = useTranslation('common')
	const toast = useToast()
	const { modal, resetModal, loginData } = getStore()
	const direction = ['column', 'column', 'column', 'row', 'row']

	const {
    mutate: performLogin,
    isSuccess,
    data,
    isError,
    error,
  } = useMutation({ mutationFn: (otpType) => apiLogin({otpType, mobileNumber: loginData.phoneNumber}) });

	useEffect(() => {
		if (isError) {
			toast.show({
				render: ({ id }) => <ToastAlert toast={toast} id={id} title={`errorCodes.${error}`} status="error" variant="solid" isClosable />,
				placement: 'top-right',
			})
		}
	}, [isError, error])

	useEffect(() => {
		if (isSuccess) {
			toast.show({
				render: ({ id }) => <ToastAlert toast={toast} id={id} title="login.otpResent" status="success" variant="solid" isClosable />,
				placement: 'top-right',
			})
			resetModal()
		}
	}, [isSuccess, data])

	return (
		<>
			{
				modal.type === LOGIN_MODALS.reset ?
					(
						<Modal height="200px" width="350px" title={t('modal.login.title')} reset={resetModal} closeable>
							<VStack space="3">
								<Divider />
								<VStack p="10px" space="3">
									<Text variant="xs">{t('modal.login.description')}</Text>
								</VStack>
								<Divider />
								<Stack direction={direction} alignItems="center" justifyContent="center" w="100%" space={3} flex={1} px="15px">
									<Button variant="whatsapp" onPress={() => performLogin('WHATSAPP')} flex={0.5} >
										<Text variant="xs" color={colors.neutral.cotton} textAlign="center">Whatsapp</Text>
									</Button>
									<Button variant="solid" onPress={() => performLogin('SMS')} flex={0.5} >
										<Text variant="xs" color={colors.neutral.cotton} textAlign="center">SMS</Text>
									</Button>
								</Stack>
							</VStack>
						</Modal>
					)
					:
					<></>
			}
		</>
	)
}

export default LoginModal
