import {
  HStack,
  Text,
  Center,
  FlatList,
  Button,
  Spinner,
  // TODO:  added to move to next line and remove eslint error
} from "native-base";
import colors from "theme/colors";
import { useTranslation } from "react-i18next";
import { getName, numberWithDotsAndEmptyCaseZero } from "utils/helpers";
import { VERIFY_STATUS_OPTIONS } from "global/constants";

const Cell = ({
  children = null,
  header = false,
  style = {},
  color = "",
  // TODO: Dummy text to move to next line
}) => {
  const tableCellWidth = ["120px", "110px", "110px", "110px", "185px"];
  return (
    <Center bg={header ? colors.neutral.mist : colors.neutral.cotton} py="10px">
      <Text
        variant={header ? "xs-bold" : "xs"}
        minW={tableCellWidth}
        color={color}
        style={[style || {}, { textAlign: "center" }]}
      >
        {children}
      </Text>
    </Center>
  );
};

const Header = () => {
  const { t } = useTranslation("common");

  const border = { borderRightWidth: 2, borderColor: "#c1c1c1" };
  return (
    <HStack
      justifyContent="space-between"
      style={{ backgroundColor: colors.neutral.mist }}
    >
      <Cell header style={{ ...border, minWidth: "75px" }}>
        {t("transactionPage.table.no")}
      </Cell>
      <Cell header style={border}>
        {t("transactionPage.table.withdrawalDate")}
      </Cell>
      <Cell header style={border}>
        {t("transactionPage.table.employeeId")}
      </Cell>
      <Cell header style={border}>
        {t("transactionPage.table.employeeName")}
      </Cell>
      <Cell header style={border}>
        {t("transactionPage.table.group")}
      </Cell>
      <Cell header style={border}>
        {t("transactionPage.table.withdrawalAmount")}
      </Cell>
      <Cell header style={border}>
        {t("transactionPage.table.purposeOfWithdrawal")}
      </Cell>
      <Cell header>{t("transactionPage.table.status")}</Cell>
    </HStack>
  );
};

const Row = (item) => {
  const row = item.row.item;
  const { currentPage } = item;
  const border = { paddingRight: 16 };
  const status =
    getName(row?.transactionStatus.toLowerCase(), VERIFY_STATUS_OPTIONS)
      ?.name ?? "-";
  const statusColor =
    getName(row?.transactionStatus.toLowerCase(), VERIFY_STATUS_OPTIONS)
      ?.color ?? "-";
  const txnDate = new Date(row?.transactionDate)
    .toLocaleString()
    .substring(0, 10);
  return (
    <HStack justifyContent="space-between" zIndex={2}>
      <Cell style={{ ...border, minWidth: "75px" }}>
        {item.row.index + 1 + (currentPage - 1) * 10}
      </Cell>
      <Cell style={border}>{txnDate}</Cell>
      <Cell style={border}>{row?.employeeCode}</Cell>
      <Cell style={border}>{row?.employeeName}</Cell>
      <Cell style={border}>{row?.employeeGroupName}</Cell>
      <Cell style={border}>
        {numberWithDotsAndEmptyCaseZero(row?.transactionAmount)}
      </Cell>
      <Cell style={border}>{row?.transactionCategoryName}</Cell>
      <Cell color={statusColor}>{status}</Cell>
    </HStack>
  );
};

const WithdrawalTable = ({
  data = {},
  isMinimize = false,
  loading = false,
  currentPage = 1,
}) => {
  const vHeight = window.innerHeight;
  const tableContainerHeight = isMinimize
    ? [
      vHeight < 700 ? "52%" : "67%",
      vHeight < 700 ? "52%" : "67%",
      "67%",
      "67%",
      "77%",
    ]
    : [
      vHeight < 700 ? "42%" : "62%",
      vHeight < 700 ? "42%" : "62%",
      "62%",
      "67%",
      "76%",
    ];

  return (
    <>
      {loading ? (
        <Spinner size="sm" />
      ) : (
        <FlatList
          data={data?.data?.data}
          ListHeaderComponent={Header}
          renderItem={(item) => <Row row={item} currentPage={currentPage} />}
          style={{
            marginHorizontal: "15px",
            overflowX: "scroll",
            paddingBottom: "35px",
            height: tableContainerHeight,
            borderBottomColor: colors.neutral.mist,
            borderBottomWidth: 1,
          }}
          maxHeight={tableContainerHeight}
        />
      )}
    </>
  );
};

export default WithdrawalTable;
