import colors from 'theme/colors';

const styles = {
	content: {
		backgroundColor: colors.neutral.mist,
		paddingHorizontal: '20px',
		paddingVertical: '20px',
		overflow: 'scroll'
	},
	breakpoints: {
		content: [1, 1, 1, 0.85, 0.85],
	},
	title: {color: colors.secondary.orchidBlue},
}

export default styles
