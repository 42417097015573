import { HStack, VStack } from "native-base";
import { Routes, Route } from "react-router-dom";
import TopBar from "atoms/TopBar";
import NavBar from "molecules/NavBar";
import routeData from "global/routes";
import { getStore } from "store/storeUtils";
import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import secureStore from "utils/secureStore";
import AmplitudeHelper from "utils/analytics";
import { SentryHelper } from "utils/performance/SentryHelper";

const Home = () => {
  const { userData } = getStore();
  useEffect(() => {
    (async () => {
      const token = await secureStore.getItemAsync("accessToken");
      const decodedToken = jwtDecode(token);
      AmplitudeHelper.setUserProperties({
        userId: decodedToken.userId,
        employerId: decodedToken.employerId,
        name: userData.userName,
      });
      SentryHelper.setUser(
        decodedToken.userId,
        decodedToken.employerId,
        userData.userName
      );
    })();
  }, []);
  return (
    <VStack>
      <TopBar
        name={userData?.userName ? userData?.userName.split(" ")[0] : ""}
      />
      <HStack height="92vh">
        <NavBar />
        <Routes>
          {routeData.map((route) =>
            route.children ? (
              <Route path={route.path} element={route.element}>
                {route.children.map((child) => (
                  <Route path={child.path} element={child.element} />
                ))}
              </Route>
            ) : (
              <Route path={route.path} element={route.element} />
            )
          )}
        </Routes>
      </HStack>
    </VStack>
  );
};
export default Home;
