// Base
import { useState } from "react";
import { HStack, Stack } from "native-base";
import { useTranslation } from "react-i18next";
import SummaryBox from "atoms/SummaryBox";
import TeamSummary from "molecules/TeamSummary";
import WalletType from "molecules/WalletType";
import ProgressBox from "atoms/ProgressBox";
import CheckStatusBox from "atoms/CheckStatusBox";

import styles from "./styles";
import { useConnect } from "./connect";

// Components
const RingkasanSection = ({ summaryData = {}, summaryLoading = false }) => {
  const { t } = useTranslation("common");
  const [ringkasanMinimize, setRingkasanMinimize] = useState(false);

  return (
    <SummaryBox
      title={t("home.summary.ringkasan")}
      shouldMinimize
      minimize={ringkasanMinimize}
      setMinimize={setRingkasanMinimize}
      height={ringkasanMinimize ? "70px" : "auto"}
      flexWrap="wrap"
      width="38%"
    >
      {ringkasanMinimize ? null : (
        <TeamSummary isLoading={summaryLoading} data={summaryData?.employee} />
      )}
    </SummaryBox>
  );
};

const TipeSection = ({ summaryData = {}, summaryLoading = false }) => {
  const { t } = useTranslation("common");
  const [tipeMinimize, setTipeMinimize] = useState(false);

  return (
    <SummaryBox
      title={t("home.summary.tipe")}
      shouldMinimize
      minimize={tipeMinimize}
      setMinimize={setTipeMinimize}
      h={tipeMinimize ? "70px" : "auto"}
      flex={1}
    >
      {tipeMinimize ? null : (
        <WalletType isLoading={summaryLoading} data={summaryData?.account} />
      )}
    </SummaryBox>
  );
};

const TeamPageSummary = () => {
  const { t } = useTranslation("common");
  const { summaryData, summaryLoading } = useConnect();
  return (
    <>
      <HStack space={5} style={styles.container} overflowY="hidden">
        <RingkasanSection
          summaryData={summaryData}
          summaryLoading={summaryLoading}
        />
        <TipeSection
          summaryData={summaryData}
          summaryLoading={summaryLoading}
        />
      </HStack>
      <HStack space={2} w="100%">
        <ProgressBox />
        <CheckStatusBox />
      </HStack>
    </>
  );
};

export default TeamPageSummary;
