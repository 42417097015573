import Breadcrumbs from "atoms/Breadcrumbs";
import ContentContainer from "atoms/ContentContainer";
import { VStack, ScrollView, Text, useToast } from "native-base";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import FormContainer from "atoms/FormContainer";
import { AiOutlineTeam } from "react-icons/ai";
import FormSubmit from "atoms/FormSubmit";
import DropzoneXlsx from "atoms/Dropzone";
import RenderBulkCreateSteps from "atoms/RenderBulkCreateSteps";
import { getStore } from "store/storeUtils";
import {
  BULK_TYPES,
  BULK_UPDATE_TEMPLATE_LINKS,
  CREATE_MODALS,
} from "global/constants";
import CreateModal from "molecules/CreateModal";
import { bulkEmployeesCreate } from "api/apiCall";
import { useMutation } from "@tanstack/react-query";
import ToastAlert from "atoms/ToastAlert";
import colors from "theme/colors";
import AmplitudeHelper from "utils/analytics";

const BulkUpdateStatus = () => {
  const { t } = useTranslation("common");
  const {
    modal,
    setModal,
    noOfFilesUploading,
    setNoOfFilesUploading,
    setIsFileUploading,
  } = getStore();
  const toast = useToast();

  const [selectedFile, setSelectedFile] = useState([]);
  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const {
    isSuccess: isCreateSuccess,
    isError: isCreateError,
    error: createError,
    mutate: bulkUpdateEmployee,
    data: bulkEmployeesCreateResponse,
  } = useMutation({ mutationFn: (data) => bulkEmployeesCreate(data) });

  useEffect(() => {
    AmplitudeHelper.logEvent("bulk_employee_update_status_page");
  }, []);

  useEffect(() => {
    setIsLoading(false);
    setIsFileUploading(false);
    if (isCreateSuccess) {
      AmplitudeHelper.logEvent("file_uploaded", {
        source: "bulk_employee_update_status_page",
      });
      toast.show({
        render: ({ id }) => (
          <ToastAlert
            toast={toast}
            id={id}
            title={`${noOfFilesUploading} ${t(
              "toolTip.documentUploadedSuccessfully"
            )}`}
            status="success"
            isClosable
            style={{
              backgroundColor: colors.success.polarGreen1,
              borderColor: colors.success.polarGreen3,
              borderWidth: 1,
            }}
          />
        ),
        placement: "top-right",
      });
      setSelectedFile([]);
      if (noOfFilesUploading > 0) setNoOfFilesUploading(noOfFilesUploading - 1);
    }

    if (isCreateError) {
      AmplitudeHelper.logEvent("file_upload_failed", {
        source: "bulk_update_status_cancel_pop_up_confirmation",
      });
      toast.show({
        render: ({ id }) => (
          <ToastAlert
            toast={toast}
            id={id}
            title="toolTip.uploadFailedDueToSystemError"
            status="error"
            isClosable
            style={{
              backgroundColor: colors.error.dustRed,
              borderColor: colors.error.rose,
              borderWidth: 1,
            }}
          />
        ),
        placement: "top-right",
      });
      if (noOfFilesUploading > 0) setNoOfFilesUploading(noOfFilesUploading - 1);
    }
  }, [isCreateSuccess, isCreateError]);

  const handleSubmit = () => {
    AmplitudeHelper.logEvent("save_button_clicked", {
      source: "bulk_employee_update_status_page",
    });
    setModal({
      ...modal,
      type: CREATE_MODALS.bulkCreate,
      screenType: BULK_TYPES.UPDATE_STATUS,
    });
  };

  const handleCancel = () => {
    AmplitudeHelper.logEvent("cancel_button_clicked", {
      source: "bulk_employee_update_status_page",
    });
    setModal({ ...modal, type: CREATE_MODALS.singleCreateCancel });
  };

  const handleSendData = () => {
    setIsLoading(true);
    setIsFileUploading(true);
    const formData = new FormData();
    formData.append("file", selectedFile[0]);
    formData.append("requestType", "update_employee_status");
    const currentNoOfFilesUploading = noOfFilesUploading + 1;
    setNoOfFilesUploading(currentNoOfFilesUploading);
    toast.show({
      render: ({ id }) => (
        <ToastAlert
          toast={toast}
          id={id}
          title={`${currentNoOfFilesUploading} ${t(
            "toolTip.documentIsBeingUploaded"
          )}`}
          status=""
          style={{
            backgroundColor: colors.tooltip.blueBody,
            borderColor: colors.tooltip.blueBorder,
            borderWidth: 1,
            textColor: colors.neutral.charcoal,
            color: colors.neutral.charcoal,
          }}
        />
      ),
      placement: "top-right",
    });
    bulkUpdateEmployee(formData);
  };

  return (
    <>
      <ContentContainer flex={[1, 1, 1, 0.85, 0.85]}>
        <Breadcrumbs
          currentPage="sideBar.bulkUpdateStatus"
          isFileUploading={isLoading}
        />
        <ScrollView pb="50px">
          <VStack space={5}>
            <FormContainer
              title="employeeUpdation.bulkUpdateStatus.title"
              description="employeeUpdation.bulkUpdateStatus.desc"
              icon={<AiOutlineTeam size={25} />}
            >
              <Text variant="xs">
                {t("employeeCreation.bulk.followTheseSteps")}
              </Text>
              <RenderBulkCreateSteps
                templateLink={BULK_UPDATE_TEMPLATE_LINKS.UPDATE_STATUS}
                screenType={BULK_TYPES.UPDATE_STATUS}
              />

              <DropzoneXlsx
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                errors={errors}
                setErrors={setErrors}
                isLoading={isLoading}
                screenType={BULK_TYPES.UPDATE_STATUS}
              />
            </FormContainer>
          </VStack>
        </ScrollView>
        <FormSubmit
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          isSubmitDisabled={Object.keys(selectedFile).length === 0 || isLoading}
          isBulk
        />
      </ContentContainer>
      <CreateModal handleSendData={handleSendData} />
    </>
  );
};

export default BulkUpdateStatus;
