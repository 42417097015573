import {
  View,
  Text,
  HStack,
  Button,
  Hidden,
  Stack,
  FlatList,
} from "native-base";
import { useTranslation } from "react-i18next";
import React from "react";
import { AiOutlineDownload } from "react-icons/ai";
import colors from "theme/colors";
import AmplitudeHelper from "utils/analytics";

const RenderBulkCreateSteps = ({ templateLink = "", screenType = "" }) => {
  const { t } = useTranslation("common");
  const steps = [
    "employeeCreation.bulk.selectDocumentTemplate",
    "employeeCreation.bulk.afterDownloadingFillDetailsAndExport",
    "employeeCreation.bulk.pleaseUploadFileInFormat",
  ];

  const handleDownload = async () => {
    AmplitudeHelper.logEvent("download_template_clicked", {
      source: `bulk_employee_${screenType}_page`,
    });

    fetch(templateLink)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Bulk_Employee_${screenType}.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
  };

  const renderSteps = (step) => {
    const { item = "", index = 0 } = step;
    return (
      <Stack mb="24px">
        <HStack space={2}>
          <View
            h="24px"
            w="24px"
            borderRadius="12px"
            bg={colors.secondary.orchid}
            alignItems="center"
            justifyContent="center"
            space={2}
          >
            <Text color={colors.neutral.cotton}>{index + 1}</Text>
          </View>
          <Text>{t(item)}</Text>
        </HStack>
        {index === 0 && (
          <HStack space={2} ml={8}>
            <Button
              variant="outline"
              rightIcon={<AiOutlineDownload size="17px" />}
              mt="8px"
              size="xs"
              onPress={handleDownload}
            >
              <Hidden till="md">
                <Text variant="xs">
                  {t("employeeCreation.bulk.downloadTemplate")}
                </Text>
              </Hidden>
            </Button>
          </HStack>
        )}
      </Stack>
    );
  };

  return (
    <FlatList
      data={steps}
      contentContainerStyle={{ marginTop: 24 }}
      renderItem={renderSteps}
    />
  );
};

export default RenderBulkCreateSteps;
