import { Text } from "native-base";
import React from "react";
import tenantConfig from "./tenantConfig";

function TextCurrency(props) {
  const { amountCurrency, showCurrencySymbol } = props;
  let numberValue = isNaN(amountCurrency)
    ? parseInt(amountCurrency, 10)
    : amountCurrency;

  numberValue = Math.floor(numberValue);

  const { currencySymbol, numberFormatting, currencyShortName } =
    tenantConfig.id;

  return (
    <Text testID="txtCurrency" sentry-label="txtCurrency" {...props}>
      {showCurrencySymbol ? currencySymbol : null}
      {numberValue.toLocaleString(numberFormatting, {
        currency: currencyShortName,
        string: "currency",
      })}
    </Text>
  );
}

export default TextCurrency;
