/* Root: Starting point. Conditionally renders based on auth */
import secureStore from 'utils/secureStore'
import { getStore } from 'store/storeUtils';
/* Root: Starting point for all the routing */
import {Routes, Route} from 'react-router-dom'

// Paths
import {
	HOME,
	LOGIN,
} from 'global/paths'

// Components
import Home from 'pages/Home'
import Login from 'pages/Login'

export const Root = () => {
	const storeAccessToken = secureStore.getItemAsync('accessToken')
	const {loginData} = getStore()

	return storeAccessToken == null || !loginData.isLoggedIn ? <Login /> : <Home />
}
