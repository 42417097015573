import { Box } from 'native-base'
import colors from 'theme/colors'
import DescriptionBox from 'atoms/DescriptionBox';

const FormContainer = ({
	title, icon, description, children 
}) => {
	return (
		<Box bg={colors.neutral.cotton} justifyContent="flex-start" p="30px" mx="10px" borderRadius={12} flexWrap="wrap">
			<DescriptionBox title={title} description={description} icon={icon}/>
			{children}
		</Box>
	)
}

export default FormContainer
