// Paths
import {
  TEAM,
  SINGLE_CREATE,
  TRANSACTIONS,
  IN_PROGRESS,
  SINGLE_UPDATE,
  IN_STATUS_CHECK,
  BULK_CREATE,
  BULK_UPDATE_STATUS,
  BULK_UPDATE,
} from "global/paths";

// Team
import TeamStack from "pages/TeamStack";
import Team from "pages/Team";
import SingleCreate from "pages/SingleCreate";
import SingleUpdate from "pages/SingleUpdate";
import InProgress from "pages/InProgress";
import Create from "pages/BulkCreateAndUpdate/create";
import BulkUpdate from "pages/BulkCreateAndUpdate/BulkUpdate";
import BulkUpdateStatus from "pages/BulkCreateAndUpdate/BulkUpdateStatus";

// Transactions
import Transactions from "pages/Transactions";

// Icons
import { BsFillPeopleFill } from "react-icons/bs";
import { TbChartHistogram } from "react-icons/tb";
import InStatusCheck from "pages/InStatusCheck";

const routeData = [
  {
    id: 1,
    name: "sideBar.manageTeam",
    element: <TeamStack />,
    path: TEAM,
    icon: <BsFillPeopleFill size="17px" style={{ marginBottom: "-3px" }} />,
    amplitudeEvent: "team_menu_clicked",
    children: [
      {
        id: 1,
        element: <Team />,
        path: TEAM,
      },
      {
        id: 2,
        element: <SingleCreate />,
        path: SINGLE_CREATE,
      },
      {
        id: 3,
        element: <InProgress />,
        path: IN_PROGRESS,
      },
      {
        id: 4,
        element: <SingleUpdate />,
        path: SINGLE_UPDATE,
      },
      {
        id: 5,
        element: <InStatusCheck />,
        path: IN_STATUS_CHECK,
      },
      {
        id: 6,
        element: <Create />,
        path: BULK_CREATE,
      },
      {
        id: 7,
        element: <BulkUpdate />,
        path: BULK_UPDATE,
      },
      {
        id: 8,
        element: <BulkUpdateStatus />,
        path: BULK_UPDATE_STATUS,
      },
    ],
  },
  {
    id: 2,
    name: "sideBar.transactionHistory",
    element: <Transactions />,
    path: TRANSACTIONS,
    icon: <TbChartHistogram size="17px" style={{ marginBottom: "-3px" }} />,
    amplitudeEvent: "transactions_menu_clicked",
  },
];

export default routeData;
