import URI from "urijs";
import secureStore from "utils/secureStore";
import axios from "axios";
import { SentryHelper } from "utils/performance/SentryHelper";
import config from "config";

const URITemplate = require("urijs/src/URITemplate");

export const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "x-api-key": "141414", // Reach out to Nalin for gg-web X-API key
};

export const axiosInstance = axios.create({
  baseURL: process.env.API_URL,
  headers: defaultHeaders,
});

export function shouldAddCustomHeaders(customHeaders) {
  if (customHeaders) {
    return {
      ...defaultHeaders,
      ...customHeaders,
    };
  }
  return {
    ...defaultHeaders,
  };
}

function getExpandedURL(sUrl, data) {
  const uriObj = URI.expand(sUrl, data.queryProps);
  const { _parts } = uriObj;
  const { protocol, hostname, path } = _parts;
  return `${protocol}://${hostname}${path}`;
}
export async function shouldAddTokenForAuth(options) {
  if (options.isAuthenticated) {
    const accessToken = await secureStore.getItemAsync("accessToken");
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  }
  return {};
}

export function getURLForApiCall(options, sUrl, data) {
  let result = sUrl;
  if (options?.hasQueryProps && options?.hasNamedProps) {
    result += URI.buildQuery(data.queryProps);
  } else if (options?.hasQueryProps && !options?.hasNamedProps) {
    result = getExpandedURL(sUrl, data);
  } else if (options?.hasMixedProps) {
    // This scenario is for api(s) like txn/reasons. Since it contains a mix of /param?key1={val1}&key2={val2}
    const mixedUriObj = URI.expand(sUrl, data.mixedProps);
    const { _parts } = mixedUriObj;
    const { protocol, hostname, path } = _parts;
    result = `${protocol}://${hostname}${path}?`;
    result += URI.buildQuery(data.queryProps);
  }
  return result;
}

export function getInvokeOptionsForHTTPMethod(
  postData,
  options,
  invokeOption,
  urlEncodedData
) {
  let result = { ...invokeOption }; // When post body is null - result get returned as null . therefore losing header . So default properties should be retained
  if (postData) {
    switch (options.httpMethod) {
      case "POST":
        result = urlEncodedData
          ? { ...invokeOption, data: urlEncodedData }
          : {
              ...invokeOption,
              data: options.stringifyRequest
                ? JSON.stringify(postData)
                : postData,
            };
        break;
      case "PUT":
        result = urlEncodedData
          ? { ...invokeOption, body: urlEncodedData }
          : {
              ...invokeOption,
              data: postData,
            };
        break;
      case "PATCH":
        result = urlEncodedData
          ? { ...invokeOption, body: urlEncodedData }
          : {
              ...invokeOption,
              data: postData,
            };
        break;
      case "DELETE":
        result = { ...invokeOption, body: JSON.stringify(postData) };
        break;
      default:
    }
  }
  return result;
}

async function retryApiWithRefreshedToken(result, response) {
  const { data } = result.data;
  await secureStore.setItemAsync("accessToken", data.accessToken);
  await secureStore.setItemAsync("refreshToken", data.refreshToken);
  // retry last api with refreshed token
  response.config.headers = {
    ...response.config.headers,
    Authorization: `Bearer ${data.accessToken}`,
  };
  return response;
}

async function refreshTokenCall() {
  const refreshToken = await secureStore.getItemAsync("refreshToken");
  return axios.post(`${config.API_URL}login/refresh`, undefined, {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });
}

function retryCallWithNewTokens(result, response) {
  const modifiedResponseWithHeaders = retryApiWithRefreshedToken(
    result,
    response
  );
  axiosInstance.defaults.headers = modifiedResponseWithHeaders;
  return axiosInstance(modifiedResponseWithHeaders.config);
}

function successData(response) {
  return response.data.count ? response.data : response.data.data;
}

export function axiosResponseInterceptor() {
  return async (response) => {
    if (response.status === 200) {
      // this is the httpstatus
      if (response.data.success) {
        return successData(response);
      }
      if (
        response.data.error &&
        response.data.error.length > 0 &&
        response.data.error[0] === "GG-GEN-17"
      ) {
        const result = await refreshTokenCall();
        // token refreshed successfully
        if (result?.data?.success) {
          return retryCallWithNewTokens(result, response);
        }
        // force logouot in case refresh token api fails
        throw response.data.error;
      }
      SentryHelper.captureException(response.data.error);
      throw response.data.error;
    }
    return null;
  };
}

axiosInstance.interceptors.response.use(
  axiosResponseInterceptor()
  // error => {},
);
