import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Hidden, Text, Button } from 'native-base'
import { AiFillFilter } from 'react-icons/ai'
import { BsChevronDown } from 'react-icons/bs'
import { getStore } from 'store/storeUtils'
import { TEAM_MODALS } from 'global/constants'

const ProgressFilterDropdown = ({isCollapsed, setIsCollapsed, children}) => {
	const { t } = useTranslation('common')
	// const [isCollapsed, setIsCollapsed] = useState(true)
	const { modal, setModal } = getStore()

	const styles = {
		container: {
			display: 'flex',
			flexDirection: 'column',
			alignSelf: 'center',
			maxWidth: 400,
		},
		dropdown: {
			button: {
				width: 'auto',
				height: 'auto',
				border: `solid ${1}px ${'#d9d9d9'}`,
				borderRadius: 4,
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				paddingLeft: 10,
				paddingRight: 10,
				cursor: 'pointer',
				backgroundColor: 'white',
				zIndex: 0,
			},
		},
		collapsible: {
			width: 350,
			marginTop: 'max(1.7%, 40px)',
			marginLeft: 1,
			boxShadow: '0.5px 2px 10px #d1d1d1',
			backgroundColor: 'white',
			position: 'absolute',
			zIndex: 1,
			container: {
				maxWidth: 350,
				backgroundColor: 'white',
				overflow: 'scroll',
				'overflow-x': 'hidden',
				transition: 'all 0.2s ease-out',
				height: isCollapsed ? 0 : 325,
			},
		},
		background: {
			height: '100vh',
			width: '100vw',
			backgroundColor: 'rgba(52, 52, 52, 0.0)',
			position: 'fixed',
			left: 0,
			top: 0,
			cursor: 'default',
			zIndex: 0,
		},
	}

	return (
		<div style={styles.container}>
			<Hidden till="md">
				<Button
					variant="outline"
					leftIcon={<AiFillFilter size="17px" />}
					rightIcon={<BsChevronDown size="17px" />}
					onPress={() => {
						setIsCollapsed(!isCollapsed)
					}}
				>
					<Text variant="xs">
						{' '}
						{t('home.filter.filter')}
					</Text>
				</Button>
			</Hidden>
			<Hidden from="md">
				<Button 
					variant="outline" 
					leftIcon={<AiFillFilter size="17px" />} 
					rightIcon={<BsChevronDown size="17px" />} 
					onPress={() => setModal({ ...modal, type: TEAM_MODALS.filter })} 
				/>
			</Hidden>

			<Hidden till="md">
				{!isCollapsed && (
					<div
						style={styles.background}
						onClick={() => {
							setIsCollapsed(true)
						}}
					/>
				)}
				<div style={styles.collapsible}>
					<div style={styles.collapsible.container}>
						{children}
					</div>
				</div>
			</Hidden>
		</div>
	)
}

export default ProgressFilterDropdown
