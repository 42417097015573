import { useState, useEffect } from 'react'
import { getStore } from 'store/storeUtils';
import { validate } from 'utils/validation';
import { useMutation } from '@tanstack/react-query';
import { apiOTP } from 'api/apiCall';
import secureStore from 'utils/secureStore';
import AmplitudeHelper from 'utils/analytics';
import { LOGIN_MODALS } from 'global/constants';

export const useConnect = () => {
	// Get and store phone number
  const [otp, setOtp] = useState(['', '', '', '']);  
	const [otpError, setOtpError] = useState({isError: false, errorMessage: ''})
	const {
 loginData, setLoginData, resetLoginData, userData, setUserData, modal, setModal 
} = getStore() 

	useEffect(() => { AmplitudeHelper.logEvent('otp_page') }, [])

	// Validation
	const {
    isLoading,
    mutate: verifyOTP,
    isSuccess,
    data: otpData,
    isError: isOtpError,
    error: otpErrorMessage,
  } = useMutation({
    mutationFn: () => {
      const payload = {
        otpPin: parseInt(otp.join(''), 10),
				token: loginData?.token,
      };
      return apiOTP(payload);
    },
  });

	useEffect(() => {
		setOtpError({isError: true, errorMessage: otpErrorMessage})
	}, [otpErrorMessage])

	useEffect(() => {
		if (isSuccess) {
			const data = otpData
			secureStore.setItemAsync('accessToken', data?.accessToken);
			secureStore.setItemAsync('refreshToken', data?.refreshToken);

			const newLoginData = {
				...loginData, 
				// loginSuccess: false,
				isLoggedIn: true,
			}
			setLoginData(newLoginData)

			const newUserData = {
				...userData,
				userName: data?.userName,
			}
			setUserData(newUserData)
		}
	}, [isSuccess, otpData])

	useEffect(() => {
		if (otp.join('').length == 0) {
			const err = {isError: false, errorMessage: ''}
			setOtpError(err)
		}
	}, [otp])

	const handleSubmit = () => {
		AmplitudeHelper.logEvent('login_attempted', {phone_number: loginData.phoneNumber})
		const validation = validate.otp(otp.join(''))
		if (validation != '') {
			AmplitudeHelper.logEvent('login_failed', {phone_number: loginData.phoneNumber, failure_message: validation})
			const err = {isError: true, errorMessage: validation}
			setOtpError(err)
		} else {
			const err = {isError: false, errorMessage: ''}
			setOtpError(err)	
			verifyOTP()
			if (isOtpError) {
				AmplitudeHelper.logEvent('login_failed', {phone_number: loginData.phoneNumber, failure_message: otpErrorMessage})
				const err = {isError: true, errorMessage: otpErrorMessage}
				setOtpError(err)
			} 		
		}
	}

	const handleReset = () => {
		AmplitudeHelper.logEvent('otp_resend_clicked', {phone_number: loginData.phoneNumber})
		setModal({...modal, type: LOGIN_MODALS.reset})
		// TOAST resend successful
		setOtp(['', '', '', ''])
	}

	// API Call
	return {
		otp,
		setOtp,
		otpError,
		setLoginData,
		loginData,
		resetLoginData,
		handleSubmit,
		handleReset,
		isLoading,
	}
}
