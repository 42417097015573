import {
Text, VStack, Button, FlatList,
} from 'native-base'
import colors from 'theme/colors'
import RouteButton from 'atoms/RouteButton'
import routeData from 'global/routes'
import {AiOutlineArrowRight} from 'react-icons/ai'
import { useEffect, useState } from 'react'
import secureStore from 'utils/secureStore'
import { getStore } from 'store/storeUtils'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {BsWhatsapp, BsPower} from 'react-icons/bs'
import { WHATSAPP_URL } from 'global/constants'
import styles from './styles'

const MobileNavBar = ({setDrawerOpen, drawerOpen}) => {
	const {t} = useTranslation('common')
	const {globalRoute, setGlobalRoute, resetLoginData} = getStore()
	const [currentRoute, setCurrentRoute] = useState(globalRoute)

	useEffect(() => {
		setGlobalRoute(currentRoute)
	}, [currentRoute])

	const logout = () => {
		secureStore.removeItemAsync('accessToken')
		secureStore.removeItemAsync('refreshToken')
		resetLoginData()
	}

	return (
		<>
			{drawerOpen 
			&& (
			<VStack style={styles.sideBar}>
				<VStack space={4} w="90%">
					<Button variant="rounded" bg={colors.variants.clover} w="30px" h="30px" onPress={() => setDrawerOpen(false)}>
						<Text variant="xs" color={colors.neutral.cotton}><AiOutlineArrowRight size="17px" style={{marginTop: '4px'}} /></Text>
					</Button>
					<FlatList 
							data={routeData} 
							renderItem={(item) => (
								<Link style={{textDecorationLine: 'none'}} to={item.item.path}>	
									<RouteButton route={item.item} currentRoute={currentRoute} setCurrentRoute={setCurrentRoute} />
								</Link>	
							)}
					/>
				</VStack>
				<VStack space={4} w="90%">
					<Button variant="rounded" bg={colors.variants.clover} w="90%" onPress={() => { open(WHATSAPP_URL) }}>
						<Text variant="xs" color={colors.neutral.cotton}>
							<BsWhatsapp size="15px" style={{marginBottom: '-3px'}} />
							{'  '}
							{t('sideBar.whatsappSupport')}
						</Text>
					</Button>
					<Button variant="rounded" bg={colors.secondary.orchid} w="90%" onPress={logout} borderWidth={1} borderColor={colors.neutral.cotton}>
						<Text variant="xs" color={colors.neutral.cotton}>
							<BsPower size="15px" style={{marginBottom: '-3px'}} />
							{'  '}
							Logout
						</Text>
					</Button>
				</VStack>
			</VStack>
			)}	
		</>
	)
}

export default MobileNavBar
