import { useTranslation } from "react-i18next";
import { HStack, Hidden, VStack } from "native-base";
import colors from "theme/colors";
import SummaryBox from "atoms/SummaryBox";
import SummaryBorderBox from "atoms/SummaryBorderBox";
import { BiSolidWalletAlt } from "react-icons/bi";
import { FaMoneyBill } from "react-icons/fa";

const WalletType = ({ isLoading, data }) => {
  const { t } = useTranslation("common");

  const sections = (
    <>
      <SummaryBorderBox
        color={colors.secondary.orchid}
        title={t("home.summary.ewa")}
        tooltip={t("toolTip.ewa")}
        data={
          data?.ewa
            ? `${data?.ewa} ${t("home.summary.employee")}`
            : `0 ${t("home.summary.employee")}`
        }
        loading={isLoading}
        width="150px"
        icon={<BiSolidWalletAlt color={colors.secondary.orchid} size="13px" />}
        tooltipAmplitude="ewa_card"
      />
      <SummaryBorderBox
        color={colors.primary.carnation}
        title={t("home.summary.uangKantor")}
        tooltip={t("toolTip.uangKantor")}
        loading={isLoading}
        data={
          data?.uangKantor
            ? `${data?.uangKantor} ${t("home.summary.employee")}`
            : `0 ${t("home.summary.employee")}`
        }
        width="150px"
        icon={<FaMoneyBill color={colors.primary.carnation} size="13px" />}
        tooltipAmplitude="uang_kantor_card"
      />
      <SummaryBorderBox
        color={colors.variants.clover}
        title={t("home.summary.salaryDisbursement")}
        tooltip={t("toolTip.salaryDisbursement")}
        loading={isLoading}
        data={
          data?.salaryDisbursement
            ? `${data?.salaryDisbursement} ${t("home.summary.employee")}`
            : `0 ${t("home.summary.employee")}`
        }
        width="150px"
        icon={<FaMoneyBill color={colors.variants.clover} size="13px" />}
        tooltipAmplitude="salary_disbursement_card"
      />
    </>
  );
  return (
    <>
      <Hidden till="sm">
        <HStack space={5} flexWrap="wrap" px="25px" py="5px">
          {sections}
        </HStack>
      </Hidden>
      <Hidden from="sm">
        <VStack space={5} px="25px" py="25px">
          {sections}
        </VStack>
      </Hidden>
    </>
  );
};

export default WalletType;
