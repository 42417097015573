import {
  BULK_CREATE,
  BULK_UPDATE,
  BULK_UPDATE_STATUS,
  SINGLE_CREATE,
} from "global/paths";

import colors from "theme/colors";

export const WHATSAPP_URL =
  "https://api.whatsapp.com/send?&phone=6281315276948";
// "https://api.whatsapp.com/send?&phone=6282116266407"; - TODO: incorrect url added

export const TEAM_MODALS = {
  changeStatus: "CHANGE_STATUS",
  export: "EXPORT",
  filter: "FILTER",
};

export const TXN_MODALS = {
  export: "EXPORT_DATA",
  filter: "FILTER_DATA",
};

export const CREATE_MODALS = {
  singleCreate: "continue",
  singleCreateCancel: "cancel",
  export: "CREATE_EXPORT",
  bulkCreate: "BULK_CREATE",
  fileIsUploading: "FILE_IS_UPLOADING",
};

export const LOGIN_MODALS = {
  reset: "RESET",
};

export const ACCOUNT_TYPE_FILTER = {
  ewa: {
    code: "EWA",
    name: "Akun Gaji",
  },
  uangKantor: {
    code: "UANG_KANTOR",
    name: "Uang Kantor",
  },
  salaryDisbursement: {
    code: "SALARY_DISBURSEMENT",
    name: "Penalangan Gaji",
  },
  points: {
    code: "POINTS",
    name: "POINTS Account",
  },
};

export const STATUS_FILTER = {
  active: {
    code: "active",
    name: "home.list.rows.active",
  },
  withdraw_suspended: {
    code: "withdraw_suspended",
    name: "home.list.rows.inactive",
  },
  withdraw_accrual_suspended: {
    code: "withdraw_accrual_suspended",
    name: "home.list.rows.inactive",
  },
  inactive: {
    code: "inactive",
    name: "home.list.rows.inactive",
  },
};

export const REASONS_DATA = [
  {
    id: 4,
    name: "Data Review",
  },
  {
    id: 5,
    name: "Dismissal",
  },
  {
    id: 6,
    name: "Duplicate/ Incorrect Data",
  },
  {
    id: 8,
    name: "Employer Action",
  },
  {
    id: 11,
    name: "Fraud",
  },
  {
    id: 22,
    name: "Resignation",
  },
];

export const CREATE_OPTIONS = [
  {
    id: 1,
    name: "sideBar.singleCreate",
    path: SINGLE_CREATE,
    amplitudeEvent: "single_employee_creation_clicked",
  },
  {
    id: 2,
    name: "sideBar.bulkCreate",
    path: BULK_CREATE,
    amplitudeEvent: "bulk_employee_creation_clicked",
  },
  {
    id: 3,
    name: "sideBar.bulkUpdate",
    path: BULK_UPDATE,
    amplitudeEvent: "bulk_employee_update_clicked",
  },
  {
    id: 4,
    name: "sideBar.bulkUpdateStatus",
    path: BULK_UPDATE_STATUS,
    amplitudeEvent: "bulk_employee_update_status_clicked",
  },
];

export const GENDER_OPTIONS = [
  { id: 1, name: "Pria", data: "MALE" },
  { id: 2, name: "Wanita", data: "FEMALE" },
];

export const STATUS_OPTIONS = [
  { id: 1, name: "Aktif", data: "active" },
  { id: 2, name: "Non Aktif", data: "inactive" },
];

export const VERIFY_STATUS_OPTIONS = [
  // {
  //   id: 1,
  //   name: 'Semua',
  //   data: 'completed,pending,rejected,failed',
  //   color: colors.success.cactus
  // },
  {
    id: 2,
    name: "Disetujui",
    data: "completed",
    color: colors.success.cactus,
  },
  {
    id: 3,
    name: "Menunggu",
    data: "pending",
    color: colors.warning.sunflower,
  },
  {
    id: 4,
    name: "Ditolak",
    data: "rejected",
    color: colors.error.rose,
  },
  {
    id: 5,
    name: "Gagal",
    data: "failed",
    color: colors.error.rose,
  },
];

export const ACTION_OPTIONS = [
  { id: 1, name: "Penambahan Data", data: "create_employee" },
  { id: 2, name: "Perubahan Data Karyawan", data: "update_employee" },
  { id: 3, name: "Perubahan Data Status", data: "update_employee_status" },
];

export const TYPE_OPTIONS = [
  { id: 1, name: "Kerah Biru", data: "BLUE" },
  { id: 2, name: "Kerah Putih", data: "WHITE" },
];

export const BANK_OPTIONS = [
  { id: 1, name: "Bank BCA" },
  { id: 2, name: "Bank HSBC" },
];

export const TRANSACTION_STATUS_TYPES = {
  REJECTED: "REJECTED",
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  // SUCCESS: "SUCCESS", TODO: commented as PRODUCT has requested that these Txn status types will not be used
  // CANCELLED: "CANCELLED",
  // NOT_STARTED: "NOT_STARTED",
};

export const TRANSACTION_REASON_TYPES = [
  {
    name: "eMoney",
    id: "BP-15",
  },
  {
    name: "Konsultasi Dokter",
    id: "BP-18",
  },
  {
    name: "Listrik postpaid",
    id: "BP-17",
  },
  {
    name: "Paket Data",
    id: "BP-07",
  },
  {
    name: "Pascabayar",
    id: "BP-08",
  },
  {
    name: "PDAM",
    id: "BP-06",
  },
  {
    name: "Pembelian Emas",
    id: "GD-01",
  },
  {
    name: "Penarikan Gaji",
    id: "WD-01",
  },
  {
    name: "Pulsa",
    id: "BP-10",
  },
  {
    name: "Token PLN",
    id: "BP-05",
  },
  {
    name: "Transfer",
    id: "WD-02",
  },
  {
    name: "Voucher",
    id: "BP-13",
  },
  {
    name: "Zakat Fitrah",
    id: "BP-29",
  },
  {
    name: "e-Kasbon",
    id: "LP-01",
  },
];

export const EMPLOYEE_UPDATE_REQUEST_STATUS = {
  PENDING: "pending",
  COMPLETED: "completed",
  REJECTED: "rejected",
  FAILED: "failed",
  PROCESSING: "processing",
};

export const BULK_UPDATE_TEMPLATE_LINKS = {
  CREATE:
    "https://bulk-create-employees-stage.s3.ap-southeast-3.amazonaws.com/Template_Bulk_Upload_Banyak_Karyawan.xlsx",
  UPDATE:
    "https://bulk-create-employees-stage.s3.ap-southeast-3.amazonaws.com/Template_Ubah_Data_Beberapa_Karyawan.xlsx",
  UPDATE_STATUS:
    "https://bulk-create-employees-stage.s3.ap-southeast-3.amazonaws.com/Template_Ubah_Beberapa_Status_Karyawan.xlsx",
};

export const BULK_TYPES = {
  CREATE: "create",
  UPDATE: "update",
  UPDATE_STATUS: "update_status",
};

/*
Sample Employee record

{
    "employeeId": 2,
    "userId": 6,
    "employeeCode": "2",
    "employeeName": "pankaj",
    "employeeGroup": null,
    "employeeStatus": "active",
    "withdrawalLimit": 0,
    "payDate": "28",
    "employeeAccounts": [
        "Akun Gaji",
        "POINTS Account"
    ]
}

Sample Progress Record
{
    "id": 192,
    "employerId": 577,
    "employeeId": null,
    "userId": null,
    "requestType": "create_employee",
    "requestedBy": 175971,
    "status": "pending",
    "statusReason": null,
    "statusUpdatedAt": "2023-09-15T16:59:20.366Z",
    "statusUpdatedBy": 175971,
    "firstName": "Desy",
    "lastName": "",
    "birthDate": "1980-01-11",
    "mobileNumber": "081755832028",
    "gender": "FEMALE",
    "ktpNumber": "9583920958673829",
    "employeeCode": "SINGLE555",
    "joinedAt": "2023-09-13",
    "collarType": "WHITE",
    "groupName": "Product",
    "departmentName": null,
    "designationName": null,
    "salaryType": "FIXED_MONTH",
    "payDate": "-1",
    "salaryAmount": 3000000,
    "bankName": "Bank BCA",
    "bankAccountNo": "08559448291",
    "beneficiaryName": "Desy",
    "employeeStatus": null,
    "fileName": null,
    "fromData": null,
    "toData": null,
    "createdAt": "2023-09-15T16:59:20.366Z",
    "updatedAt": "2023-09-15T16:59:20.366Z",
    "employee": null
},
*/
