import { useRef, useEffect } from 'react';
import AmplitudeHelper from 'utils/analytics';

export const useConnect = ({otp, setOtp, phoneNumber}) => {
  const inputRefs = useRef([]);
  // const [otp, setOtp] = useState(['', '', '', '']);  
  useEffect(() => {
    if (otp.join('').length == 0) {
      for (let i = 0; i < inputRefs.current.length; i++) {
        inputRefs.current[i].setNativeProps({ text: '' });
        if (i === 0) {
          inputRefs.current[i].focus();
        }
      }
    }
  }, otp)

  const handleInputChange = (index, value) => {
    AmplitudeHelper.logEvent('otp_input', {phone_number: phoneNumber})
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);    
    
    if (value) {
      if (index < 3) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleBackspace = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = '';

    if (!value && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleKeyPress = (e, index) => {
    if (e.nativeEvent.key === 'Backspace') {
      handleBackspace(index, e.target.value);
    }
  };

	return {inputRefs, handleInputChange, handleKeyPress}
}
