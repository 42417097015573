import { Button, HStack, Text } from "native-base";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import AmplitudeHelper from "utils/analytics";
import TxnPageFilterDropdown from "atoms/TxnPageFilterDropdown";
import { BiTrashAlt } from "react-icons/bi";
import colors from "theme/colors";
import { useTranslation } from "react-i18next";
import { BsArrowRight } from "react-icons/bs";

const TransactionFilterSection = ({
  txnDates = {},
  setTxnDates = () => {},
  onResetPress = () => {},
  filterData = {},
  setFilterData = () => {},
}) => {
  const [startDate, setStartDate] = useState(txnDates?.startDate);
  const [endDate, setEndDate] = useState(txnDates?.endDate);

  const { t } = useTranslation("common");

  const resetPress = () => {
    setStartDate("");
    setEndDate("");
    onResetPress();
    AmplitudeHelper.logEvent("filter_reset", {
      source: "transaction_page",
    });
    setFilterData({});
  };

  const setEndDateAndRecallApi = (newValue) => {
    AmplitudeHelper.logEvent("date_range_clicked", {
      source: "transaction_page",
      start_date: startDate,
      end_date: newValue,
    });
    setEndDate(newValue);
    setTxnDates({
      startDate,
      endDate: newValue,
    });
  };

  useEffect(() => {
    if (startDate !== "" && startDate !== txnDates.startDate) {
      setEndDate("");
    }
  }, [startDate]);

  const changeStartDate = (newValue) => {
    AmplitudeHelper.logEvent("date_range_chosen", {
      source: "transaction_page",
    });
    setStartDate(newValue);
  };

  return (
    <HStack alignItems="center" flexWrap="wrap">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <HStack space={4} marginTop={1} flexWrap="wrap" maxW="90%">
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <DatePicker
              openTo="day"
              views={["year", "month", "day"]}
              value={dayjs(startDate)}
              onChange={changeStartDate}
              slotProps={{
                textField: {
                  size: "small",
                  error: false,
                },
              }}
              renderInput={(params) => (
                <TextField {...params} helperText={null} />
              )}
            />
          </div>
          <BsArrowRight style={{ marginTop: 20 }} />
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <DatePicker
              openTo="day"
              views={["year", "month", "day"]}
              value={dayjs(endDate)}
              slotProps={{
                textField: {
                  size: "small",
                  error: false,
                },
              }}
              onChange={setEndDateAndRecallApi}
              renderInput={(params) => (
                <TextField {...params} helperText={null} />
              )}
            />
          </div>
        </HStack>
      </LocalizationProvider>

      <div style={{ marginLeft: 10, marginTop: 3 }}>
        <TxnPageFilterDropdown
          filterData={filterData}
          setFilterData={setFilterData}
          onResetPress={resetPress}
        />
      </div>

      <Button
        variant="outline"
        flex="0.25"
        minWidth="80px"
        minHeight="40px"
        style={{ marginLeft: 10, marginTop: 3 }}
        borderColor={colors.error.rose}
        leftIcon={<BiTrashAlt size="15px" color={colors.error.rose} />}
        onPress={resetPress}
      >
        <Text variant="sm" color={colors.error.rose}>
          {t("filter.reset")}
        </Text>
      </Button>
    </HStack>
  );
};

export default TransactionFilterSection;
