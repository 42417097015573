import { useEffect, useState } from "react";
import { getStore } from "store/storeUtils";
import Modal from "atoms/Modal";
import { TEAM_MODALS, TXN_MODALS } from "global/constants";
import {
  Text,
  Button,
  HStack,
  Radio,
  Input,
  VStack,
  Divider,
  useToast,
} from "native-base";
import colors from "theme/colors";
import ReasonDropdown from "molecules/ReasonDropdown";
import { IoMdClose } from "react-icons/io";
import {
  apiUpdateStatus,
  apiExport,
  apiToExportTransactionsList,
} from "api/apiCall";
import { useMutation } from "@tanstack/react-query";
import ToastAlert from "atoms/ToastAlert";
import FilterContent from "molecules/FilterContent";
import { useTranslation } from "react-i18next";
import AmplitudeHelper from "utils/analytics";
import TxnPageFilterContent from "atoms/TxnPageFilterContent";
import { getFormattedEndDate, getFormattedStartDate } from "utils/helpers";

const TeamModal = ({
  filterData = {},
  setFilterData = () => {},
  name = "",
  txnDates = {},
}) => {
  const toast = useToast();
  const { t } = useTranslation("common");
  const { modal, resetModal } = getStore();
  const [status, setStatus] = useState("active");
  const [reason, setReason] = useState("");
  const [reasonDetail, setReasonDetail] = useState("");
  const [email, setEmail] = useState("");

  const bodyData = {
    employeeStatus: status === "active" ? "active" : "withdraw_suspended",
    suspendedReason: reasonDetail,
    suspensionCategory: reason,
  };
  const {
    isSuccess: statusSuccess,
    mutate: updateStatus,
    isError: isStatusError,
    error: statusError,
  } = useMutation({
    mutationFn: () => {
      return apiUpdateStatus(
        { employeeId: modal?.data?.employeeId ?? 0 },
        bodyData
      );
    },
  });

  const {
    isSuccess: exportSuccess,
    mutate: performExport,
    isError: isExportError,
    data: exportData,
    error: exportError,
  } = useMutation({ mutationFn: () => apiExport({ email }) });

  const {
    isLoading,
    mutate: performExportTransactions,
    isSuccess: exportTransactionListSuccess,
    data: transactionListData,
    isError: isExportTransactionListError,
    error: exportTransactionListError,
  } = useMutation({
    mutationFn: (payload) => apiToExportTransactionsList(payload, { email }),
  });

  useEffect(() => {
    if (modal?.data?.email && modal?.data?.email != "") {
      setEmail(modal?.data?.email);
    }
    if (modal?.data?.employeeStatus && modal?.data?.employeeStatus != "") {
      setStatus(modal?.data?.employeeStatus);
    }
  }, [modal]);

  useEffect(() => {
    if (exportTransactionListSuccess || exportSuccess) {
      toast.show({
        render: ({ id }) => (
          <ToastAlert
            toast={toast}
            id={id}
            title="transactionPage.downloadDataSuccess"
            status="success"
            variant="solid"
            isClosable
          />
        ),
        placement: "top-right",
      });
      resetModal();
    }
  }, [
    exportTransactionListSuccess,
    exportSuccess,
    transactionListData,
    exportData,
  ]);

  useEffect(() => {
    if (isExportError) {
      toast.show({
        render: ({ id }) => (
          <ToastAlert
            toast={toast}
            id={id}
            title={`errorCodes.${exportError}`}
            status="error"
            variant="solid"
            isClosable
          />
        ),
        placement: "top-right",
      });
    }
  }, [isExportError, exportError]);

  useEffect(() => {
    if (isExportError) {
      toast.show({
        render: ({ id }) => (
          <ToastAlert
            toast={toast}
            id={id}
            title={`errorCodes.${exportError}`}
            status="error"
            variant="solid"
            isClosable
          />
        ),
        placement: "top-right",
      });
    }
  }, [isExportError, exportError]);

  useEffect(() => {
    if (isStatusError) {
      toast.show({
        render: ({ id }) => (
          <ToastAlert
            toast={toast}
            id={id}
            title={`errorCodes.${statusError}`}
            status="error"
            variant="solid"
            isClosable
          />
        ),
        placement: "top-right",
      });
    }
  }, [isStatusError, statusError]);

  useEffect(() => {
    if (statusSuccess) {
      toast.show({
        render: ({ id }) => (
          <ToastAlert
            toast={toast}
            id={id}
            title="home.statusChange.success"
            status="success"
            variant="solid"
            isClosable
          />
        ),
        placement: "top-right",
      });
      resetModal();
    }
  }, [statusSuccess]);

  useEffect(() => {
    if (isExportTransactionListError) {
      toast.show({
        render: ({ id }) => (
          <ToastAlert
            toast={toast}
            id={id}
            title="home.export.success"
            status="success"
            variant="solid"
            isClosable
          />
        ),
        placement: "top-right",
      });
      resetModal();
    }
  }, [isExportTransactionListError, exportTransactionListError]);

  const resetAndCloseModal = () => {
    resetModal();
  };

  const sendDownloadRequest = () => {
    const data = {};
    if (name != "") {
      data.search = name;
    }

    if (txnDates?.startDate != "" && txnDates?.endDate != "") {
      data.fromTransactionDate = getFormattedStartDate(txnDates?.startDate);
      data.toTransactionDate = getFormattedEndDate(txnDates?.endDate);
    }

    if (filterData?.fromTransactionAmount != "") {
      data.fromTransactionAmount = filterData?.minAmount;
    }
    if (filterData?.toTransactionAmount != "") {
      data.toTransactionAmount = filterData?.maxAmount;
    }
    if (filterData?.employeeGroup && filterData?.employeeGroup != []) {
      data.employeeGroupName = filterData?.employeeGroup.join(",");
    }
    if (filterData?.txnStatus != [] && filterData?.txnStatus) {
      data.transactionStatus = filterData?.txnStatus.join(",");
    }
    if (filterData.withdrawalPurpose != [] && filterData?.withdrawalPurpose) {
      data.transactionCategoryId = (filterData?.withdrawalPurpose || [])
        .map((i) => i?.id)
        .join(",");
    }
    AmplitudeHelper.logEvent("send_download_clicked", {
      source: "transaction_page",
      email_address: email,
    });
    if (!modal?.shouldFiltersApply) {
      performExportTransactions();
    } else {
      performExportTransactions(data);
    }
  };

  const setEmailAddress = (e) => {
    setEmail(e.target.value);
  };

  const shouldSaveBeDisabled = (status) => {
    if (modal?.data?.employeeStatus !== "" && modal?.data?.employeeStatus) {
      return status === modal?.data?.employeeStatus;
    }
    return status === "active";
  };

  const onChangeStatus = (status) => {
    AmplitudeHelper.logEvent(
      status == "active" ? "active_status_clicked" : "suspend_status_clicked",
      {
        source: "team_page",
        employee_status_input: status,
        employee_id: modal?.data?.employeeId,
      }
    );
    setStatus(status);
    shouldSaveBeDisabled(status);
  };

  const RenderModal = () => {
    switch (modal.type) {
      case TEAM_MODALS.changeStatus:
        return (
          <Modal title="Ubah Status">
            <VStack space="2" px="10px">
              <Text variant="xs">Status:</Text>
              <Radio.Group value={status} onChange={onChangeStatus}>
                <HStack space="3">
                  <HStack space={2} alignItems="center">
                    <Radio value="active">
                      <Text variant="xs">Aktif</Text>
                    </Radio>
                  </HStack>
                  <HStack space={2} alignItems="center">
                    <Radio value="inactive">
                      <Text variant="xs">Non-Aktif</Text>
                    </Radio>
                  </HStack>
                </HStack>
              </Radio.Group>
              <Text variant="xs">Alasan Non-Aktif (Wajib Diisi):</Text>
              <ReasonDropdown reason={reason} setReason={setReason} />
              <Text variant="xs">Jika Pilih Lainnya Tulis Detail Alasan:</Text>
              <Input
                placeholder="Masukkan Detail Alasan"
                value={reasonDetail}
                onChange={(x) => {
                  setReasonDetail(x.target.value);
                }}
                onFocus={() => {
                  AmplitudeHelper.logEvent("detail_suspend_clicked", {
                    source: "team_page",
                    employee_id: modal?.data?.employeeId,
                  });
                }}
                onEndEditing={() => {
                  AmplitudeHelper.logEvent("detail_suspend_filled", {
                    source: "team_page",
                    employee_id: modal?.data?.employeeId,
                    detail_suspended: reasonDetail,
                  });
                }}
              />
            </VStack>
            <HStack
              space={2}
              width="100%"
              bottom="3"
              position="absolute"
              zIndex={-999}
              px="10px"
            >
              <Button
                variant="outline"
                onPress={() => {
                  AmplitudeHelper.logEvent("status_changes_canceled", {
                    source: "team_page",
                    employee_id: modal?.data?.employeeId,
                  });
                  resetModal();
                }}
                flex="0.25"
              >
                <Text variant="xs">Cancel</Text>
              </Button>
              <Button
                variant="solid"
                flex="0.75"
                isDisabled={shouldSaveBeDisabled(status)}
                onPress={() => {
                  AmplitudeHelper.logEvent("save_button_clicked", {
                    source: "team_page",
                    employee_id: modal?.data?.employeeId,
                  });
                  updateStatus();
                }}
              >
                <Text variant="xs" color={colors.neutral.cotton}>
                  Save
                </Text>
              </Button>
            </HStack>
          </Modal>
        );
      case TEAM_MODALS.export:
        return (
          <Modal
            height="270px"
            title={t("modal.export.title")}
            reset={resetModal}
            closeable
          >
            <VStack space="3">
              <Divider />
              <VStack p="15px" space="3">
                <Text variant="xs">{t("modal.export.description")}</Text>
                <Input
                  placeholder={t("modal.export.placeholder")}
                  value={email}
                  onChange={(x) => setEmail(x.target.value)}
                  onEndEditing={() => {
                    AmplitudeHelper.logEvent("email_address_filled", {
                      source: "team_page",
                      email_address: email,
                    });
                  }}
                />
              </VStack>
              <Divider />
              <Button
                variant="solid"
                mx="15px"
                onPress={() => {
                  AmplitudeHelper.logEvent("send_download_clicked", {
                    source: "team_page",
                    email_address: email,
                  });
                  performExport();
                }}
                isDisabled={email == ""}
              >
                <Text variant="xs" color={colors.neutral.cotton}>
                  {t("modal.export.button")}
                </Text>
              </Button>
            </VStack>
          </Modal>
        );
      case TEAM_MODALS.filter:
        return (
          <Modal title="Pilih Bedasarkan" reset={resetModal} closeable>
            <FilterContent
              filterData={filterData}
              setFilterData={setFilterData}
              resetModal={resetModal}
            />
          </Modal>
        );
      case TXN_MODALS.export:
        return (
          <Modal
            height="270px"
            title={t("modal.export.title")}
            reset={resetAndCloseModal}
            closeable
          >
            <VStack space="3">
              <Divider />
              <VStack p="15px" space="3">
                <Text variant="xs">{t("modal.export.description")}</Text>
                <Input
                  placeholder={t("modal.export.placeholder")}
                  value={email}
                  onPressOut={() => {
                    AmplitudeHelper.logEvent("email_address_filled", {
                      source: "transaction_page",
                      email_address: email,
                    });
                  }}
                  onChange={setEmailAddress}
                  onEndEditing={() => {
                    AmplitudeHelper.logEvent("email_address_filled", {
                      source: "txn_page",
                      email_address: email,
                    });
                  }}
                />
              </VStack>
              <Divider />
              <Button
                variant="solid"
                mx="15px"
                onPress={sendDownloadRequest}
                isDisabled={email == ""}
              >
                <Text variant="xs" color={colors.neutral.cotton}>
                  {t("modal.export.button")}
                </Text>
              </Button>
            </VStack>
          </Modal>
        );
      case TXN_MODALS.filter:
        return (
          <Modal title="Pilih Bedasarkan" reset={resetModal} closeable>
            <TxnPageFilterContent
              filterData={filterData}
              setFilterData={setFilterData}
              resetModal={resetModal}
            />
          </Modal>
        );
      default:
        <></>;
    }
  };

  return <>{RenderModal()}</>;
};

export default TeamModal;
