const sInternalError = "Internal error";
const sPayloadBadRequest = "Payload bad request";
const sConflictData = "Konflik data";
const sPayloadBadRequestID = "Permintaan payload tidak valid";
const sInternalErrorID = "Kesalahan internal";

const en = {
  errorCodes: {
    "GG-BENE-01": "Record conflict",
    "GG-BENE-02": sPayloadBadRequest,
    "GG-BENE-03": "Already exists",
    "GG-BENE-04": "Allowed enrolled beneficiary reached",
    "GG-BENE-05": "Deletion not allowed",
    "GG-CON-OTP-01": "Otp token required",
    "GG-CON-OTP-02": "Otp token invalid",
    "GG-CON-OTP-03": "Otp token already verified",
    "GG-CON-OTP-04": "Please try again or re-send the code",
    "GG-CON-OTP-05": "Otp token not found",
    "GG-CON-OTP-06":
      "Verification code is invalid.Please try again or re-send the code",
    "GG-CON-OTP-07":
      "Too much request for verification code. Please wait 1 hour to try again",
    "GG-CON-OTP-08": "Please wait 1 hour to try again",
    "GG-EMP-01": "Employee not found",
    "GG-EMP-02": "Employee status inactive",
    "GG-EMP-03": "Employee suspended",
    "GG-EMP-04": "Employee employer suspended",
    "GG-EMP-05": "Employee transaction is locked",
    "GG-EMP-06": "Employee employer inactive",
    "GG-EMPYER-01": "Employer not found",
    "GG-EMPYER-02": "Employer status inactive",
    "GG-EMPYER-03": "Employer suspended",
    "GG-EMPYER-04": "Employer save failed",
    "GG-EMPYER-05": "Conficting Record",
    "GG-EMPYER-LOGIN-01": "Email not valid",
    "GG-EMPYER-LOGIN-02": "Email or Password incorrect",
    "GG-EMPYER-LOGIN-03": "Employer suspended",
    "GG-EMPYER-LOGIN-04": "Employer status inactive",
    "GG-GEN-01": "Bad Request",
    "GG-GEN-02": "Too many request",
    "GG-GEN-03": "Api Not found",
    "GG-GEN-04": sInternalError,
    "GG-GEN-05": "Unauthorized JWT",
    "GG-GEN-06": "Unauthorized AppKey",
    "GG-GEN-07": "Unauthorized feature flag",
    "GG-GEN-08": "Response timeout",
    "GG-GEN-09": "Conficting Record",
    "GG-GEN-10": "Limit parameter error",
    "GG-GEN-11": "Skip parameter error",
    "GG-GEN-12": "Limit parameter invalid",
    "GG-GEN-13": "Skip parameter invalid",
    "GG-GOLD-01": "Invalid Request Data",
    "GG-GOLD-02": "Profile Already Exist",
    "GG-GOLD-03": "Treasury Profile Not Found",
    "GG-OTP-01": "Phone number not valid",
    "GG-OTP-02": "Phone number is blocked",
    "GG-OTP-03": "Invalid app key",
    "GG-OTP-04": "Resend exhausted",
    "GG-OTP-05": "Otp sms service error",
    "GG-OTP-06": "Messaging Type invalid",
    "GG-OTP-07": "Phone number not registered",
    "GG-OTP-08":
      "Too much request for verification code. Please wait 1 hour to try again",
    "GG-PHONE-01": sPayloadBadRequest,
    "GG-PHONE-02": "Already exists",
    "GG-PHONE-03": "Allowed enrolled phone number reached",
    "GG-PHONE-04": "Deletion not allowed",
    "GG-PRODUCT-01": sInternalError,
    "GG-PRODUCT-02": "Product not found",
    "GG-PRODUCT-03": "Provider is feature flag",
    "GG-PRODUCT-04": "Product unavailable",
    "GG-SIGNUP-01": "Record already exists",
    "GG-SIGNUP-02": sInternalError,
    "GG-SIGNUP-03": sPayloadBadRequest,
    "GG-SIGNUP-04": "Invalid Employer Name",
    "GG-SIGNUP-05": "Invalid User Name",
    "GG-SIGNUP-06": "Invalid Phone Number payload",
    "GG-SIGNUP-07": "Invalid Identity type",
    "GG-SIGNUP-08": "Invalid Identity id",
    "GG-TRX-01": "User in lock period",
    "GG-TRX-02": "Employee transaction suspended",
    "GG-TRX-03": "Employee category blocked",
    "GG-TRX-04": "Account blocked for category",
    "GG-TRX-05": "Insufficient Balance",
    "GG-TRX-06": sInternalError,
    "GG-TRX-07": "Provider error",
    "GG-TRX-08": "Product not found",
    "GG-TRX-09": "Product unavailable",
    "GG-USER-01": "User not found",
    "GG-USER-02": "User status inactive",
    "GG-USER-03": "User suspended",
    "GG-USER-04": "Failed to retrieve user info",
    "GG-USER-05": "User KTP Not Found",
    "GG-VAL-01":
      "Employee Status is invalid. employeeStatus must be active, inactive, withdraw_suspended, and withdraw_accrual_suspended",
    "GG-VAL-02": "Invalid format for minAmount. Should be a number",
    "GG-VAL-03": "Invalid format for maxAmount. Should be a number",
    "GG-VAL-04": "Invalid format for payDate. Should be a string",
    "GG-VAL-05":
      "accountType must be EWA, POINTS, GOLD, SALARY_DISBURSEMENT, UANG_KANTOR, UANG_KAS, SD_OFFLINE",
    "GG-VAL-06":
      "Invalid format for employeeGroup. Should be an array of strings",
    "GG-VAL-07": "Invalid format for search. Should be a string",
    "GG-VAL-08":
      "Invalid format for employeeCode. Should be an array of string",
    "GG-VAL-09":
      "Invalid format for secondaryEmployeeGroup. Should be an array of string",
    "GG-VAL-10": "Invalid format for employeeId. Should be a number",
    "GG-VAL-11": "Invalid format for suspensionCategory. Should be string",
    "GG-VAL-12": "Invalid format for suspendedReason. Should be string",
    "GG-VAL-13": "Invalid email format",
    "GG-VAL-14":
      "Invalid format for fromTransactionDate. Should be a date string",
    "GG-VAL-15":
      "Invalid format for toTransactionDate. Should be a date string",
    "GG-VAL-16":
      "Invalid value for transaction status. Transaction status must be PENDING, COMPLETED, REJECTED, FAILED",
    "GG-VAL-17":
      "Invalid format for transactionCategoryId. Should be an array of string",
    "GG-VAL-18": "Invalid format for fromTransactionAmount. Should be a number",
    "GG-VAL-19": "Invalid format for toTransactionAmount. Should be a number",
    "GG-VAL-20": "Invalid format for partnerId. Should be a number",
    "GG-VAL-21": "Invalid format for employeeCode. Should be string",
    "GG-VAL-22":
      "Invalid format for employee request status. request status must be pending, completed, processing, failed or rejected",
    "GG-VAL-23": 'collar type must be either "BLUE" or "WHITE"',
    "GG-VAL-24": "Invalid format for userId. Should be number",
    "GG-VAL-25": "Invalid format for firstName. Should be string",
    "GG-VAL-26": "Invalid format for lastName. Should be string",
    "GG-VAL-27": "Invalid format for request type. Should be string",
    "GG-VAL-28": "Invalid format for birthDate. Should be string",
    "GG-VAL-29": "Invalid format for mobile number. Should be string",
    "GG-VAL-30": "Invalid format for gender. Should be string",
    "GG-VAL-31": "Invalid format for ktp number. Should be string",
    "GG-VAL-32": "Invalid format for joined at. Should be string",
    "GG-VAL-33": "Invalid format for group name. Should be string",
    "GG-VAL-34": "Invalid format for department name. Should be string",
    "GG-VAL-35": "Invalid format for designation name. Should be string",
    "GG-VAL-36":
      "Invalid format for salary type id. SalaryType must be MONTHLY, DAILY, WEEKLY, WORK_UNIT, HOURLY, BIWEEKLY, FIXED_MONTH, FIXED_LENGTH, NEW_WEEKLY, BI_MONTHLY",
    "GG-VAL-37": "Invalid format for salary amount. Should be number",
    "GG-VAL-38": "Invalid format for employees_bank_name. Should be string",
    "GG-VAL-39":
      "Invalid format for employees bank account no. Should be string",
    "GG-VAL-40": "Invalid format for beneficiary name. Should be string",
    "GG-VAL-41": "Invalid format for file name. Should be string",
    "GG-VAL-42": "Invalid format for employee name. Should be string",
    "GG-VAL-43":
      "Invalid format for employee request sort by. sort by must be employeeCode, employeeName or uploadedAt",
    "GG-VAL-44":
      "Invalid format for employee request sort order. sort order must be asc or desc",
    "GG-EMP-REQ-01": "Invalid employee request type",
    "GG-EMP-REQ-02": "Employee Update request already exists",
    "GG-EMP-REQ-03": "No data to be updated",
    "GG-EMP-REQ-04": "Suspension Category not provided",
    "GG-EMP-REQ-05": "Employee request not found",
    "GG-EMP-REQ-06": "Failed to upload file",
    "GG-EMP-REQ-07": "Employer Id not found",
    "GG-EMP-REQ-08": "Employer not found",
    "GG-EMP-REQ-09": "Phone number is invalid",
    basicValidation: {
      lettersOnly: "Please use only letters",
      tooLong: "Too long",
      tooShort: "Too short",
      numbersOnly: "Use numbers only",
      noMinus: "No minus symbol",
      noZeros: "Cannot Send 0",
      invalidPhone: "Invalid phone number length",
      phoneExists: "Phone number already exists",
      missingFields: "Please fill this field",
      properFormat: "The cellphone number format is incorrect",
    },
  },
  home: {
    welcome: "GajiGesa Employer Dashboard",
    hello: "Hello",
    summary: {
      ringkasan: "Team Summary",
      tipe: "Wallet Type",
      totalEmployees: "Total Employees",
      employee: "Employees",
      active: "Active",
      inactive: "Non Active",
      ewa: "EWA",
      uangKantor: "Uang Kantor",
      salaryDisbursement: "Salary Disbursement",
    },
    list: {
      title: "Team List",
      header: {
        no: "No",
        employeeId: "Employee ID",
        employeeName: "Employee Name",
        walletType: "Wallet Type",
        payDate: "Cut Off Date",
        withdrawLimit: "Withdrawal Limit",
        group: "Group",
        status: "Status",
        reason: "Reason",
        walletToolTip: "Pockets of funds owned by your employees",
        payDateToolTip: "Last Date for Employee Salary Calculation.",
        withdrawToolTip:
          "Salary limit that can be withdrawn by employees via EWA GajiGesa",
      },
      rows: {
        active: "Active",
        inactive: "Inactive",
        Aktif: "Active",
        "Non Aktif": "Inactive",
        endMonth: "End of Month",
        MALE: "Male",
        FEMALE: "Female",
        BLUE: "Blue Collar",
        WHITE: "White Collar",
        withdraw_suspended: "Inactive",
        withdraw_accrual_suspended: "Inactive",
      },
    },
    filter: {
      search: "Search",
      searchPlaceholder: "Input employee name or employee ID",
      filter: "Filter",
      download: "Download Data",
      add: "Add Employee",
    },
    export: {
      success:
        "Email saved. The data will be sent to the email in about 5 minutes.",
    },
    statusChange: {
      success: "Status successfully changed.",
    },
    progressBox: {
      title: "Employee Data Process",
      description:
        "Check the status of additions and changes to employee data here",
      button: "Check Data",
    },
    statusCheckBox: {
      title: "Change in Employee Status",
      description: 'Check the status of changes to "Employee Status" here',
      button: "Check Data",
    },
    statusReason: {
      "Close Cycle": "Close Cycle",
      "Data Review": "Data Review",
      Dismissal: "Dismissal",
      "Duplicate/ Incorrect Data": "Duplicate/ Incorrect Data",
      "Employer Action": "Employer Action",
      Fraud: "Fraud",
      Resignation: "Resignation",
    },
  },
  login: {
    welcome: "Welcome!",
    warning:
      "Access from your desktop for a better experience using this website",
    phone: "Phone Number",
    otp: "Verification code sent to ",
    phonePlaceholder: "Insert your registered phone number",
    error: "Invalid phone number",
    otpError: "Invalid OTP",
    whatsapp: "Send OTP via Whatsapp",
    sms: "Send OTP via SMS",
    signIn: "Sign in",
    noReceive: "Didn't receive verification code?",
    resend: "Resend code",
    or: "or",
    changePhone: "Change phone number",
    copyright: "© 2023 GajiGesa. All rights reserved.",
    otpResent: "OTP Resent. Try again",
  },
  basicValidation: {
    lettersOnly: "Please use only letters",
    tooLong: "Too long",
    tooShort: "Too short",
    numbersOnly: "Use numbers only",
    noMinus: "No minus symbol",
    noZeros: "Cannot Send 0",
    invalidPhone: "Invalid phone number length",
    phoneExists: "Phone number already exists",
    missingFields: "Please fill this field",
    properFormat: "The cellphone number format is incorrect",
  },
  progressBox: {
    title: "Employee Data Process",
    description:
      "Check the status of additions and changes to employee data here",
    button: "Check Data",
  },
  sideBar: {
    manageTeam: "Manage Team",
    transactionHistory: "Transaction History",
    whatsappSupport: "Whatsapp Support",
    logOut: "Logout",
    singleCreate: "Add One Employee",
    inProgress: "Data Under Verification",
    inStatusCheck: "Change in Employee Status",
    changeEmployeeData: "Change Employee Data",
    bulkCreate: "Add Several Employees",
    bulkUpdate: "Update data for several employees",
    bulkUpdateStatus: "Change Multiple Employee Status",
  },
  employeeCreation: {
    single: {
      personalData: "Data Pribadi",
      personalDataDesc: "Data Pribadi adalah informasi pribadi karyawan Anda.",
      employeeData: "Data Karyawan",
      employeeDataDesc:
        "Data Karyawan adalah informasi detail tentang karyawan Anda meliputi informasi jenis karyawan, pembayaran gaji dan jadwal kerja.",
      info: "Perhatian",
      infoDesc:
        "Tanggal absen terakhir karyawan yang disepakati oleh perusahaan dan GajiGesa. Pastikan mengisi dengan benar.",
      fullName: "Full Name (Required)",
      phone: "Phone Number (Required)",
      gender: "Gender (Required)",
      birthDate: "Date of Birth",
      ktp: "KTP Number (Required)",
      employeeId: "Employee ID (Required)",
      employeeType: "Employee Type (Required)",
      bankName: "Bank Name (Required)",
      beneficiaryName: "Beneficiary Name (Required)",
      salaryType: "Salary Type (Required)",
      payDate: "Pay Date (Required)",
      dateJoined: "Joining Date (Required)",
      group: "Group",
      bankNumber: "Bank Account Number (Required)",
      status: "Employee Status (Required)",
      salary: "Salary Amount (Required)",
      endOfMonth: "End of month",
      selectDate: "Choose a salary date",
      submit: "Submit",
      cancel: "Cancel",
      success: "Employee Creation Successful",
      pending: "New employee data is awaiting verification",
      error: "Changes to employee data are rejected",
    },
    bulk: {
      addSeveralEmployees: "Add Several employees",
      addSeveralDesc: "You can register several employees at once easily",
      followTheseSteps: "Follow these steps :",
      selectDocumentTemplate:
        "Before uploading a document, make sure you have used the template we provide",
      afterDownloadingFillDetailsAndExport:
        'After downloading the template, make sure you fill in the data according to the template and export it with the type ".XLSX" before uploading it with a maximum size of 25 MB',
      pleaseUploadFileInFormat:
        'Please upload your document. Files that can be uploaded are in "XLSX" format.',
      downloadTemplate: "Download Template",
      changeDocument: "Change Document",
      or: "Or",
      wipe: "Wipe",
      clickHereToUpload: "Click here to upload",
      orDragFilesToThisArea: "or Drag Files To This Area",
      maxFileSize25mb: "Maximum File Size 25 MB.",
      maxFileSizeExceeded:
        "The file you uploaded exceeds the maximum file size. Please check and try again",
      incorrectFileFormat:
        "The file format you uploaded is incorrect. Please check and try again",
      cancel: "Cancel",
      submit: "Submit",
      uploadingPleaseWait: "Uploading, please wait…",
    },
    BLUE: "Blue Collar",
    WHITE: "White Collar",
  },
  employeeUpdation: {
    single: {
      update: "Changes to employee data are approved",
      rejected: "Changes to employee data are rejected",
      processing: "Changes to employee data are awaiting verification",
      warningTitle: "In Verification",
      WarningDescription:
        "Some data is in the process of being verified by the GajiGesa team. We will notify you when verification is complete",
    },
    bulk: {
      changingEmployeeDataAtOnce: "Changing Employee Data at Once",
      bulkUpdateDesc: "You can change the data of many employees at once",
    },
    bulkUpdateStatus: {
      title: "Change Multiple Employee Status",
      desc: 'You can simultaneously change many employee statuses from "Active to Inactive" and vice versa',
    },
  },
  inProgress: {
    title: "Data Under Verification",
    waiting: "Some new employee data is waiting to be verified",
    downloadDataAndCheckForErrors: "Download data and check for errors",
    table: {
      header: {
        title: "Employee Data",
        no: "No",
        employeeId: "Employee ID",
        employeeName: "Employee name",
        uploadDate: "Uploaded On",
        fileName: "Nama File",
        status: "Verification Status",
        note: "Notes",
        actionType: "Action Type",
        employeeStatus: "Employee Status",
        reason: "Reason",
      },
    },
  },
  inStatusCheck: {
    title: "Change in Employee Status",
    table: {
      header: {
        title: "Employee Data",
        no: "No",
        employeeId: "Employee ID",
        employeeName: "Employee name",
        uploadDate: "Uploaded On",
        fileName: "Nama File",
        status: "Verification Status",
        note: "Notes",
      },
    },
  },
  toolTip: {
    totalEmployees: "Total number of employees who are registered in GajiGesa",
    totalActiveEmployees: "Employees who can conduct transactions in GajiGesa",
    totalSuspendEmployees:
      "Employees who cannot conduct transactions in GajiGesa",
    ewa: "Employee EWA Account",
    uangKantor: "Accounts used as office operating fund",
    salaryDisbursement:
      "Employee salary account received through GajiGesa salary disbursement",
    walletType: "",
    payDate: "",
    withdrawalLimit: "",
    documentIsBeingUploaded: "document is being uploaded…",
    uploadFailedDueToSystemError:
      "Upload failed due to system error. Please try again",
    documentUploadedSuccessfully: "document uploaded successfully",
  },
  modal: {
    export: {
      title: "Enter email to download",
      description:
        "Your downloaded file will be sent to your registered email in approximately 5 minutes.",
      placeholder: "example : agni@alamatemail.com",
      button: "Save & Send",
    },
    create: {
      confirm: "Add Employee Confirmation",
      confirmDesc: "Please make sure the data is correct before submitting",
      submit: "Submit",
      cancel: "Cancel",
    },
    cancel: {
      title: "Leave Page?",
      desc: "Are you sure you want to leave this page? The data you have entered will be lost.",
      stay: "Stay here",
      leave: "Leave it",
    },
    login: {
      title: "Resend Verification Code",
      description: "Please select a method to resend your verification code",
    },
    bulk: {
      confirm: "Confirm Add Several Employees",
      confirmDesc: "Make sure the documents uploaded are correct.",
      leavePage: "Leave Page",
      areYouSureYouWantToLeave:
        "Are you sure you want to leave this page? The data you have entered will be lost.",
      leave: "Leave",
      stayHere: "Stay Here",
    },
  },
  filter: {
    title: "Filter Based",
    walletType: "Wallet Type",
    status: "Status",
    group: "Group",
    payDate: "Cut Off Date",
    withdrawLimit: "Withdraw Limit",
    reset: "Reset",
    save: "Save",
    withdrawalAmount: "Withdrawl Amount",
    startingFrom: "Starting From (Rp)",
    upTo: "Up To (Rp)",
    transactionStatus: "Transaction Status",
    purposeOfWithdrawal: "Purpose Of Withdrawal",
    searchHere: "Search Here",
    actionType: "Action Type",
    fileName: "File Name",
    noData: "No Data",
  },
  transactionPage: {
    table: {
      no: "No",
      withdrawalDate: "Withdrawal Date",
      employeeId: "Employee Id",
      employeeName: "Employee Name",
      group: "Group",
      withdrawalAmount: "Withdrawal Amount",
      purposeOfWithdrawal: "Purpose of Withdrawal",
      status: "Status",
    },
    downloadDataSuccess: "Download Data Success to Email Id",
    transaction: "Transaction",
    notes: "Notes",
    dataListedIsBasedOnSalaryWithdrawlDataInPayrollCycle:
      "The data listed is based on salary withdrawal data in the payroll cycle for this period or yours choose",
    transactionDate: "Transaction date",
    dataSummary: "Data Summary",
    totalSalaryWithdrawals: "Total Salary Withdrawals",
    numberOfTransactions: "Number Of Transactions",
    totalEmployeesTransacting: "Total Employees Transacting",
    viewTransactions: "View Transactions",
    enterEmployeeNameOrID: "Enter Employee Name or ID",
    search: "Search",
    downloadData: "Download Data",
    downloadAllData: "Download All Data",
    downloadDataWithFilters: "Download Data With Filters",
    REJECTED: "REJECTED",
    PENDING: "PENDING",
    COMPLETED: "COMPLETED",
    FAILED: "FAILED",
    CANCELLED: "CANCELLED",
    NOT_STARTED: "NOT STARTED",
    SUCCESS: "SUCCESS",
    all: "All of them",
  },
};
const id = {
  errorCodes: {
    "GG-BENE-01": sConflictData,
    "GG-BENE-02": sPayloadBadRequestID,
    "GG-BENE-03": "Sudah ada",
    "GG-BENE-04": "Jumlah penerima yang terdaftar sudah mencapai batas",
    "GG-BENE-05": "Penghapusan tidak diizinkan",
    "GG-CON-OTP-01": "Token OTP diperlukan",
    "GG-CON-OTP-02": "Token OTP tidak valid",
    "GG-CON-OTP-03": "Token OTP sudah diverifikasi",
    "GG-CON-OTP-04": "Silahkan coba lagi atau kirim ulang kode",
    "GG-CON-OTP-05": "Token OTP tidak ditemukan",
    "GG-CON-OTP-06":
      "Kode verifikasi tidak valid. Silahkan coba lagi atau kirim ulang kode",
    "GG-CON-OTP-07":
      "Terlalu banyak permintaan kode verifikasi. Mohon tunggu 1 jam untuk mencoba kembali",
    "GG-CON-OTP-08": "Mohon tunggu 1 jam untuk mencoba kembali",
    "GG-EMP-01": "Pegawai tidak ditemukan",
    "GG-EMP-02": "Status pegawai tidak aktif",
    "GG-EMP-03": "Pegawai ditangguhkan",
    "GG-EMP-04": "Pemberi kerja pegawai ditangguhkan",
    "GG-EMP-05": "Transaksi pegawai terkunci",
    "GG-EMP-06": "Pemberi kerja pegawai tidak aktif",
    "GG-EMPYER-01": "Pemberi kerja tidak ditemukan",
    "GG-EMPYER-02": "Status pemberi kerja tidak aktif",
    "GG-EMPYER-03": "Pemberi kerja ditangguhkan",
    "GG-EMPYER-04": "Gagal menyimpan pemberi kerja",
    "GG-EMPYER-05": sConflictData,
    "GG-EMPYER-LOGIN-01": "Email tidak valid",
    "GG-EMPYER-LOGIN-02": "Email atau kata sandi salah",
    "GG-EMPYER-LOGIN-03": "Pemberi kerja ditangguhkan",
    "GG-EMPYER-LOGIN-04": "Status pemberi kerja tidak aktif",
    "GG-GEN-01": "Permintaan tidak valid",
    "GG-GEN-02": "Terlalu banyak permintaan",
    "GG-GEN-03": "API tidak ditemukan",
    "GG-GEN-04": sInternalErrorID,
    "GG-GEN-05": "JWT tidak sah",
    "GG-GEN-06": "AppKey tidak sah",
    "GG-GEN-07": "Fitur tidak diotorisasi",
    "GG-GEN-08": "Waktu respons habis",
    "GG-GEN-09": sConflictData,
    "GG-GEN-10": "Kesalahan parameter batas",
    "GG-GEN-11": "Kesalahan parameter lewati",
    "GG-GEN-12": "Parameter batas tidak valid",
    "GG-GEN-13": "Parameter lewati tidak valid",
    "GG-GOLD-01": "Data permintaan tidak valid",
    "GG-GOLD-02": "Profil sudah ada",
    "GG-GOLD-03": "Profil Treasury tidak ditemukan",
    "GG-OTP-01": "Nomor telepon tidak valid",
    "GG-OTP-02": "Nomor telepon diblokir",
    "GG-OTP-03": "AppKey tidak valid",
    "GG-OTP-04": "Mengirim ulang habis",
    "GG-OTP-05": "Kesalahan layanan SMS OTP",
    "GG-OTP-06": "Jenis pengiriman pesan tidak valid",
    "GG-OTP-07": "Nomor telepon belum terdaftar",
    "GG-OTP-08":
      "Terlalu banyak permintaan kode verifikasi. Mohon tunggu 1 jam untuk mencoba kembali",
    "GG-PHONE-01": sPayloadBadRequestID,
    "GG-PHONE-02": "Sudah ada",
    "GG-PHONE-03": "Jumlah nomor telepon yang terdaftar sudah mencapai batas",
    "GG-PHONE-04": "Penghapusan tidak diizinkan",
    "GG-PRODUCT-01": sInternalErrorID,
    "GG-PRODUCT-02": "Produk tidak ditemukan",
    "GG-PRODUCT-03": "Provider diatur oleh fitur bendera",
    "GG-PRODUCT-04": "Produk tidak tersedia",
    "GG-SIGNUP-01": "Data sudah ada",
    "GG-SIGNUP-02": sInternalErrorID,
    "GG-SIGNUP-03": sPayloadBadRequestID,
    "GG-SIGNUP-04": "Nama Pemberi Kerja tidak valid",
    "GG-SIGNUP-05": "Nama Pengguna tidak valid",
    "GG-SIGNUP-06": "Payload Nomor Telepon tidak valid",
    "GG-SIGNUP-07": "Jenis Identitas tidak valid",
    "GG-SIGNUP-08": "ID Identitas tidak valid",
    "GG-TRX-01": "Pengguna dalam periode kunci",
    "GG-TRX-02": "Transaksi pegawai ditangguhkan",
    "GG-TRX-03": "Kategori pegawai diblokir",
    "GG-TRX-04": "Akun diblokir untuk kategori",
    "GG-TRX-05": "Saldo tidak mencukupi",
    "GG-TRX-06": sInternalErrorID,
    "GG-TRX-07": "Kesalahan provider",
    "GG-TRX-08": "Produk tidak ditemukan",
    "GG-TRX-09": "Produk tidak tersedia",
    "GG-USER-01": "Pengguna tidak ditemukan",
    "GG-USER-02": "Status pengguna tidak aktif",
    "GG-USER-03": "Pengguna ditangguhkan",
    "GG-USER-04": "Gagal mengambil info pengguna",
    "GG-USER-05": "KTP Pengguna tidak ditemukan",
    "GG-VAL-01":
      "Status Karyawan salah. Status yang diisi wajib aktif, tidak aktif, penarikan ditangguhkan, dan penarikan akrual ditangguhkan ",
    "GG-VAL-02": "Format jumlah minimal salah. Format wajib diiisi dalam angka",
    "GG-VAL-03": "Format jumlah maksimal salah. Format wajib diisi dalam angka",
    "GG-VAL-04": "Format tanggal gajian salah. Format wajib diisi dalam teks",
    "GG-VAL-05":
      "Tipe akun wajib EWA, Poin, Emas, Penalangan Gaji, Uang Kantor, Uang Kas, dan Penalangan Gaji Offline",
    "GG-VAL-06": "Format grup karyawan salah. Format wajib diisi dalam teks",
    "GG-VAL-07": "Format pencarian salah. Format wajib diisi dalam teks",
    "GG-VAL-08":
      "Format kode karyawan salah. Format wajib diisi dalam kumpulan teks",
    "GG-VAL-09":
      "Format grup karyawan sekunder salah. Format wajib diisi dalam kumpulan teks",
    "GG-VAL-10":
      "Format identitas karyawan salah. Format wajib diisi dalam angka",
    "GG-VAL-11":
      "Format kategori penangguhan salah. Format wajib diisi dalam teks",
    "GG-VAL-12":
      "Format alasan penangguhan salah. Format wajib diisi dalam teks",
    "GG-VAL-13": "Format email salah",
    "GG-VAL-14":
      "Format dari tanggal transaksi salah. Format wajib diisi dalam teks tanggal",
    "GG-VAL-15":
      "Format ke tanggal transaksi salah. Format wajib diisi dalam teks tanggal",
    "GG-VAL-16":
      "Nilai untuk status transaksi salah. Status transaksi wajib MENUNGGU, SUKSES, DITOLAK, GAGAL",
    "GG-VAL-17":
      "Format identitas kategori transaksi salah. Format wajib diisi dalam kumpulan teks",
    "GG-VAL-18":
      "Format dari jumlah transaksi salah. Format wajib diisi dalam angka",
    "GG-VAL-19":
      "Format ke jumlah transaksi salah. Format wajib diisi dalam angka",
    "GG-VAL-20": "Format identitas rekan salah. Format wajib diisi dalam angka",
    "GG-VAL-21": "Format kode karyawan salah. Format wajib diisi dalam teks",
    "GG-VAL-22":
      "Format status pengajuan karyawan salah. Status pengajuan wajib menunggu, sukses, dalam proses, gagal, atau ditolak",
    "GG-VAL-23": 'Tipe kerah wajib "BIRU" atau "PUTIH"',
    "GG-VAL-24":
      "Format identitas pengguna salah. Format wajib diisi dalam angka",
    "GG-VAL-25": "Format nama depan salah. Format wajib diisi dalam teks",
    "GG-VAL-26": "Format nama belakang salah. Format wajib diisi dalam teks",
    "GG-VAL-27": "Format jenis pengajuan salah. Format wajib diisi dalam teks",
    "GG-VAL-28": "Format tanggal lahir salah. Format wajib diisi dalam teks",
    "GG-VAL-29": "Format nomor handphone salah. Format wajib diisi dalam teks",
    "GG-VAL-30": "Format jenis kelamin salah. Format wajib diisi dalam teks",
    "GG-VAL-31": "Format nomor KTP salah. Format wajib diisi dalam teks",
    "GG-VAL-32":
      "Format tanggal bergabung salah. Format wajib diisi dalam teks",
    "GG-VAL-33": "Format nama grup salah. Format wajib diisi dalam teks",
    "GG-VAL-34": "Format nama departemen salah. Format wajib diisi dalam teks",
    "GG-VAL-35": "Format nama penunjukkan salah. Format wajib diisi dalam teks",
    "GG-VAL-36":
      "Format identitas tipe gaji salah. Jenis gaji wajib BULANAN, HARIAN, MINGGUAN, UNIT_KERJA, PER JAM, DUA MINGGU SEKALI, BULANAN_TETAP, JENJANG WAKTU_TETAP, DUA BULAN_SEKALI",
    "GG-VAL-37": "Format jumlah gaji salah. Format wajib diisi dalam angka",
    "GG-VAL-38":
      "Format nama bank karyawan salah. Format wajib diisi dalam angka",
    "GG-VAL-39":
      "Format nomor akun bank karyawan salah. Format wajib diisi dalam teks",
    "GG-VAL-40": "Format nama penerima salah. Format wajib diisi dalam teks",
    "GG-VAL-41": "Format nama file salah. Format wajib diisi dalam teks",
    "GG-VAL-42": "Format nama karyawan salah. Format wajib diisi dalam teks",
    "GG-VAL-43":
      "Format permintaan karyawan diurutkan berdasarkan salah. Urutan wajib berdasarkan kode karyawan, nama karyawan, atau diupload pada",
    "GG-VAL-44":
      "Format urutan permintaan karyawan salah. Pengurutan harus menaik atau menurun",
    "GG-EMP-REQ-01":
      "Tidak dapat melakukan perubahan data. Silahkan menghubungi Tim GajiGesa",
    "GG-EMP-REQ-02":
      "Gagal upload karena ada dokumen yang masih diverifikasi. Silahkan coba lagi setelah dokumen disetujui!",
    "GG-EMP-REQ-03": "Tidak ada perubahan data",
    "GG-EMP-REQ-04":
      "Perubahan status karyawan gagal. Silakan pilih dan masukan detail alasan terlebih dahulu!",
    "GG-EMP-REQ-05":
      "Tidak dapat melakukan perubahan data. Silahkan menghubungi Tim GajiGesa",
    "GG-EMP-REQ-06": "Gagal upload karena error sistem. Silahkan coba lagi",
    "GG-EMP-REQ-07": "ID Karyawan tidak ditemukan",
    "GG-EMP-REQ-08": "Karyawan tidak ditemukan",
    "GG-EMP-REQ-09": "Nomor HP tidak valid",
    basicValidation: {
      lettersOnly: "Harap gunakan huruf saja",
      tooLong: "Terlalu panjang",
      tooShort: "Terlalu pendek",
      numbersOnly: "Harap gunakan angka saja",
      noMinus: "Angka tidak bisa minus",
      noZeros: "Tidak bisa menggunakan 0",
      invalidPhone: "Nomor handphone tidak valid",
      phoneExists: "Nomor sudah terdaftar",
      missingFields: "Mohon maaf, harap di isi",
      futureDate: "Mohon maaf, harap isi tanggal sebelum hari ini",
      properFormat: "Format nomor HP salah",
    },
  },
  home: {
    welcome: "Dashboard Karyawan GajiGesa",
    hello: "Hai",
    summary: {
      ringkasan: "Ringkasan Tim",
      tipe: "Tipe Kantong",
      totalEmployees: "Total Karyawan",
      employee: "Karyawan",
      active: "Status Aktif",
      inactive: "Status Non Aktif",
      ewa: "Akun Gaji",
      uangKantor: "Uang Kantor",
      salaryDisbursement: "Penalangan Gaji",
    },
    progressBox: {
      title: "Proses Data Karyawan",
      description: "Cek status penambahan dan perubahan data karyawan disini",
      button: "Cek Data",
    },
    statusCheckBox: {
      title: "Perubahan Status Karyawan",
      description: "Cek status perubahan “Status Karyawan” disini",
      button: "Cek Data",
    },
    list: {
      title: "Lihat Tim",
      header: {
        no: "No",
        employeeId: "ID Karyawan",
        employeeName: "Nama Karyawan",
        walletType: "Tipe Kantong",
        payDate: "Tanggal Cutoff",
        withdrawLimit: "Limit Penarikan",
        group: "Grup",
        status: "Status",
        reason: "Alasan",
        walletToolTip: "Kantong dana yang dimiliki oleh karyawan Anda",
        payDateToolTip: "Tanggal Terakhir Perhitungan Gaji Karyawan.",
        withdrawToolTip:
          "Batas gaji yang bisa ditarik oleh karyawan melalui EWA GajiGesa",
      },
      rows: {
        active: "Aktif",
        inactive: "Non Aktif",
        endMonth: "Akhir Bulan",
        MALE: "Pria",
        FEMALE: "Wanita",
        BLUE: "Kerah Biru",
        WHITE: "Kerah Putih",
        withdraw_suspended: "Non Aktif",
        Aktif: "Aktif",
        "Non Aktif": "Non Aktif",
        withdraw_accrual_suspended: "Non Aktif",
      },
    },
    filter: {
      search: "Cari",
      searchPlaceholder: "Masukkan Nama atau ID Karyawan",
      filter: "Filter",
      download: "Unduh Data",
      add: "Kelola Karyawan",
    },
    export: {
      success:
        "Email baru tersimpan. Data akan terkirim ke email kamu kurang lebih dalam 5 Menit",
    },
    statusChange: {
      success: "Status berhasil diubah.",
    },
    statusReason: {
      "Close Cycle": "Close Cycle",
      "Data Review": "Peninjauan Data",
      Dismissal: "Pemutusan Hubungan Kerja",
      "Duplicate/ Incorrect Data": "Data Duplikasi/Salah",
      "Employer Action": "Lainnya",
      Fraud: "Penipuan",
      Resignation: "Mengundurkan Diri",
    },
  },
  login: {
    welcome: "Selamat Datang!",
    warning:
      "Akses dari desktop untuk pengalaman yang lebih baik dalam menggunakan dashboard ini",
    phone: "Nomor HP",
    otp: "Kode verifikasi terkirim ke nomor ",
    phonePlaceholder: "Masukkan nomor HP yang terdaftar",
    error: "Nomor telpon invalid",
    otpError: "OTP invalid",
    whatsapp: "Kirim OTP Lewat Whatsapp",
    sms: "Kirim OTP Lewat SMS",
    signIn: "Masuk",
    noReceive: "Tidak menerima kode verifikasi?",
    resend: "Kirim ulang",
    or: "atau",
    changePhone: "Ganti nomor HP",
    copyright: "© 2023 GajiGesa. All rights reserved.",
    otpResent: "OTP telah dikirim. Coba lagi",
  },
  basicValidation: {
    lettersOnly: "Harap gunakan huruf saja",
    tooLong: "Terlalu panjang",
    tooShort: "Terlalu pendek",
    numbersOnly: "Harap gunakan angka saja",
    noMinus: "Angka tidak bisa minus",
    noZeros: "Tidak bisa menggunakan 0",
    invalidPhone: "Nomor handphone tidak valid",
    phoneExists: "Nomor sudah terdaftar",
    missingFields: "Mohon maaf, harap di isi",
    futureDate: "Mohon maaf, harap isi tanggal sebelum hari ini",
    properFormat: "Format nomor HP salah",
  },
  sideBar: {
    manageTeam: "Kelola Data Karyawan",
    transactionHistory: "Transaksi",
    whatsappSupport: "Whatsapp Support",
    logOut: "Logout",
    singleCreate: "Tambah Satu Karyawan",
    inProgress: "Proses Data Karyawan",
    inStatusCheck: "Perubahan Status Karyawan",
    changeEmployeeData: "Ubah Data Karyawan",
    bulkCreate: "Tambah Beberapa Karyawan",
    bulkUpdate: "Update Data Beberapa Karyawan",
    bulkUpdateStatus: "Ubah Beberapa Status Karyawan",
  },
  employeeCreation: {
    single: {
      personalData: "Data Pribadi",
      personalDataDesc: "Data Pribadi adalah informasi pribadi karyawan Anda.",
      employeeData: "Data Karyawan",
      employeeDataDesc:
        "Data Karyawan adalah informasi detail tentang karyawan Anda meliputi informasi jenis karyawan, pembayaran gaji dan jadwal kerja.",
      info: "Perhatian",
      infoDesc:
        "Tanggal absen terakhir karyawan yang disepakati oleh perusahaan dan GajiGesa. Pastikan mengisi dengan benar.",
      fullName: "Nama Lengkap (Wajib)",
      phone: "Nomor Handphone (Wajib / Format wajib dimulai dengan 62)",
      gender: "Jenis Kelamin (Wajib)",
      birthDate: "Tanggal Lahir",
      ktp: "Nomor KTP (Wajib)",
      employeeId: "ID Karyawan (Wajib)",
      employeeType: "Tipe Karyawan (Wajib)",
      bankName: "Nama Bank (Wajib)",
      beneficiaryName: "Nama Pemilik Rekening (Wajib)",
      salaryType: "Tipe Gaji (Wajib)",
      payDate: "Tanggal Terakhir Perhitungan Gaji Karyawan (Wajib)",
      dateJoined: "Tanggal Bergabung Perusahaan (Wajib)",
      group: "Grup",
      bankNumber: "Nomor Rekening (Wajib)",
      status: "Status Karyawan (Wajib)",
      salary: "Nominal Gaji (Wajib)",
      endOfMonth: "Akhir Bulanan",
      selectDate: "Pilih Tanggal Gajian",
      submit: "Simpan",
      cancel: "Batalkan",
      success: "Karyawan berhasil disimpan",
      pending: "Data karyawan baru sedang menunggu verifikasi",
      error: "Perubahan data karyawan ditolak",
    },
    bulk: {
      addSeveralEmployees: "Tambah Beberapa Karyawan",
      addSeveralDesc:
        "Anda bisa mendaftarkan beberapa karyawan secara sekaligus dengan mudah",
      followTheseSteps: "Ikuti langkah-langkah berikut ini :",
      selectDocumentTemplate:
        "Sebelum meng-upload dokumen, pastikan Anda sudah menggunakan template yang kami sediakan",
      afterDownloadingFillDetailsAndExport:
        "Setelah mendownload template, pastikan anda mengisi data sesuai dengan template dan mengeksport nya dengan tipe “.XLSX” sebelum menguploadnya dengan maksimal ukuran 25 MB",
      pleaseUploadFileInFormat:
        "Silahkan upload dokumen Anda. File yang dapat di-upload adalah dalam bentuk ”.XLSX”",
      downloadTemplate: "Download Template",
      changeDocument: "Ganti Dokumen",
      or: "atau",
      wipe: "Hapus",
      clickHereToUpload: "Klik disini untuk mengupload",
      orDragFilesToThisArea: "atau seret file ke area ini",
      maxFileSize25mb: "Ukuran file maksimum 25 MB.",
      maxFileSizeExceeded:
        "File yang Anda upload melebih ukuran file maksimum. Silahkan periksa dan coba lagi",
      incorrectFileFormat:
        "Format file yang Anda upload salah. Silahkan periksa dan coba lagi",
      cancel: "Kembali",
      submit: "Lanjutkan",
      uploadingPleaseWait: "Sedang meng-upload, mohon tunggu…",
    },
    BLUE: "Kerah Biru",
    WHITE: "Kerah Putih",
  },
  employeeUpdation: {
    single: {
      update: "Perubahan data karyawan disetujui",
      pending: "Perubahan pada data karyawan sedang menunggu keputusan",
      rejected: "Perubahan data karyawan ditolak",
      failed: "Perubahan pada data karyawan gagal",
      processing: "Perubahan data karyawan sedang menunggu verifikasi",
      warningTitle: "Dalam Verifikasi",
      WarningDescription:
        "Beberapa data sedang dalam proses verifikasi tim GajiGesa. Kami akan memberitahu kamu jika verifikasi sudah selesai",
    },
    bulk: {
      changingEmployeeDataAtOnce: "Pengubahan Data Karyawan Sekaligus",
      bulkUpdateDesc:
        "Anda bisa mengubah data banyak karyawan secara sekaligus",
    },
    bulkUpdateStatus: {
      title: "Ubah Beberapa Status Karyawan",
      desc: "Anda bisa sekaligus mengubah banyak status karyawan dari “Aktif ke Non Aktif” dan sebaliknya",
    },
  },
  inProgress: {
    title: "Proses Data Karyawan",
    waiting: "Beberapa data karyawan baru sedang menunggu diverifikasi",
    downloadDataAndCheckForErrors: "Download data untuk cek error",
    table: {
      header: {
        title: "Data Karyawan",
        no: "No",
        employeeId: "ID Karyawan",
        employeeName: "Nama Karyawan",
        uploadDate: "Diupload Pada",
        fileName: "Nama File",
        status: "Status Verifikasi",
        note: "Catatan",
        actionType: "Tipe Aksi",
        employeeStatus: "Status Karyawan",
        reason: "Alasan",
      },
    },
  },
  inStatusCheck: {
    title: "Perubahan Status Karyawan",
    table: {
      header: {
        title: "Data Karyawan",
        no: "No",
        employeeId: "ID Karyawan",
        employeeName: "Nama Karyawan",
        uploadDate: "Diupload Pada",
        fileName: "Nama File",
        status: "Status Verifikasi",
        note: "Catatan",
      },
    },
  },
  toolTip: {
    totalEmployees: "Jumlah seluruh karyawan yang terdaftar di GajiGesa",
    totalActiveEmployees: "Karyawan yang bisa melakukan transaksi di GajiGesa",
    totalSuspendEmployees:
      "Karyawan yang tidak bisa melakukan transaksi di GajiGesa",
    ewa: "Akun EWA karyawan",
    uangKantor: "Akun yang digunakan sebagai dana operasional kantor",
    salaryDisbursement:
      "Akun gaji karyawan yang diterima melalui penalangan GajiGesa",
    walletType: "Kantong dana yang dimiliki oleh karyawan Anda",
    payDate: "Tanggal Terakhir Perhitungan Gaji Karyawan.",
    withdrawalLimit:
      "Batas gaji yang bisa ditarik oleh karyawan melalui EWA GajiGesa",
    documentIsBeingUploaded: "dokumen sedang diupload…",
    uploadFailedDueToSystemError:
      "Upload failed due to system error. Please try again",
    documentUploadedSuccessfully: "dokumen berhasil diupload",
  },
  modal: {
    export: {
      title: "Masukkan email untuk download",
      description:
        "File yang Anda download akan dikirim ke email terdaftar Anda kurang lebih 5 menit",
      placeholder: "contoh : agni@alamatemail.com",
      button: "Simpan & Kirim",
    },
    create: {
      confirm: "Konfirmasi Tambah Karyawan",
      confirmDesc: "Pastikan data sudah sesuai sebelum menyimpan data.",
      submit: "Simpan",
      cancel: "Batalkan",
    },
    cancel: {
      title: "Tinggalkan Halaman?",
      desc: "Apakah Anda yakin ingin meninggalkan halaman ini? Data yang telah Anda masukkan akan hilang.",
      stay: "Tetap Disini",
      leave: "Tinggalkan",
    },
    login: {
      title: "Kirim Ulang Kode Verifikasi",
      description: "Silahkan pilih metode kirim ulang kode verifikasi Anda",
    },
    bulk: {
      confirm: "Konfirmasi Tambah Beberapa Karyawan",
      confirmDesc: "Pastikan dokumen yang diupload sudah benar.",
      leavePage: "Tinggalkan Halaman",
      areYouSureYouWantToLeave:
        "Apakah Anda yakin ingin meninggalkan halaman ini? Data yang telah Anda masukkan akan hilang.",
      leave: "Tinggalkan",
      stayHere: "Tetap Disini",
    },
  },
  filter: {
    title: "Pilih Bedasarkan",
    walletType: "Tipe Kantong",
    status: "Status",
    group: "Grup",
    payDate: "Tanggal Cut Off",
    withdrawLimit: "Limit Penarikan",
    reset: "Reset",
    save: "Simpan",
    actionType: "Tipe Aksi",
    fileName: "Nama File",
    withdrawalAmount: "Jumlah Penarikan",
    startingFrom: "Mulai Dari (Rp)",
    upTo: "Sampai Dengan (Rp)",
    transactionStatus: "Status Penarikan",
    purposeOfWithdrawal: "Tujuan Penarikan",
    searchHere: "Cari Disini",
    noData: "Tidak ada data",
  },
  transactionPage: {
    table: {
      no: "No",
      withdrawalDate: "Tanggal Penarikan",
      employeeId: "ID Karyawan",
      employeeName: "Nama Karyawan",
      group: "Grup",
      withdrawalAmount: "Jumlah Penarikan",
      purposeOfWithdrawal: "Tujuan Penarikan",
      status: "Status",
    },
    transaction: "Transaksi",
    downloadDataSuccess: "Download Data Sukses ke Email Id",
    notes: "Catatan",
    dataListedIsBasedOnSalaryWithdrawlDataInPayrollCycle:
      "Data yang tertera bersumber pada data penarikan gaji dalam siklus penggajian periode ini atau yang Anda pilih",
    transactionDate: "Tanggal transaksi",
    dataSummary: "Ringkasan Data",
    totalSalaryWithdrawals: "Total Penarikan Gaji",
    numberOfTransactions: "Jumlah Transaksi",
    totalEmployeesTransacting: "Total Karyawan yang Bertransaksi",
    viewTransactions: "Lihat Transaksi",
    enterEmployeeNameOrID: "Masukkan Nama atau ID Karyawan",
    search: "Cari",
    downloadData: "Download Data",
    downloadAllData: "Download Semua Data",
    downloadDataWithFilters: "Download Berdasarkan Filter",
    REJECTED: "Ditolak",
    PENDING: "Menunggu",
    COMPLETED: "Sukses",
    FAILED: "Gagal",
    CANCELLED: "Dibatalkan",
    NOT_STARTED: "Belum Mulai",
    SUCCESS: "Sukses",
    all: "Semua",
  },
};

export { en, id };
