import { useEffect, useState } from "react";
import {
  Text,
  Button,
  VStack,
  HStack,
  Input,
  Box,
  Menu,
  Pressable,
  Checkbox,
} from "native-base";
import { BsArrowRight, BsChevronDown } from "react-icons/bs";
import { BiTrashAlt } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import colors from "theme/colors";
import {
  TRANSACTION_REASON_TYPES,
  TRANSACTION_STATUS_TYPES,
} from "global/constants";
import { useTranslation } from "react-i18next";
import { fetchEmployeeGroup } from "api/useQueryCalls";
import AmplitudeHelper from "utils/analytics";

const TxnPageFilterContent = ({
  setFilterData = () => {},
  setIsCollapsed = () => {},
  resetModal = () => {},
  filterData = {},
  onResetPress = () => {},
}) => {
  const { t } = useTranslation("common");
  const [withdrawalPurpose, setWithdrawalPurpose] = useState(
    filterData?.withdrawalPurpose || []
  );
  const [txnStatus, setTxnStatus] = useState(filterData?.txnStatus);
  const [group, setGroup] = useState([]);
  const [minAmount, setMinAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");

  const collapseDropDown = () => {
    if (setIsCollapsed) {
      setIsCollapsed(true);
    }
    if (resetModal) {
      resetModal();
    }
  };

  useEffect(() => {
    if (Object.keys(filterData || {}).length === 0) {
      setTxnStatus([]);
      collapseDropDown();
    }
  }, [filterData]);

  const styles = {
    menuContainer: {
      paddingHorizontal: 16,
      paddingVertical: 8,
      justifyContent: "space-between",
    },
    checkBoxContainer: { marginLeft: 16, marginTop: 8 },
  };

  const handleSubmit = () => {
    const data = {};
    withdrawalPurpose != "" && (data.withdrawalPurpose = withdrawalPurpose);
    txnStatus != "" && (data.txnStatus = txnStatus);
    group != "" && (data.employeeGroup = group);
    minAmount != "" && (data.minAmount = minAmount);
    maxAmount != "" && (data.maxAmount = maxAmount);
    setFilterData(data);
    AmplitudeHelper.logEvent("filter_saved", {
      source: "transaction_page",
      filter_parameted: Object.keys(data),
      filter_value: { ...data },
    });
    collapseDropDown();
  };

  const handleReset = () => {
    setWithdrawalPurpose("");
    setTxnStatus([]);
    setGroup([]);
    setMinAmount("");
    setMaxAmount("");
    setFilterData({});
    onResetPress();
    AmplitudeHelper.logEvent("filter_reset", {
      source: "filter_transaction_page",
    });
    collapseDropDown();
  };

  const {
    isLoading,
    isError,
    isSuccess,
    error,
    data: employeeGroupData = [],
  } = fetchEmployeeGroup();

  return (
    <VStack p="15px" space={2}>
      <Text variant="sm-bold">{t("filter.title")}</Text>

      <Text variant="xs">{t("filter.purposeOfWithdrawal")}</Text>
      <Box mb="10px">
        <Menu
          w="250"
          placement="top left"
          type="checkbox"
          onOpen={() => {
            AmplitudeHelper.logEvent("transaction_type_clicked", {
              source: "filter_transaction_page",
            });
          }}
          trigger={(triggerProps) => {
            return (
              <Pressable
                accessibilityLabel="Employee Withdrawal Purpose"
                {...triggerProps}
              >
                <HStack
                  alignItems="center"
                  space="2"
                  borderWidth={1}
                  borderColor={colors.disabled.smoke}
                  borderRadius={4}
                  style={styles.menuContainer}
                >
                  {withdrawalPurpose.length === 0 ? (
                    <Text opacity={0.5}>{t("transactionPage.all")}</Text>
                  ) : (
                    <Text>
                      {withdrawalPurpose.map((i) => i.name).join(", ")}
                    </Text>
                  )}
                  <BsChevronDown size={22} />
                </HStack>
              </Pressable>
            );
          }}
        >
          <Checkbox.Group
            colorScheme="green"
            maxHeight="300"
            defaultValue={withdrawalPurpose}
            onChange={(values) => {
              setWithdrawalPurpose(values || []);
            }}
          >
            {TRANSACTION_REASON_TYPES.map((reason) => (
              <Pressable>
                <HStack
                  space={2}
                  alignItems="center"
                  style={styles.checkBoxContainer}
                >
                  <Checkbox value={reason} />
                  <Text variant="xs">{reason.name}</Text>
                </HStack>
              </Pressable>
            ))}
          </Checkbox.Group>
        </Menu>
      </Box>

      <Text variant="xs">{t("filter.transactionStatus")}</Text>
      <Box mb="10px">
        <Menu
          w="190"
          onOpen={() => {
            AmplitudeHelper.logEvent("transaction_status_clicked", {
              source: "filter_transaction_page",
            });
          }}
          trigger={(triggerProps) => {
            return (
              <Pressable
                accessibilityLabel="Employee TXN Status"
                {...triggerProps}
              >
                <HStack
                  alignItems="center"
                  space="2"
                  borderWidth={1}
                  borderColor={colors.disabled.smoke}
                  borderRadius={4}
                  style={styles.menuContainer}
                >
                  {txnStatus.length === 0 ? (
                    <Text opacity={0.5}>{t("transactionPage.all")}</Text>
                  ) : (
                    <Text>
                      {txnStatus
                        .map((i) => t(`transactionPage.${i}`))
                        .join(", ")}
                    </Text>
                  )}
                  <BsChevronDown size={22} />
                </HStack>
              </Pressable>
            );
          }}
        >
          <Checkbox.Group
            colorScheme="green"
            defaultValue={txnStatus}
            onChange={(values) => {
              setTxnStatus(values || []);
            }}
          >
            {Object.keys(TRANSACTION_STATUS_TYPES).map((status) => (
              <HStack
                space={2}
                alignItems="center"
                style={styles.checkBoxContainer}
              >
                <Checkbox value={status} />
                <Text variant="xs">
                  {t(`transactionPage.${TRANSACTION_STATUS_TYPES[status]}`)}
                </Text>
              </HStack>
            ))}
          </Checkbox.Group>
        </Menu>
      </Box>

      <Text variant="xs">{t("filter.withdrawalAmount")}</Text>
      <HStack
        alignItems="center"
        borderWidth={1}
        borderColor={colors.disabled.smoke}
        borderRadius={4}
        mb="10px"
      >
        <Input
          variant="unstyled"
          type="number"
          placeholder={t("filter.startingFrom")}
          value={minAmount}
          onChange={(x) => {
            AmplitudeHelper.logEvent("transaction_amount_clicked", {
              source: "filter_transaction_page",
              minAmt: x.target.value,
            });
            setMinAmount(x.target.value.replace(/[^0-9]/g, ""));
          }}
        />
        <BsArrowRight />
        <Input
          variant="unstyled"
          type="number"
          placeholder={t("filter.upTo")}
          value={maxAmount}
          onChange={(x) => {
            AmplitudeHelper.logEvent("transaction_amount_clicked", {
              source: "filter_transaction_page",
              maxAmt: x.target.value,
            });
            setMaxAmount(x.target.value.replace(/[^0-9]/g, ""));
          }}
        />
      </HStack>

      <Text variant="xs">{t("filter.group")}</Text>
      <Menu
        w="250"
        type="checkbox"
        onOpen={() => {
          AmplitudeHelper.logEvent("group_clicked", {
            source: "filter_transaction_page",
          });
        }}
        trigger={(triggerProps) => {
          return (
            <Pressable
              accessibilityLabel="Employee Group Filter"
              {...triggerProps}
            >
              <HStack
                alignItems="center"
                space="2"
                borderWidth={1}
                borderColor={colors.disabled.smoke}
                borderRadius={4}
              >
                <Input
                  placeholder={t("filter.searchHere")}
                  flex="0.97"
                  borderWidth={0}
                  value={group.join(", ")}
                  onChange={(x) => setGroup(x.target.value)}
                />
                <AiOutlineSearch size="20px" />
              </HStack>
            </Pressable>
          );
        }}
      >
        <Checkbox.Group
          colorScheme="green"
          maxHeight="300"
          defaultValue={group}
          onChange={(values) => {
            setGroup(values || []);
          }}
        >
          {(employeeGroupData || []).map((groupName) => (
            <Pressable>
              <HStack
                space={2}
                alignItems="center"
                style={styles.checkBoxContainer}
              >
                <Checkbox value={groupName} />
                <Text variant="xs">{groupName}</Text>
              </HStack>
            </Pressable>
          ))}
        </Checkbox.Group>
      </Menu>

      <HStack space={3}>
        <Button
          variant="outline"
          flex="0.25"
          borderColor={colors.error.rose}
          leftIcon={<BiTrashAlt size="15px" color={colors.error.rose} />}
          onPress={handleReset}
        >
          <Text variant="sm" color={colors.error.rose}>
            {t("filter.reset")}
          </Text>
        </Button>
        <Button variant="solid" flex="0.75" onPress={handleSubmit}>
          <Text variant="sm" color={colors.neutral.cotton}>
            {t("filter.save")}
          </Text>
        </Button>
      </HStack>
    </VStack>
  );
};

export default TxnPageFilterContent;
