import {
  Flex,
  Text,
  Button,
  HStack,
  VStack,
  Box,
  Menu,
  // TODO:  added to move to next line and remove eslint error
} from "native-base";
import colors from "theme/colors";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IN_PROGRESS } from "global/paths";

const ProgressBox = () => {
  const { t } = useTranslation("common");
  return (
    <Flex
      bg={colors.neutral.cotton}
      mb="20px"
      p="15px"
      py="10px"
      flex={1}
      borderRadius={12}
      maxWidth="1600px"
    >
      <HStack alignItems="center" space={3} style={{ flexWrap: "wrap" }}>
        <VStack style={{ flexWrap: "wrap", flex: 1 }}>
          <Text variant="sm-bold" color={colors.secondary.orchidBlue}>
            {t("home.progressBox.title")}
          </Text>
          <Text variant="xs" numberOfLines={2}>
            {t("home.progressBox.description")}
          </Text>
        </VStack>
        <Link to={IN_PROGRESS} style={{ textDecorationLine: "none" }}>
          <Button variant="solid">
            <Text variant="xs" color={colors.neutral.cotton}>
              {t("home.progressBox.button")}
            </Text>
          </Button>
        </Link>
      </HStack>
    </Flex>
  );
};

export default ProgressBox;
