const apiConfig = {
  login: {
    name: "login",
    urlEndpoint: "login/otp?",
    httpMethod: "GET",
    hasQueryProps: true,
    isAuthenticated: false,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: true,
  },
  otp: {
    name: "otp",
    urlEndpoint: "login/otp",
    httpMethod: "POST",
    isAuthenticated: false,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  logout: {
    name: "logout",
    urlEndpoint: "auth/logout",
    httpMethod: "POST",
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  summary: {
    name: "summary",
    urlEndpoint: "team/summary",
    httpMethod: "GET",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: true,
  },
  employees: {
    name: "employees",
    urlEndpoint: "team/employees?",
    httpMethod: "GET",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: true,
  },
  status: {
    name: "status",
    urlEndpoint: "team/employee/{employeeId}/status",
    httpMethod: "PATCH",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  export: {
    name: "export",
    urlEndpoint: "team/employees/export",
    httpMethod: "POST",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  exportEmployeeRequests: {
    name: "exportEmployeeRequests",
    urlEndpoint: "employee-request/export?",
    httpMethod: "POST",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: true,
  },
  email: {
    name: "email",
    urlEndpoint: "team/email",
    httpMethd: "GET",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  employeeGroup: {
    name: "employeeGroup",
    urlEndpoint: "team/employeeGroup",
    httpMethd: "GET",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  progressExport: {
    name: "progressExport",
    urlEndpoint: "employee-request/export?",
    httpMethod: "POST",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  singleCreate: {
    name: "singleCreate",
    urlEndpoint: "employee-request/single-create",
    httpMethod: "POST",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  inProgress: {
    name: "inProgress",
    urlEndpoint: "employee-request?",
    httpMethod: "GET",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: true,
  },
  paymentCycles: {
    name: "paymentCycles",
    urlEndpoint: "payment-cycles?",
    httpMethod: "GET",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: true,
  },
  getTransactions: {
    name: "getTransactions",
    urlEndpoint: "transactions?",
    httpMethod: "GET",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: true,
  },
  exportTransactionList: {
    name: "exportTransactionList",
    urlEndpoint: "transactions/export?",
    httpMethod: "POST",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: true,
  },
  getEmployeeData: {
    name: "getEmployeeData",
    urlEndpoint: "team/employees/{employeeId}",
    httpMethod: "GET",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: true,
  },
  getEmployeeRequestById: {
    name: "getEmployeeRequestById",
    urlEndpoint: "employee-request/{employeeId}",
    httpMethod: "GET",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: true,
  },
  singleUpdate: {
    name: "singleUpdate",
    urlEndpoint: "employee-request/single-update",
    httpMethod: "POST",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  getBanksList: {
    name: "getBanksList",
    urlEndpoint: "team/bank/list",
    httpMethod: "GET",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  bulkCreate: {
    name: "bulkCreate",
    urlEndpoint: "employee-request/file-upload/xlsx",
    httpMethod: "POST",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
};

/**
 *
 * @param {string} name for the api name
 * @param {string} baseUrl for api base URL
 * @returns {string} as concatenated string with base url
 */
apiConfig.getApiUrl = (name, baseUrl) => baseUrl + apiConfig[name].urlEndpoint;

export default apiConfig;
