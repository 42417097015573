import {
  HStack,
  Text,
  Center,
  FlatList,
  Button,
  Spinner,
  Box,
  // TODO:  added to move to next line and remove eslint error
} from "native-base";
import { Link } from "react-router-dom";
import colors from "theme/colors";
import { BsChevronDown } from "react-icons/bs";
import InfoToolTip from "atoms/InfoToolTip";
import { getStore } from "store/storeUtils";
import { TEAM_MODALS } from "global/constants";
import { useTranslation } from "react-i18next";
import AmplitudeHelper from "utils/analytics";
import { SINGLE_UPDATE } from "global/paths";

const Cell = ({
  children,
  header = false,
  style = {},
  button = false,
  id = 0,
  disabled = false,
  isPressable = false,
  linkTo = "",
  data = "",
}) => {
  const tableCellWidth = ["120px", "130px", "130px", "130px", "185px"];
  const { modal, setModal } = getStore();

  const employeeData = {
    employeeId: data,
  };

  const handleShowModal = (id) => {
    AmplitudeHelper.logEvent("employee_status_clicked", {
      source: "team_page",
      employee_id: id,
    });
    setModal({
      ...modal,
      type: TEAM_MODALS.changeStatus,
      data: { employeeId: id },
    });
  };

  return (
    <Center bg={header ? colors.neutral.mist : colors.neutral.cotton} py="10px">
      {button ? (
        <Center w={tableCellWidth}>
          <Button
            variant="outline"
            w="95px"
            rightIcon={<BsChevronDown size="15px" />}
            onPress={() => handleShowModal(id)}
            isDisabled={disabled}
          >
            <Text variant="xs" textAlign="center">
              {children}
            </Text>
          </Button>
        </Center>
      ) : isPressable ? (
        <Box minW={tableCellWidth} alignItems="center">
          <Link
            to={linkTo}
            onClick={() => {
              AmplitudeHelper.logEvent("employee_code_clicked", {
                source: "team_page",
                employee_id: id,
              });
            }}
            state={employeeData}
            style={{ color: colors.secondary.orchidBlue }}
          >
            <Text
              variant={header ? "xs-bold" : "xs"}
              minW={tableCellWidth}
              color={colors.secondary.orchidBlue}
              style={[style || {}, { textAlign: "center" }]}
            >
              {children}
            </Text>
          </Link>
        </Box>
      ) : (
        <Text
          variant={header ? "xs-bold" : "xs"}
          minW={tableCellWidth}
          style={[style || {}, { textAlign: "center" }]}
        >
          {children}
        </Text>
      )}
    </Center>
  );
};

const Header = () => {
  const { t } = useTranslation("common");

  const border = { borderRightWidth: 2, borderColor: "#c1c1c1" };
  return (
    <HStack>
      <Cell header style={{ ...border, minWidth: "75px" }}>
        {t("home.list.header.no")}
      </Cell>
      <Cell header style={border}>
        {t("home.list.header.employeeId")}
      </Cell>
      <Cell header style={border}>
        {t("home.list.header.employeeName")}
      </Cell>
      <Cell header style={border}>
        <InfoToolTip
          label={t("home.list.header.walletToolTip")}
          amplitude="account_type"
        >
          {t("home.list.header.walletType")}
        </InfoToolTip>
      </Cell>
      <Cell header style={border}>
        <InfoToolTip
          label={t("home.list.header.payDateToolTip")}
          amplitude="pay_date"
        >
          {t("home.list.header.payDate")}
        </InfoToolTip>
      </Cell>
      <Cell header style={border}>
        <InfoToolTip
          label={t("home.list.header.withdrawToolTip")}
          amplitude="max_withdrawal"
        >
          {t("home.list.header.withdrawLimit")}
        </InfoToolTip>
      </Cell>
      <Cell header style={border}>
        {t("home.list.header.group")}
      </Cell>
      <Cell header style={border}>
        {t("home.list.header.status")}
      </Cell>
      <Cell header>{t("home.list.header.reason")}</Cell>
    </HStack>
  );
};

const Row = (item) => {
  const { t } = useTranslation("common");

  const row = item.row.item;
  return (
    <HStack zIndex={2}>
      <Cell style={{ minWidth: "75px" }}>{row?.id}</Cell>
      <Cell isPressable linkTo={SINGLE_UPDATE} data={row?.unalteredEmpId}>
        {row?.employeeId}
      </Cell>
      <Cell>{row?.employeeName}</Cell>
      <Cell>{row?.walletType}</Cell>
      <Cell>{row?.cutOffDate}</Cell>
      <Cell>{row?.withdrawalLimit}</Cell>
      <Cell>{row?.group}</Cell>
      <Cell
        button
        id={row?.unalteredEmpId}
        disabled={row?.status == "home.list.rows.inactive"}
      >
        {t(row?.status)}
      </Cell>
      <Cell>{row?.reasons}</Cell>
    </HStack>
  );
};

const Table = ({ data, isLoading }) => {
  return (
    <>
      {isLoading ? (
        <Spinner size="sm" />
      ) : (
        <FlatList
          data={data}
          ListHeaderComponent={Header}
          renderItem={(item) => <Row row={item} />}
          style={{
            marginHorizontal: "15px",
            overflowX: "scroll",
            paddingBottom: "35px",
            borderBottomColor: colors.neutral.mist,
            borderBottomWidth: 1,
          }}
        />
      )}
    </>
  );
};

export default Table;
