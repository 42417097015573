import { useState, useEffect } from "react";
import { getStore } from "store/storeUtils";
import Modal from "atoms/Modal";
import ToastAlert from "atoms/ToastAlert";
import { CREATE_MODALS } from "global/constants";
import {
  Text,
  Button,
  HStack,
  Flex,
  Box,
  VStack,
  Divider,
  useToast,
  Input,
} from "native-base";
import { AiFillInfoCircle } from "react-icons/ai";
import { RiErrorWarningFill } from "react-icons/ri";
import colors from "theme/colors";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { apiProgressExport } from "api/apiCall";
import { Link } from "react-router-dom";
import AmplitudeHelper from "utils/analytics";

const CreateModal = ({ handleSendData }) => {
  const { t } = useTranslation("common");
  const toast = useToast();
  const { modal, resetModal } = getStore();
  const [email, setEmail] = useState();

  useEffect(() => {
    if (modal.type) {
      let params = {
        confirmation_type: modal.type,
      };
      if (modal?.screenType) {
        params = {
          ...params,
          ...{ source: `bulk_employee_${modal?.screenType}_page` },
        };
      }
      AmplitudeHelper.logEvent("confirmation_pop_up_appeared", params);
      setEmail(modal?.data?.email);
    }
  }, [modal]);

  const {
    isSuccess,
    isError,
    error,
    data,
    mutate: performProgressExport,
  } = useMutation({
    mutationFn: (payload) => apiProgressExport(payload, { email }),
  });

  useEffect(() => {
    if (isError) {
      toast.show({
        render: ({ id }) => (
          <ToastAlert
            toast={toast}
            id={id}
            title={`errorCodes.${error}`}
            status="error"
            variant="solid"
            isClosable
          />
        ),
        placement: "top-right",
      });
    }
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess) {
      toast.show({
        render: ({ id }) => (
          <ToastAlert
            toast={toast}
            id={id}
            title="home.export.success"
            status="success"
            variant="solid"
            isClosable
          />
        ),
        placement: "top-right",
      });
      resetModal();
    }
  }, [isSuccess, data]);

  const renderComponent = () => {
    switch (modal.type) {
      case CREATE_MODALS.singleCreate:
        return (
          <Modal height="150px">
            <Flex
              p="10px"
              borderRadius={12}
              mb="10px"
              w="100%"
              alignItems="center"
            >
              <HStack
                alignItems="center"
                space={3}
                style={{ flexWrap: "wrap" }}
              >
                <Box variant="ghost" alignSelf="flex-start">
                  <AiFillInfoCircle
                    size="25px"
                    color={colors.secondary.orchid}
                  />
                </Box>
                <VStack style={{ flexWrap: "wrap", flex: 1 }}>
                  <Text variant="sm-bold">{t("modal.create.confirm")}</Text>
                  <Text variant="xs" noOfLines={10}>
                    {t("modal.create.confirmDesc")}
                  </Text>
                </VStack>
              </HStack>
              <HStack
                space={2}
                alignSelf="flex-end"
                position="absolute"
                bottom="-50"
              >
                <Button
                  variant="outline"
                  borderColor={colors.neutral.darkMist}
                  px="10px"
                  onPress={() => {
                    AmplitudeHelper.logEvent("cancel_button_clicked", {
                      source: "continue_pop_up_confirmation",
                    });
                    resetModal();
                  }}
                >
                  <Text variant="xs" color={colors.neutral.darkMist}>
                    {t("modal.create.cancel")}
                  </Text>
                </Button>
                <Button
                  variant="solid"
                  px="30px"
                  onPress={() => {
                    AmplitudeHelper.logEvent("save_button_clicked", {
                      source: "continue_pop_up_confirmation",
                    });
                    handleSendData();
                    resetModal();
                  }}
                >
                  <Text variant="xs" color={colors.neutral.cotton}>
                    {t("modal.create.submit")}
                  </Text>
                </Button>
              </HStack>
            </Flex>
          </Modal>
        );
      case CREATE_MODALS.singleCreateCancel:
        return (
          <Modal height="170px">
            <Flex
              p="10px"
              borderRadius={12}
              mb="10px"
              w="100%"
              alignItems="center"
            >
              <HStack
                alignItems="center"
                space={3}
                style={{ flexWrap: "wrap" }}
              >
                <Box variant="ghost" alignSelf="flex-start">
                  <RiErrorWarningFill
                    size="25px"
                    color={colors.warning.sunflower}
                  />
                </Box>
                <VStack style={{ flexWrap: "wrap", flex: 1 }}>
                  <Text variant="sm-bold">{t("modal.cancel.title")}</Text>
                  <Text variant="xs" noOfLines={10}>
                    {t("modal.cancel.desc")}
                  </Text>
                </VStack>
              </HStack>
              <HStack
                space={2}
                alignSelf="flex-end"
                position="absolute"
                bottom="-50"
              >
                <Link to="/" style={{ textDecorationLine: "none" }}>
                  <Button
                    variant="outline"
                    borderColor={colors.neutral.darkMist}
                    px="10px"
                    onPress={() => {
                      AmplitudeHelper.logEvent("cancel_button_clicked", {
                        source: "cancel_pop_up_confirmation",
                      });
                      resetModal();
                    }}
                  >
                    <Text variant="xs" color={colors.neutral.darkMist}>
                      {t("modal.cancel.leave")}
                    </Text>
                  </Button>
                </Link>
                <Button
                  variant="solid"
                  px="30px"
                  onPress={() => {
                    AmplitudeHelper.logEvent("save_button_clicked", {
                      source: "cancel_pop_up_confirmation",
                    });
                    resetModal();
                  }}
                >
                  <Text variant="xs" color={colors.neutral.cotton}>
                    {t("modal.cancel.stay")}
                  </Text>
                </Button>
              </HStack>
            </Flex>
          </Modal>
        );
      case CREATE_MODALS.export:
        return (
          <Modal
            height="270px"
            title={t("modal.export.title")}
            reset={resetModal}
            closeable
          >
            <VStack space="3">
              <Divider />
              <VStack p="15px" space="3">
                <Text variant="xs">{t("modal.export.description")}</Text>
                <Input
                  placeholder={t("modal.export.placeholder")}
                  value={email}
                  onChange={(x) => setEmail(x.target.value)}
                  onEndEditing={() => {}}
                />
              </VStack>
              <Divider />
              <Button
                variant="solid"
                mx="15px"
                onPress={() => {
                  let sendData = modal?.data?.filter;
                  sendData.limit && delete sendData.limit;
                  sendData.skip && delete sendData.skip;
                  sendData.sortBy && delete sendData.sortBy;
                  sendData.sortOrder && delete sendData.sortOrder;

                  performProgressExport(sendData);
                }}
                isDisabled={email == ""}
              >
                <Text variant="xs" color={colors.neutral.cotton}>
                  {t("modal.export.button")}
                </Text>
              </Button>
            </VStack>
          </Modal>
        );
      case CREATE_MODALS.bulkCreate:
        return (
          <Modal height="150px">
            <Flex
              p="10px"
              borderRadius={12}
              mb="10px"
              w="100%"
              alignItems="center"
            >
              <HStack
                alignItems="center"
                space={3}
                style={{ flexWrap: "wrap" }}
              >
                <Box variant="ghost" alignSelf="flex-start">
                  <AiFillInfoCircle
                    size="25px"
                    color={colors.secondary.orchid}
                  />
                </Box>
                <VStack style={{ flexWrap: "wrap", flex: 1 }}>
                  <Text variant="sm-bold">{t("modal.bulk.confirm")}</Text>
                  <Text variant="xs" noOfLines={10}>
                    {t("modal.bulk.confirmDesc")}
                  </Text>
                </VStack>
              </HStack>

              <HStack
                space={2}
                alignSelf="flex-end"
                position="absolute"
                bottom="-50"
              >
                <Button
                  variant="outline"
                  borderColor={colors.neutral.darkMist}
                  px="10px"
                  onPress={() => {
                    AmplitudeHelper.logEvent("cancel_button_clicked", {
                      source: `bulk_${modal?.screenType}_continue_pop_up_confirmation`,
                    });
                    resetModal();
                  }}
                >
                  <Text variant="xs" color={colors.neutral.darkMist}>
                    {t("employeeCreation.bulk.cancel")}
                  </Text>
                </Button>
                <Button
                  variant="solid"
                  px="30px"
                  onPress={() => {
                    AmplitudeHelper.logEvent("save_button_clicked", {
                      source: `bulk_${modal?.screenType}_continue_pop_up_confirmation`,
                    });
                    handleSendData();
                    resetModal();
                  }}
                >
                  <Text variant="xs" color={colors.neutral.cotton}>
                    {t("modal.create.submit")}
                  </Text>
                </Button>
              </HStack>
            </Flex>
          </Modal>
        );
      case CREATE_MODALS.fileIsUploading:
        return (
          <Modal height="175px">
            <Flex
              p="10px"
              borderRadius={12}
              mb="10px"
              w="100%"
              alignItems="center"
            >
              <HStack
                alignItems="center"
                space={3}
                style={{ flexWrap: "wrap" }}
              >
                <Box variant="ghost" alignSelf="flex-start">
                  <AiFillInfoCircle
                    size="25px"
                    color={colors.warning.sunflower}
                  />
                </Box>
                <VStack style={{ flexWrap: "wrap", flex: 1 }}>
                  <Text variant="sm-bold">{t("modal.bulk.leavePage")}</Text>
                  <Text variant="xs" noOfLines={10}>
                    {t("modal.bulk.areYouSureYouWantToLeave")}
                  </Text>
                </VStack>
              </HStack>

              <HStack
                space={2}
                alignSelf="flex-end"
                position="absolute"
                bottom="-50"
              >
                <Link to={modal?.screenLink} style={{ textDecoration: "none" }}>
                  <Button
                    variant="outline"
                    borderColor={colors.neutral.darkMist}
                    px="10px"
                    onPress={() => {
                      resetModal();
                    }}
                  >
                    <Text variant="xs" color={colors.neutral.darkMist}>
                      {t("modal.bulk.leave")}
                    </Text>
                  </Button>
                </Link>
                <Button
                  variant="solid"
                  px="30px"
                  onPress={() => {
                    resetModal();
                  }}
                >
                  <Text variant="xs" color={colors.neutral.cotton}>
                    {t("modal.bulk.stayHere")}
                  </Text>
                </Button>
              </HStack>
            </Flex>
          </Modal>
        );
      default:
        return <></>;
    }
  };

  return <>{renderComponent()}</>;
};

export default CreateModal;
