import { Text, Box, Spinner } from "native-base";
import colors from "theme/colors";
import InfoToolTip from "atoms/InfoToolTip";
import styles from "./styles";

const SummaryBorderBox = ({
  color,
  width,
  title,
  data,
  loading,
  icon,
  tooltip,
  tooltipAmplitude,
}) => (
  <Box
    maxH="60px"
    style={{ ...styles.box, borderLeftColor: color, minWidth: width }}
    flex={1}
  >
    <InfoToolTip label={tooltip} amplitude={tooltipAmplitude}>
      {icon}
      {"  "}
      <Text variant="xxs" color={colors.neutral.darkMist}>
        {title}
      </Text>
    </InfoToolTip>
    {loading ? (
      <Spinner size="sm" />
    ) : (
      <Text variant="xs-bold" color={color}>
        {data}
      </Text>
    )}
  </Box>
);

export default SummaryBorderBox;
