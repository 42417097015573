import { useTranslation } from 'react-i18next'
import { Hidden, Text, Button } from 'native-base'
import { AiOutlineDownload } from 'react-icons/ai'

const DownloadButton = ({onPress}) => {
	const { t } = useTranslation('common')
	return (
		<Button
			variant="outline"
			leftIcon={<AiOutlineDownload size="17px" />}
			onPress={() => { if (onPress) onPress() }}
		>
			<Hidden till="md">
				<Text variant="xs">
					{' '}
					{t('home.filter.download')}
				</Text>
			</Hidden>
		</Button>
	)
}

export default DownloadButton
