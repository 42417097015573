import Amplitude from "amplitude-js";
import config from "../../config";

const amplitude = Amplitude.getInstance();

const AmplitudeHelper = {
  init: () => {
    amplitude.init(config.AMPLITUDE_KEY);
  },
  logEvent: (eventName, data) => {
    amplitude.logEvent(eventName, data);
  },
  setUserId: (userID, employerID) => {
    const sendingUserId = userID;
    amplitude.setUserId(sendingUserId.toString());
    amplitude.setUserProperties({
      environment: config.ENV_SENTRY,
      employerId: employerID,
    });
  },
  setLocation: async (lat, lng) => {
    amplitude.setUserProperties({
      Latitude: lat,
      Longitude: lng,
    });
  },
  setUserProperties: (data) => {
    amplitude.setUserProperties(data);
  },
  clearUserProperties: () => {
    amplitude.clearUserProperties();
  },
};

export default AmplitudeHelper;
