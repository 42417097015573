import colors from 'theme/colors';

const styles = {
		box: {
			backgroundColor: colors.neutral.cotton,
			height: '80%',
			borderRadius: 12,
			borderWidth: 1,
			borderColor: colors.disabled.smoke,
			borderLeftWidth: 17,
			paddingHorizontal: '10px',
			paddingVertical: '10px',
			marginVertical: '10px',
			justifyContent: 'center',
		},
}

export default styles
