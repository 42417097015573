import { VStack, Stack, Hidden } from "native-base";
import dayjs from "dayjs";
import FormContainer from "atoms/FormContainer";
import { AiOutlineUser } from "react-icons/ai";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import FormMenu from "atoms/FormMenu";
import ValidatedInput from "atoms/ValidatedInput";
import { GENDER_OPTIONS } from "global/constants";
import AmplitudeHelper from "utils/analytics";
import { formatDateToISOWithUTCOffset } from "utils/helpers";

const PersonalForm = ({ errors, formData, setFormData }) => {
  const direction = ["column", "column", "row", "row", "row"];
  const flex = [null, null, null, 0.5, 0.5];
  const space = [5, 5, 5, 10, 10];

  return (
    <FormContainer
      title="employeeCreation.single.personalData"
      description="employeeCreation.single.personalDataDesc"
      icon={<AiOutlineUser size={25} />}
    >
      <Stack direction={direction} space={space}>
        <VStack flex={flex} space={3}>
          <ValidatedInput
            label="name"
            errors={errors}
            title="employeeCreation.single.fullName"
            required
            value={formData.name ?? ""}
            setValue={(x) => setFormData({ ...formData, name: x })}
            amplitude="employee_name"
          />
          <ValidatedInput
            label="phone"
            errors={errors}
            title="employeeCreation.single.phone"
            required
            value={formData.phone ?? ""}
            allowOnlyNumbers
            setValue={(x) => setFormData({ ...formData, phone: x })}
            amplitude="phone_number"
          />
          <ValidatedInput
            label="gender"
            errors={errors}
            title="employeeCreation.single.gender"
            component={
              <FormMenu
                data={GENDER_OPTIONS}
                value={formData.gender?.name ?? ""}
                setValue={(x) => setFormData({ ...formData, gender: x })}
                isError={Object.prototype.hasOwnProperty.call(errors, "gender")}
                amplitude="gender"
              />
            }
            required
          />
        </VStack>
        <VStack flex={0.5} space={3}>
          <ValidatedInput
            errors={errors}
            title="employeeCreation.single.birthDate"
            label="birthDate"
            required
            component={
              <>
                <Hidden till="lg">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={["year", "month", "day"]}
                      value={
                        formData.birthDate
                          ? dayjs(new Date(formData.birthDate))
                          : ""
                      }
                      onChange={(x) => {
                        AmplitudeHelper.logEvent("date_of_birth_filled", {
                          source: "single_employee_creation_page",
                          input: formatDateToISOWithUTCOffset(new Date(x)),
                        });
                        setFormData({ ...formData, birthDate: new Date(x) });
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          error: Object.prototype.hasOwnProperty.call(
                            errors,
                            "birthDate"
                          ),
                        },
                      }}
                      renderInput={(params) => (
                        <TextField {...params} helperText={null} />
                      )}
                    />
                  </LocalizationProvider>
                </Hidden>
                <Hidden from="lg">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={["year", "month", "day"]}
                      value={
                        formData.birthDate
                          ? dayjs(new Date(formData.birthDate))
                          : ""
                      }
                      onChange={(x) => {
                        AmplitudeHelper.logEvent("date_of_birth_filled", {
                          source: "single_employee_creation_page",
                          input: formatDateToISOWithUTCOffset(new Date(x)),
                        });
                        setFormData({ ...formData, birthDate: new Date(x) });
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          error: Object.prototype.hasOwnProperty.call(
                            errors,
                            "birthDate"
                          ),
                        },
                      }}
                      renderInput={(params) => (
                        <TextField {...params} helperText={null} />
                      )}
                    />
                  </LocalizationProvider>
                </Hidden>
              </>
            }
          />
          <ValidatedInput
            label="ktp"
            errors={errors}
            title="employeeCreation.single.ktp"
            required
            value={formData.ktp ?? ""}
            setValue={(x) => setFormData({ ...formData, ktp: x })}
            amplitude="ktp"
          />
        </VStack>
      </Stack>
    </FormContainer>
  );
};

export default PersonalForm;
