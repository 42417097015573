import { useTranslation } from "react-i18next";
import { HStack, VStack, Text, Tooltip, Button, Spinner } from "native-base";
import colors from "theme/colors";
import InfoToolTip from "atoms/InfoToolTip";

const TeamSummary = ({ isLoading, data }) => {
  const { t } = useTranslation("common");

  return (
    <VStack space={2} py="5px" px="25px">
      <VStack>
        <InfoToolTip
          label={t("toolTip.totalEmployees")}
          amplitude="total_employees"
        >
          <Text variant="xxs" color={colors.neutral.darkMist}>
            {t("home.summary.totalEmployees")}
          </Text>
        </InfoToolTip>
        {isLoading ? (
          <Spinner size="sm" />
        ) : (
          <Text variant="md-bold" className="text">
            {data?.total ? `${data?.total} ${t("home.summary.employee")}` : ""}
          </Text>
        )}
      </VStack>
      <HStack space={10} flexWrap="wrap">
        <VStack>
          <InfoToolTip
            label={t("toolTip.totalActiveEmployees")}
            amplitude="total_active_employees"
          >
            <Text variant="xxs" color={colors.neutral.darkMist}>
              {t("home.summary.active")}
            </Text>
          </InfoToolTip>
          {isLoading ? (
            <Spinner size="sm" />
          ) : (
            <Text variant="sm-bold" color={colors.secondary.orchidBlue}>
              {data?.active
                ? `${data?.active} ${t("home.summary.employee")}`
                : ""}
            </Text>
          )}
        </VStack>
        <VStack>
          <InfoToolTip
            label={t("toolTip.totalSuspendEmployees")}
            amplitude="total_suspend_employees"
          >
            <Text variant="xxs" color={colors.neutral.darkMist}>
              {t("home.summary.inactive")}
            </Text>
          </InfoToolTip>
          {isLoading ? (
            <Spinner size="sm" />
          ) : (
            <Text variant="sm-bold" color={colors.secondary.orchidBlue}>
              {data?.suspended
                ? `${data?.suspended} ${t("home.summary.employee")}`
                : ""}
            </Text>
          )}
        </VStack>
      </HStack>
    </VStack>
  );
};

export default TeamSummary;
