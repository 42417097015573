/* Zustand middleware, generates a hook called useStore to get state and call setter functions  */

import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import createSlice from './createStore';

function createSliceParam() {
  return (set, get, api) => ({
    ...createSlice(set, get, api),
  });
}

function getStorageParam() {
  return {
    name: 'gg-web',
    storage: createJSONStorage(() => window.localStorage),
  };
}

const persistParameter = persist(createSliceParam(), getStorageParam());

const useStore = create(persistParameter);

export default useStore;
