import { useState } from "react";
import {
  Text,
  Button,
  ScrollView,
  FlatList,
  // TODO: line break added to move to next line
} from "native-base";
import colors from "theme/colors";
import { REASONS_DATA } from "global/constants";
import AmplitudeHelper from "utils/analytics";
import { getStore } from "store/storeUtils";
import { useTranslation } from "react-i18next";

const ReasonItem = ({
  item = {},
  setReason = () => {},
  isCollapsed = false,
  setIsCollapsed = () => {},
}) => {
  const { modal } = getStore();
  const { t } = useTranslation("common");
  return (
    <Button
      variant="unstyled"
      justifyContent="flex-start"
      onPress={() => {
        AmplitudeHelper.logEvent("suspension_category_chosen", {
          source: "team_page",
          employee_id: modal?.data?.employeeId,
          suspension_category: item?.name,
        });
        setReason(item?.name);
        setIsCollapsed(!isCollapsed);
      }}
    >
      <Text variant="xs">{t(`home.statusReason.${item?.name}`)}</Text>
    </Button>
  );
};
const ReasonsContent = ({
  reason = "",
  setReason = () => {},
  isCollapsed = false,
  setIsCollapsed = () => {},
}) => (
  <ScrollView w="100%" h="100%">
    <FlatList
      data={REASONS_DATA}
      renderItem={({ item, index }) => (
        <ReasonItem
          item={item}
          reason={reason}
          setReason={setReason}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
      )}
    />
  </ScrollView>
);

const ReasonDropdown = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { modal } = getStore();
  const { t } = useTranslation("common");

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignSelf: "center",
      width: "100%",
      zIndex: 999,
    },
    dropdown: {
      button: {
        width: "auto",
        height: "auto",
        border: `solid ${props.borderWidth || 1}px ${
          props.borderColor || "#d9d9d9"
        }`,
        borderRadius: 4,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 10,
        cursor: "pointer",
        backgroundColor: props.buttonColor || "white",
        zIndex: 0,
      },
    },
    collapsible: {
      width: "100%",
      marginTop: "max(1.7%, 40px)",
      marginLeft: 1,
      boxShadow: "0.5px 2px 10px #d1d1d1",
      backgroundColor: "white",
      position: "absolute",
      zIndex: 1,
      container: {
        maxWidth: "100%",
        backgroundColor: props.contentBackgroundColor || "white",
        overflow: "scroll",
        "overflow-x": "hidden",
        transition: "all 0.2s ease-out",
        height: isCollapsed ? 0 : 200,
      },
    },
    background: {
      height: "100vh",
      width: "100vw",
      backgroundColor: "rgba(52, 52, 52, 0.0)",
      position: "fixed",
      left: 0,
      top: 0,
      cursor: "default",
      zIndex: 0,
    },
  };

  return (
    <div style={styles.container}>
      <Button
        variant="outline"
        onPress={() => {
          AmplitudeHelper.logEvent("suspension_category_clicked", {
            source: "team_page",
            employee_id: modal?.data?.employeeId,
          });
          setIsCollapsed(!isCollapsed);
        }}
        justifyContent="flex-start"
        {...props}
      >
        <Text
          variant="xs"
          color={
            props.reason == ""
              ? colors.neutral.darkMist
              : colors.neutral.charcoal
          }
          fontSize="12px"
        >
          {" "}
          {props.reason == ""
            ? "Pilih Alasan Non-Aktif"
            : t(`home.statusReason.${props.reason}`)}
        </Text>
      </Button>
      {!isCollapsed && (
        <div
          style={styles.background}
          onClick={() => {
            setIsCollapsed(true);
          }}
        />
      )}
      <div style={styles.collapsible}>
        <div style={styles.collapsible.container}>
          <ReasonsContent
            reason={props.reason}
            setReason={props.setReason}
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
          />
        </div>
      </div>
    </div>
  );
};

export default ReasonDropdown;
