import { useToast } from "native-base";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { validate } from "utils/validation";
import { getStore } from "store/storeUtils";
import {
  CREATE_MODALS,
  EMPLOYEE_UPDATE_REQUEST_STATUS,
  VERIFY_STATUS_OPTIONS,
} from "global/constants";
import {
  apiSingleEmployeeData,
  apiSingleUpdate,
  fetchEmployeeRequestById,
} from "api/apiCall";
import { useMutation } from "@tanstack/react-query";
import ToastAlert from "atoms/ToastAlert";
import AmplitudeHelper from "utils/analytics";
import { useTranslation } from "react-i18next";
import colors from "theme/colors";
import { getBanksList } from "api/useQueryCalls";
import { getDifferenceBetweenObjects } from "utils/helpers";

const useUpdateConnect = ({ isEmployeeRequest = false }) => {
  const { t } = useTranslation("common");
  const toast = useToast();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const { modal, setModal } = getStore();

  const handleSubmit = () => {
    const newErrors = {};
    const required = [
      "name",
      "phone",
      // "birthDate",
      "ktp",
      "employeeId",
      // "group",
      "bankNumber",
      "bankName",
      "beneficiaryName",
      "employeeType",
      "status",
      "salary",
      "payDate",
      "gender",
      "dateJoined",
    ];
    const inputs = [
      "name",
      "phone",
      "ktp",
      "birthDate",
      "dateJoined",
      // 'employeeId',
      "bankNumber",
      "beneficiaryName",
      "salary",
    ];

    required.forEach((field) => {
      const fieldValue = formData[field];
      if (!fieldValue || fieldValue.toString().trim() == "") {
        newErrors[`${field}`] = "basicValidation.missingFields";
      }
    });

    inputs.forEach((field) => {
      let validation = "";

      if (field === "birthDate") {
        validation = validate[`${field}`](formData[`${new Date(field)}`]);
      } else if (field === "salary") {
        const salaryValue = formData[`${field}`];
        validation = validate[`${field}`](salaryValue.toString());
      } else {
        validation = validate[`${field}`](formData[`${field}`]);
      }
      if (
        validation != "" &&
        newErrors[`${field}`] != "basicValidation.missingFields"
      ) {
        newErrors[`${field}`] = validation;
      }
    });

    if (Object.keys(newErrors).length != 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      AmplitudeHelper.logEvent("save_button_clicked", {
        source: "detailed_employee_page",
      });
      setModal({
        ...modal,
        type: CREATE_MODALS.singleCreate,
        data: { ...formData },
      });
    }
  };

  const handleCancel = () => {
    AmplitudeHelper.logEvent("cancel_button_clicked", {
      source: "employee_single_creation_page",
    });
    setModal({ ...modal, type: CREATE_MODALS.singleCreateCancel });
  };

  const mutateEmployeeData = (data = {}) => {
    const { firstName = "", lastName = "", payDate = "-1" } = data;
    return {
      ...data,
      name: `${firstName ?? ""}`.concat(` ${lastName ?? ""}`),
      phone: data?.mobileNumber,
      ktp: data?.ktpNumber,
      employeeId: data?.employeeCode,
      bankName: { bankName: data?.bankName },
      dateJoined: new Date(data?.joinedAt),
      group: data?.groupName,
      bankNumber: data?.bankAccountNo,
      salary: data?.salaryAmount,
      employeeType: { data: data?.collarType },
      gender: t(data?.gender),
      status: {
        name: data?.employeeStatus,
      },
      payDate,
      hasPendingUpdateRequest: isEmployeeRequest
        ? true
        : data?.hasPendingUpdateRequest,
      cutOffDate:
        payDate == "-1" ? t("home.list.rows.endMonth") : payDate ?? "-",
    };
  };

  const {
    isSuccess,
    isError,
    error,
    data: selectedEmployeeData,
    mutate: getEmployeeId,
  } = useMutation({
    mutationFn: (employeeId) => apiSingleEmployeeData(employeeId),
  });

  const {
    isSuccess: employeeRequestIsSuccess,
    isError: employeeRequestIsError,
    error: employeeRequestError,
    data: employeeRequestData,
    mutate: getEmployeeRequestById,
  } = useMutation({
    mutationFn: (employeeId) => fetchEmployeeRequestById(employeeId),
  });

  const {
    isSuccess: updateSuccess,
    isError: isUpdateError,
    error: updateError,
    mutate: updateEmployee,
    data: singleEmployeeUpdateResponse,
  } = useMutation({ mutationFn: (data) => apiSingleUpdate(data) });

  const {
    isError: banksListIsError,
    error: banksListError,
    data: banksListData = [],
  } = getBanksList();

  useEffect(() => {
    if (banksListIsError) {
      toast.show({
        render: ({ id }) => (
          <ToastAlert
            toast={toast}
            id={id}
            title={`errorCodes.${banksListError}`}
            status="error"
            variant="solid"
            isClosable
          />
        ),
        placement: "top-right",
      });
    }
  }, [banksListIsError, banksListError]);

  useEffect(() => {
    if (selectedEmployeeData != {} && selectedEmployeeData) {
      setFormData(mutateEmployeeData(selectedEmployeeData));
    } else if (employeeRequestData != {} && employeeRequestData) {
      setFormData(mutateEmployeeData(employeeRequestData));
    }
  }, [selectedEmployeeData, employeeRequestData]);

  useEffect(() => {
    if (isUpdateError) {
      toast.show({
        render: ({ id }) => (
          <ToastAlert
            toast={toast}
            id={id}
            title={`errorCodes.${updateError}`}
            status="error"
            variant="solid"
            isClosable
          />
        ),
        placement: "top-right",
      });
    }
  }, [isUpdateError, updateError]);

  useEffect(() => {
    if (updateSuccess) {
      let title = "";
      let status = "";
      let style = {};
      const { status: updateStatus = "" } = singleEmployeeUpdateResponse;
      switch (updateStatus) {
        case EMPLOYEE_UPDATE_REQUEST_STATUS.COMPLETED:
          title = "employeeUpdation.single.update";
          status = "success";
          style = {
            backgroundColor: colors.success.polarGreen1,
            borderColor: colors.success.polarGreen3,
            borderWidth: 1,
          };
          break;
        case EMPLOYEE_UPDATE_REQUEST_STATUS.REJECTED:
        case EMPLOYEE_UPDATE_REQUEST_STATUS.FAILED:
          title = "employeeUpdation.single.rejected";
          status = "error";
          style = {
            backgroundColor: colors.error.dustRed,
            borderColor: colors.error.rose,
            borderWidth: 1,
          };
          break;
        case EMPLOYEE_UPDATE_REQUEST_STATUS.PENDING:
        case EMPLOYEE_UPDATE_REQUEST_STATUS.PROCESSING:
          title = "employeeUpdation.single.processing";
          status = "warning";
          style = {
            backgroundColor: colors.warning.gold,
            borderColor: colors.warning.sunflower,
            borderWidth: 1,
          };
          break;
        default:
          title = "employeeUpdation.single.update";
          status = "success";
      }
      toast.show({
        render: ({ id }) => (
          <ToastAlert
            toast={toast}
            id={id}
            title={title}
            status={status}
            isClosable
            style={style}
          />
        ),
        placement: "top-right",
      });
      navigate("/");
    }
  }, [updateSuccess]);

  const mutateData = (data) => {
    const nameParts = data?.name.split(" ");
    const firstName = nameParts[0];
    const lastName = nameParts.slice(1).join(" ");
    const basicDetails = {
      requestType: "update_employee",
      employeeId: data?.id,
      userId: data?.userId,
    };
    const mutatedData = {
      firstName,
      lastName,
      birthDate: dayjs(data.birthDate).format("YYYY-MM-DD"),
      mobileNumber: data.phone,
      gender: data.gender,
      ktpNumber: data.ktp,
      employeeCode: data.employeeId,
      joinedAt: dayjs(data.dateJoined).format("YYYY-MM-DD"),
      collarType: data.employeeType.data,
      groupName: data.group,
      salaryType: "FIXED_MONTH",
      employeeStatus:
        data.status.name === "Aktif" || data.status.name === "active"
          ? "active"
          : data.status.name === "Non Aktif" || data.status.name === "inactive"
          ? "inactive"
          : "withdraw_suspended",
      payDate:
        data?.payDate === "-1"
          ? data?.payDate
          : dayjs(data?.payDate).get("date"),
      salaryAmount: data.salary,
      bankName: data.bankName.bankName,
      bankId: data.bankName.id,
      bankAccountNo: data.bankNumber,
      beneficiaryName: data.beneficiaryName,
    };

    let initalData = {};
    if (selectedEmployeeData != {} && selectedEmployeeData) {
      initalData = selectedEmployeeData;
    } else if (employeeRequestData != {} && employeeRequestData) {
      initalData = employeeRequestData;
    }

    const differences = getDifferenceBetweenObjects({
      object1: initalData,
      object2: mutatedData,
    });
    return { ...differences, ...basicDetails };
  };

  const handleSendData = () => {
    let sendData = mutateData(formData);
    AmplitudeHelper.logEvent("save_button_clicked", {
      source: "detailed_employee_page",
      employee_id: sendData?.employeeId,
    });
    updateEmployee(sendData);
  };

  return {
    formData,
    banksList: banksListData?.list || [],
    getEmployeeId,
    getEmployeeRequestById,
    setFormData,
    handleSendData,
    handleSubmit,
    handleCancel,
    errors,
    setErrors,
  };
};

export default useUpdateConnect;
