import colors from "theme/colors";

export const styles = {
  boxContainer: {
    padding: 12,
    elevation: 20,
    width: 300,
    shadowColor: colors.neutral.charcoal,
    // shadowOffset: { width: 1, height: 2 },
    // shadowOpacity: 0.4,
    boxShadow: "0.5px 2px 10px #d1d1d1",
    borderRadius: 8,
  },
};
