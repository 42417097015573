import { useQuery } from "@tanstack/react-query";
import {
  apiTopProducts,
  apiSummary,
  apiToGetTransactions,
  apiEmployeeGroup,
  apiEmail,
  apiSingleEmployeeData,
  apiToGetBanksList,
} from "api/apiCall";

export function fetchTopProducts() {
  return useQuery(
    {
      queryKey: ["topProducts_api"],
      queryFn: apiTopProducts,
    },
    {
      enabled: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
}

export function fetchEmail() {
  return useQuery(
    {
      queryKey: ["email_api"],
      queryFn: apiEmail,
      enabled: true,
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
}

export function fetchEmployeeGroup() {
  return useQuery(
    {
      queryKey: ["employeeGroup_api"],
      queryFn: apiEmployeeGroup,
      enabled: true,
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
}

export function fetchSummary() {
  return useQuery(
    {
      queryKey: ["summary_api"],
      queryFn: apiSummary,
    },
    {
      enabled: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
}

export function getTransactionData(payload) {
  return useQuery(
    {
      queryKey: ["get_transaction_data"],
      queryFn: () => apiToGetTransactions(payload),
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
}

export function getSingleEmployeeData(payload) {
  return useQuery(
    {
      queryKey: ["get_single_employee_data"],
      queryFn: () => apiSingleEmployeeData(payload),
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
}

export function getBanksList() {
  return useQuery(
    {
      queryKey: ["get_banks_list"],
      queryFn: () => apiToGetBanksList(),
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
}
