import {
  HStack,
  Button,
  Text,
} from 'native-base';
import { forwardRef } from 'react';
import colors from 'theme/colors';
import { CiCalendar } from 'react-icons/ci';
import AmplitudeHelper from 'utils/analytics';

const CalendarInput = forwardRef(({
 value, onClick, isError, amplitude, isDisabled
}, ref) => (
  <HStack
    alignItems="center"
    borderWidth={isError ? '2' : '1'}
    borderRadius={4}
    borderColor={isError ? 'error.600' : colors.disabled.smoke}
    justifyContent="space-between"
    pr="10px"
    h="35px"
    width="100%"
  >
    <Button 
      variant="ghost" 
      isDisabled={isDisabled}
      onPress={() => {
        if (onClick) {
          AmplitudeHelper.logEvent(`${amplitude}_clicked`, {source: 'single_employee_creation_page'})
          onClick()
        }
      }} 
      w="95%" 
      justifyContent="space-between" 
      h="30px"
    >
      <Text variant="xs" color={value ? 'black' : colors.neutral.darkMist}>{value || ''}</Text>
    </Button>
    {!value && <CiCalendar size="20px" />}
  </HStack>
))

export default CalendarInput
