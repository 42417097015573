import { HStack, Stack } from 'native-base'
import DownloadButton from 'atoms/DownloadButton'
import SearchBar from 'atoms/SearchBar'
import AddEmployeeButton from 'atoms/AddEmployeeButton'
import FilterDropdown from 'atoms/FilterDropdown'
import { TEAM_MODALS } from 'global/constants'
import AmplitudeHelper from 'utils/analytics'
import { getStore } from 'store/storeUtils'
import FilterContent from 'molecules/FilterContent'

const FilterRow = ({ 
	onSearch, filterData, setFilterData, userEmail
}) => {
	const { modal, setModal } = getStore()

	const handleDownload = () => {
		AmplitudeHelper.logEvent('download_clicked', { source: 'team_page' })
		setModal({ ...modal, type: TEAM_MODALS.export, data: {email: userEmail} })
	}

	return (
		<Stack direction={['column', 'column', 'column', 'row', 'row']} p="4" space={[2, 2, 2, 10, 10]} zIndex={1}>
			<SearchBar
				onSearch={onSearch}
			/>
			<HStack w={['100%', '100%', '100%', '47%', '47%']} justifyContent="space-between">
				<HStack space={3}>
					<FilterDropdown filterData={filterData} setFilterData={setFilterData} />
					<DownloadButton onPress={handleDownload}/>
				</HStack>
				<AddEmployeeButton />
			</HStack>
		</Stack>

	)
}

export default FilterRow
