import dayjs from "dayjs";
import ContentContainer from "atoms/ContentContainer";
import TransactionPageSummary from "organisms/TransactionPageSummary";
import TransactionPageDownloadSection from "atoms/TransactionPageDownloadSection";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { apiToGetTransactions } from "api/apiCall";
import { useMutation } from "@tanstack/react-query";
import TeamModal from "molecules/TeamModal";
import { TRANSACTION_STATUS_TYPES } from "global/constants";
import AmplitudeHelper from "utils/analytics";
import { getFormattedEndDate, getFormattedStartDate } from "utils/helpers";
import styles from "./styles";

const Transactions = () => {
  const { t } = useTranslation("common");
  const now = dayjs().endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  const monthStart = dayjs()
    .date(1)
    .startOf("day")
    .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [txnDates, setTxnDates] = useState({
    endDate: now,
    startDate: monthStart,
  });
  const [name, setName] = useState("");
  const [filterData, setFilterData] = useState({
    txnStatus: [TRANSACTION_STATUS_TYPES.COMPLETED],
  });

  const {
    isLoading,
    isError,
    mutate: refetchTransactionsList,
    isSuccess,
    error,
    data: transactionsData,
  } = useMutation({ mutationFn: (payload) => apiToGetTransactions(payload) });

  useEffect(() => {
    AmplitudeHelper.logEvent("transaction_page");
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setTotalPageNumber(Math.ceil(transactionsData.count / 10));
    }
  }, [isSuccess, transactionsData]);

  const setPageNo = (pageNo) => {
    setCurrentPage(pageNo);
  };

  useEffect(() => {
    const data = {};
    if (name != "") {
      data.search = name;
    }

    if (Object.keys(txnDates) != 0 && txnDates?.endDate != "") {
      data.fromTransactionDate = getFormattedStartDate(txnDates?.startDate);
      data.toTransactionDate = getFormattedEndDate(txnDates?.endDate);
    }

    if (filterData?.fromTransactionAmount != "") {
      data.fromTransactionAmount = filterData?.minAmount;
    }
    if (filterData?.toTransactionAmount != "") {
      data.toTransactionAmount = filterData?.maxAmount;
    }
    if (filterData?.employeeGroup && filterData?.employeeGroup != []) {
      data.employeeGroupName = filterData?.employeeGroup.join(",");
    }
    if (filterData?.txnStatus != [] && filterData?.txnStatus) {
      data.transactionStatus = filterData?.txnStatus.join(",");
    }
    if (filterData.withdrawalPurpose != [] && filterData?.withdrawalPurpose) {
      data.transactionCategoryId = (filterData?.withdrawalPurpose || [])
        .map((i) => i?.id)
        .join(",");
    }

    if (currentPage > 1) {
      const params = { limit: 10, skip: (currentPage - 1) * 10 };
      const payload = { ...params, ...data };
      refetchTransactionsList(payload);
    } else {
      const params = { limit: 10, skip: 0 };
      const payload = { ...params, ...data };
      refetchTransactionsList(payload);
    }
  }, [currentPage, name, txnDates, filterData]);

  const onResetPress = () => {
    setTxnDates({ endDate: "", startDate: "" });
    setName("");
    setFilterData({});
    const params = { limit: 10, skip: 0 };
    refetchTransactionsList(params);
  };

  return (
    <ContentContainer
      title={t("transactionPage.transaction")}
      flex={styles.breakpoints.content}
    >
      <TransactionPageSummary
        data={transactionsData?.data}
        txnDates={txnDates}
        filterData={filterData}
        setFilterData={setFilterData}
        setTxnDates={setTxnDates}
        onResetPress={onResetPress}
      />
      <TransactionPageDownloadSection
        data={transactionsData}
        loading={isLoading}
        currentPage={currentPage}
        setPageNo={setPageNo}
        totalPageNumber={totalPageNumber}
        name={name}
        setName={setName}
      />
      <TeamModal
        filterData={filterData}
        setFilterData={setFilterData}
        name={name}
        txnDates={txnDates}
      />
    </ContentContainer>
  );
};

export default Transactions;
