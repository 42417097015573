/* Zustand combined reducer with actions */
import routeData from "global/routes";

const initialState = {
  /* AUTH */
  loginData: {
    phoneNumber: "",
    otp: "",
    otpType: "SMS",
    loginSuccess: false,
    token: "",
    isLoggedIn: false,
  },

  /* PROFILE */
  userData: {
    userName: "",
  },

  /* EMPLOYEE DATA */
  employeeGroups: [],

  /* ROUTING */
  globalRoute: routeData[0],

  /* UTILS */
  modal: { type: "", data: {} },

  /* FILE HANDLING STATES */
  noOfFilesUploading: 0,
  isFileUploading: false,
};

const createSlice = (set) => ({
  ...initialState,

  /* AUTH */
  setLoginData: (loginData) => set({ loginData }),
  resetLoginData: () => set({ loginData: { ...initialState.loginData } }),

  /* PROFILE */
  setUserData: (userData) => set({ userData }),

  /* EMPLOYEE DATA */
  setEmployeeGroups: (employeeGroups) => set({ employeeGroups }),

  /* ROUTING */
  setGlobalRoute: (globalRoute) => set({ globalRoute }),

  /* UTILS */
  setModal: (modal) => set({ modal }),
  resetModal: () => set({ modal: { ...initialState.modal } }),

  /* To Determine no of files currently being uploaded */
  setNoOfFilesUploading: (value) => set({ noOfFilesUploading: value }),

  /* To set if a file upload is going on */
  setIsFileUploading: (value) => set({ isFileUploading: value }),
});

export default createSlice;
