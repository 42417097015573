import { useEffect, useState } from 'react'
import { ScrollView, View } from 'native-base'

import ContentContainer from 'atoms/ContentContainer'
import TeamPageSummary from 'organisms/TeamPageSummary'
import TeamPageList from 'organisms/TeamPageList'
import TeamModal from 'molecules/TeamModal'
import AmplitudeHelper from 'utils/analytics'
import styles from './styles'

const Team = () => {
	const [filterData, setFilterData] = useState({})

	useEffect(() => { AmplitudeHelper.logEvent('team_page') }, [])
	return (
		<ContentContainer title="Kelola Tim" flex={styles.breakpoints.content}>
				<TeamPageSummary/>
				<TeamPageList filterData={filterData} setFilterData={setFilterData}/>
			<TeamModal filterData={filterData} setFilterData={setFilterData}/>
		</ContentContainer>
	)
}

export default Team
