/* 
In App.js:
- Router Container
- Native Base Provider
- React-query provider 
- i18n init
- Amplitude Init
- Sentry Init
*/
import { Root } from "pages";
import { BrowserRouter } from "react-router-dom";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "api/clientProvider";
import { NativeBaseProvider } from "native-base";
import { en, id } from "i18n/supportedLanguages";
import AmplitudeHelper from "utils/analytics";
import initializePerformanceTool from "utils/performance";
import { theme } from "theme/theme";

function App() {
  // INITIALIZE AMPLITUDE
  AmplitudeHelper.init();

  // INITIALIZE SENTRY
  initializePerformanceTool();

  i18next.init({
    compatibilityJSON: "v3",
    interpolation: { escapeValue: false }, // React already does escaping
    lng: "id",
    resources: {
      en: { common: en },
      id: { common: id },
    },
  });

  return (
    <I18nextProvider i18n={i18next}>
      <NativeBaseProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Root />
          </BrowserRouter>
        </QueryClientProvider>
      </NativeBaseProvider>
    </I18nextProvider>
  );
}

export default App;
