import { useEffect, useState } from "react";
import { useToast } from "native-base";
import { useTranslation } from "react-i18next";

import SummaryBox from "atoms/SummaryBox";
import FilterRow from "molecules/FilterRow";
import PaginationMui from "molecules/Pagination";

import Table from "molecules/Table";
import NoData from "atoms/NoData";
import { apiEmployees } from "api/apiCall";
import { useMutation } from "@tanstack/react-query";
import { STATUS_FILTER } from "global/constants";
import { numberWithDots } from "utils/helpers";
import ToastAlert from "atoms/ToastAlert";
import TeamModal from "molecules/TeamModal";

const TeamPageList = ({ isMinimize }) => {
  const toast = useToast();
  const { t } = useTranslation("common");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);

  // Search and Filter
  const [filterData, setFilterData] = useState({});
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [keyword, setKeyword] = useState("");

  const {
    isLoading,
    mutate: getEmployees,
    isSuccess,
    data: employeeData,
    isError: employeesError,
    error: employeesErrorMessage,
  } = useMutation({ mutationFn: (payload) => apiEmployees(payload) });

  useEffect(() => {
    if (employeesError) {
      toast.show({
        render: ({ id }) => (
          <ToastAlert
            id={id}
            toast={toast}
            title={`errorCodes.${employeesErrorMessage}`}
            variant="solid"
            status="error"
            isClosable
          />
        ),
        placement: "top-right",
      });
    }
  }, [employeesError, employeesErrorMessage]);

  const stringLimit = (str, char) => {
    return str?.length > char ?? 18 ? `${str?.slice(0, char ?? 18)}...` : str;
  };

  const joinAccounts = (arr) => {
    const additional = arr.length - 2 > 0 ? ` +${arr.length - 2}` : "";
    return `${stringLimit(arr[0], 15)},\n ${stringLimit(
      arr[1],
      15
    )}${additional}`;
  };

  const mutateData = (data) => {
    const result = [];
    data.forEach((item, index) => {
      result.push({
        id: index + 1,
        employeeId: item.employeeCode,
        employeeName: stringLimit(item.employeeName, 15),
        walletType: joinAccounts(item.employeeAccounts),
        unalteredEmpId: item.employeeId,
        cutOffDate:
          item.payDate == "-1" || item.payDate == "31"
            ? t("home.list.rows.endMonth")
            : item.payDate ?? "-",
        withdrawalLimit:
          item.withdrawalLimit == undefined
            ? "Rp 0"
            : `Rp ${numberWithDots(item.withdrawalLimit)}`,
        group: item.employeeGroup ? stringLimit(item.employeeGroup, 15) : "-",
        status: item.employeeStatus
          ? STATUS_FILTER[item.employeeStatus]?.name
          : "-",
        reasons: item.suspendedReason
          ? stringLimit(item.suspendedReason, 10)
          : "-",
      });
    });
    return result;
  };

  useEffect(() => {
    if (isSuccess) {
      setTotalPageNumber(Math.ceil(employeeData.count / 50));
      setCurrentData(mutateData(employeeData.data));
    }
  }, [isSuccess, employeeData]);

  useEffect(() => {
    let skipedElements = currentPage == 1 ? 0 : 50 * (currentPage - 1);
    const searchText = keyword ? { search: keyword } : {};
    const filteredDataToSend = { ...filterData, ...searchText };
    getEmployees({ ...filteredDataToSend, limit: 50, skip: skipedElements });
  }, [currentPage]);

  const handleSearch = (keyword) => {
    setCurrentPage(1);
    setKeyword(keyword);
    if (Object.keys(filterData).length != 0) {
      getEmployees({
        ...filterData,
        search: keyword,
        limit: 50,
        skip: 0,
      });
    } else {
      getEmployees({ search: keyword, limit: 50, skip: 0 });
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    let skipedElements = currentPage == 1 ? 0 : 50 * (currentPage - 1);
    const searchText = keyword ? { search: keyword } : {};
    const filteredData = Object.keys(filterData).length != 0 ? filterData : {};
    const filteredDataToSend = { ...filteredData, ...searchText };
    getEmployees({ ...filteredDataToSend, limit: 50, skip: skipedElements });
  }, [filterData]);

  return (
    <>
      <SummaryBox title={t("home.list.title")} maxWidth="1600px">
        <FilterRow
          onSearch={handleSearch}
          setFilterData={setFilterData}
          filterData={filterData}
        />
        {!currentData ? (
          <NoData />
        ) : (
          <>
            <Table
              isMinimize={isMinimize}
              data={currentData}
              isLoading={isLoading}
            />
            <PaginationMui
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPageNumber={totalPageNumber}
            />
          </>
        )}
      </SummaryBox>
      <TeamModal filterData={filterData} setFilterData={setFilterData} />
    </>
  );
};

export default TeamPageList;
