import { useState, forwardRef, useEffect } from "react";
import {
  Text,
  Button,
  VStack,
  HStack,
  Input,
  Radio,
  ScrollView,
  Checkbox,
  Menu,
  Box,
  CheckCircleIcon,
} from "native-base";
import { AiOutlineSearch } from "react-icons/ai";
import { BiTrashAlt } from "react-icons/bi";
import { BsChevronDown } from "react-icons/bs";
import colors from "theme/colors";
import { ACTION_OPTIONS, VERIFY_STATUS_OPTIONS } from "global/constants";
import { useTranslation } from "react-i18next";

const ProgressFilterContent = ({
  filterData,
  setFilterData,
  setIsCollapsed,
  initialFilterData,
}) => {
  const { t } = useTranslation("common");
  const [status, setStatus] = useState([]);
  const [actions, setActions] = useState(ACTION_OPTIONS);
  const [fileName, setFileName] = useState("");

  const handleSubmit = () => {
    const data = {
      sortBy: "uploadedAt",
      sortOrder: "desc",
    };
    status.length != 0 && (data.status = status.map((x) => x.data).join(","));
    actions.length != 0 &&
      (data.requestType = actions.map((x) => x.data).join(","));
    fileName != "" && (data.fileName = fileName);

    setFilterData(data);

    if (setIsCollapsed) {
      setIsCollapsed(true);
    }

    // AmplitudeHelper.logEvent('filter_saved', {
    // 	source: 'team_page',
    // 	filter_parameter: Object.keys(data)?.join(','),
    // 	filter_value: Object.values(data)?.join(','),
    // })

    // if (resetModal) {
    // 	resetModal()
    // }
  };

  const handleReset = () => {
    setActions([ACTION_OPTIONS[0]]);
    setStatus([]);
    setFileName("");

    setFilterData(initialFilterData);
    if (setIsCollapsed) {
      setIsCollapsed(true);
    }
    // if (resetModal) {
    // 	resetModal()
    // }
  };

  const getName = (arr, defaults) => {
    if (arr.length == defaults.length) return "Semua";
    let names = arr.map((item) => item.name);
    return names.join(",");
  };

  return (
    <ScrollView vertical>
      <VStack p="15px" space={2}>
        <Text variant="sm-bold">{t("filter.title")}</Text>
        <Text variant="xs">{t("filter.status")}</Text>
        <Menu
          closeOnSelect={false}
          trigger={(triggerProps) => (
            <HStack
              alignItems="center"
              borderWidth="1"
              borderRadius={4}
              borderColor={colors.disabled.smoke}
              justifyContent="space-between"
              pr="10px"
              h="35px"
              width="100%"
            >
              <Button
                variant="ghost"
                w="95%"
                justifyContent="space-between"
                h="30px"
                {...triggerProps}
              >
                <Text variant="xs">
                  {getName(status, VERIFY_STATUS_OPTIONS)}
                </Text>
              </Button>
              <BsChevronDown size="17px" />
            </HStack>
          )}
          style={{
            height: "auto",
            width: "auto",
            padding: "15px",
            backgroundColor: colors.neutral.cotton,
          }}
        >
          <VStack space={3}>
            <Checkbox
              isChecked={status.length == VERIFY_STATUS_OPTIONS.length}
              onChange={() => {
                if (status.length == VERIFY_STATUS_OPTIONS.length) {
                  setStatus([]);
                } else setStatus(VERIFY_STATUS_OPTIONS);
              }}
            >
              <Text variant="xs">Semua</Text>
            </Checkbox>
            <Checkbox.Group onChange={setStatus} value={status}>
              <VStack space={3}>
                {VERIFY_STATUS_OPTIONS.map((item) => (
                  <HStack space={2} alignItems="center">
                    <Checkbox value={item} />
                    <Text variant="xs">{item.name}</Text>
                  </HStack>
                ))}
              </VStack>
            </Checkbox.Group>
          </VStack>
        </Menu>
        <Text variant="xs">{t("filter.actionType")}</Text>
        <Menu
          closeOnSelect={false}
          trigger={(triggerProps) => (
            <HStack
              alignItems="center"
              borderWidth="1"
              borderRadius={4}
              borderColor={colors.disabled.smoke}
              justifyContent="space-between"
              pr="10px"
              h="35px"
              width="100%"
            >
              <Button
                variant="ghost"
                w="95%"
                justifyContent="space-between"
                h="30px"
                {...triggerProps}
              >
                <Text variant="xs">{getName(actions, ACTION_OPTIONS)}</Text>
              </Button>
              <BsChevronDown size="17px" />
            </HStack>
          )}
          style={{
            height: "auto",
            width: "auto",
            padding: "15px",
            backgroundColor: colors.neutral.cotton,
          }}
        >
          <VStack space={3}>
            <Checkbox
              isChecked={actions.length == ACTION_OPTIONS.length}
              onChange={() => {
                if (actions.length == ACTION_OPTIONS.length) setActions([]);
                else setActions(ACTION_OPTIONS);
              }}
            >
              <Text variant="xs">Semua</Text>
            </Checkbox>
            <Checkbox.Group onChange={setActions} value={actions}>
              <VStack space={3}>
                {ACTION_OPTIONS.map((item) => (
                  <HStack space={2} alignItems="center">
                    <Checkbox value={item} />
                    <Text variant="xs">{item.name}</Text>
                  </HStack>
                ))}
              </VStack>
            </Checkbox.Group>
          </VStack>
        </Menu>
        <Text variant="xs">{t("filter.fileName")}</Text>
        <HStack
          alignItems="center"
          space="2"
          borderWidth={1}
          borderColor={colors.disabled.smoke}
          borderRadius={4}
        >
          <Input
            placeholder="Cari Disini"
            flex="0.97"
            borderWidth={0}
            value={fileName}
            onChange={(x) => setFileName(x.target.value)}
          />
          <AiOutlineSearch size="20px" />
        </HStack>
        <HStack space={3} mt="20px">
          <Button
            variant="outline"
            flex="0.25"
            borderColor={colors.error.rose}
            leftIcon={<BiTrashAlt size="15px" color={colors.error.rose} />}
            onPress={handleReset}
          >
            <Text variant="sm" color={colors.error.rose}>
              {t("filter.reset")}
            </Text>
          </Button>
          <Button variant="solid" flex="0.75" onPress={handleSubmit}>
            <Text variant="sm" color={colors.neutral.cotton}>
              {t("filter.save")}
            </Text>
          </Button>
        </HStack>
      </VStack>
    </ScrollView>
  );
};

export default ProgressFilterContent;
