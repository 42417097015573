/* Login Form: Input phone number for auth */

import { Text, Button, HStack, VStack, Image, FormControl } from "native-base";
import { Logo } from "assets";
import { useTranslation } from "react-i18next";
import OtpComponent from "atoms/OtpComponent";
import AmplitudeHelper from "utils/analytics";
import styles from "./styles";
import { useConnect } from "./connect";

const OtpForm = () => {
  const { t } = useTranslation("common");
  const {
    otp,
    setOtp,
    otpError,
    loginData,
    resetLoginData,
    handleSubmit,
    handleReset,
    isLoading,
  } = useConnect();

  return (
    <VStack
      space={styles.breakpoints.containerSpacing}
      alignSelf="left"
      style={styles.container}
      width={styles.breakpoints.containerWidth}
    >
      <VStack space={0}>
        <Image
          height={styles.breakpoints.logoSize}
          width={styles.breakpoints.logoSize}
          resizeMode="contain"
          source={Logo}
          alt="logo"
        />
        <Text variant="xxl-bold" style={styles.welcome}>
          {t("login.welcome")}
        </Text>
      </VStack>
      <FormControl isInvalid={otpError.isError}>
        <FormControl.Label variant="login">
          {t("login.otp")}
          {loginData.phoneNumber ?? ""}
        </FormControl.Label>
        <OtpComponent
          otp={otp}
          setOtp={setOtp}
          phoneNumber={loginData.phoneNumber}
        />
        <FormControl.ErrorMessage variant="login">
          {otpError.errorMessage && t(`errorCodes.${otpError.errorMessage}`)}
        </FormControl.ErrorMessage>
      </FormControl>
      <HStack space={3}>
        <Button
          variant="solid"
          flex={1}
          isDisabled={
            otpError.isError || otp.join("").length === 0 || isLoading
          }
          onPress={handleSubmit}
        >
          <Text variant="sm" color="white">
            {t("login.signIn")}
          </Text>
        </Button>
      </HStack>
      <VStack flex={1} flexWrap="wrap">
        <Text variant="sm" style={styles.text}>
          {t("login.noReceive")}
          <Button variant="link" onPress={handleReset}>
            <Text variant="sm-bold" style={styles.link}>
              {t("login.resend")}
            </Text>
          </Button>
        </Text>
        <Text variant="sm" style={styles.text}>
          {t("login.or")}
          <Button
            variant="link"
            onPress={() => {
              AmplitudeHelper.logEvent("change_phone_number_clicked", {
                phone_number: loginData.phoneNumber,
              });
              resetLoginData();
            }}
          >
            <Text variant="sm-bold" style={styles.link}>
              {t("login.changePhone")}
            </Text>
          </Button>
        </Text>
      </VStack>
    </VStack>
  );
};

export default OtpForm;
