import * as Sentry from '@sentry/react';

export const SentryHelper = {
  setUser: (id, employerId, name) => {
    Sentry.setUser({ id, username: name, segment: employerId });
  },
  captureException: (exception) => {
    if (exception && exception.length > 0) Sentry.captureException(exception);
  },
  captureCustomException: (name = null, params = null) => {
    if (name === null || params === null) return;
    Sentry.captureException(new Error(name), {
      tags: {
        ...params,
      },
    });
  },
  clearUser: () => {
    // Call During logout
    Sentry.configureScope((scope) => scope.setUser(null));
  },
  captureMessage: (sMsg) => {
    Sentry.captureMessage(sMsg);
  },
};

export function beforeBreadCrumbEvent() {
  return (breadcrumb, hint) => {
    if (breadcrumb.category === 'xhr') {
      const data = {
        // eslint-disable-next-line no-underscore-dangle
        requestBody: hint?.xhr?.__sentry_xhr__?.body,
        response: hint?.xhr?.response,
        responseUrl: hint?.xhr?.responseURL,
      };
      return { ...breadcrumb, data };
    }
    return breadcrumb;
  };
}
