/* Login Form: Input phone number for auth */

import {
  Text,
  Input,
  Button,
  Stack,
  VStack,
  Image,
  FormControl,
} from "native-base";
import { Logo } from "assets";
import { useTranslation } from "react-i18next";
import AmplitudeHelper from "utils/analytics";
import styles from "./styles";
import { useConnect } from "./connect";

const LoginForm = () => {
  const { t } = useTranslation("common");
  const {
    phoneNumber,
    handlePhoneChange,
    phoneError,
    handleSubmit,
    isLoading,
  } = useConnect();

  const buttonArrangement = ["column", "column", "column", "row", "row"];

  return (
    <VStack
      space={styles.breakpoints.containerSpacing}
      alignSelf="left"
      style={styles.container}
      width={styles.breakpoints.containerWidth}
    >
      <VStack space={0}>
        <Image
          height={styles.breakpoints.logoSize}
          width={styles.breakpoints.logoSize}
          resizeMode="contain"
          source={Logo}
          alt="logo"
        />
        <Text variant="xxl-bold" style={styles.welcome}>
          {t("login.welcome")}
        </Text>
      </VStack>
      <FormControl isInvalid={phoneError.isError}>
        <FormControl.Label variant="login">
          {t("login.phone")}
        </FormControl.Label>
        <Input
          variant="login"
          size="xl"
          placeholder={t("login.phonePlaceholder")}
          value={phoneNumber}
          onChange={handlePhoneChange}
          onFocus={() => AmplitudeHelper.logEvent("phone_number_clicked")}
        />
        <FormControl.ErrorMessage variant="login">
          {phoneError.errorMessage && phoneError.errorMessage}
        </FormControl.ErrorMessage>
      </FormControl>
      <Stack space={3} flex={1} direction={buttonArrangement}>
        <Button
          variant="whatsapp"
          flex={0.5}
          onPress={() => handleSubmit("WHATSAPP")}
          isDisabled={
            phoneError.isError || phoneNumber.length == 0 || isLoading
          }
        >
          <Text variant={{ base: "xxs", md: "xs" }} color="white">
            {t("login.whatsapp")}
          </Text>
        </Button>
        <Button
          variant="solid"
          flex={0.5}
          onPress={() => handleSubmit("SMS")}
          isDisabled={
            phoneError.isError || phoneNumber.length == 0 || isLoading
          }
        >
          <Text variant={{ base: "xxs", md: "xs" }} color="white">
            {t("login.sms")}
          </Text>
        </Button>
      </Stack>
    </VStack>
  );
};

export default LoginForm;
