import {
	Text, Box, HStack, Button, Hidden,
} from 'native-base'
import { FcMinus } from 'react-icons/fc'
import { AiOutlinePlus } from 'react-icons/ai'
import styles from './styles'

const SummaryBox = (props) => {
	return (
		<Box style={styles.box} overflow="hidden" {...props}>
			<HStack justifyContent="space-between" alignItems="center" style={styles.titleGroup}>
				<Text variant="sm-bold" style={styles.title}>{props.title}</Text>
				<Hidden from="md">
					{props.shouldMinimize
						&& (
							<Button variant="ghost" onPress={() => props.setMinimize(!props.minimize)} h="5px" alignItems="center">
								{props.minimize ? <AiOutlinePlus size="25px" /> : <FcMinus size="25px" />}
							</Button>
						)}
				</Hidden>
			</HStack>
			{props.children}
		</Box>
	)
}

export default SummaryBox
