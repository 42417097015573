import { useEffect, useState } from "react";
import { Hidden, Text, VStack, Button, FlatList } from "native-base";
import colors from "theme/colors";
import RouteButton from "atoms/RouteButton";
import routeData from "global/routes";
import { BsWhatsapp, BsPower } from "react-icons/bs";
import secureStore from "utils/secureStore";
import { getStore } from "store/storeUtils";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CREATE_MODALS, WHATSAPP_URL } from "global/constants";
import AmplitudeHelper from "utils/analytics";
import { SentryHelper } from "utils/performance/SentryHelper";
import styles from "./styles";

const NavBar = () => {
  const { t } = useTranslation("common");
  const {
    globalRoute,
    setGlobalRoute,
    resetLoginData,
    isFileUploading,
    modal,
    setModal,
  } = getStore();
  const [currentRoute, setCurrentRoute] = useState(globalRoute);

  useEffect(() => {
    setGlobalRoute(currentRoute);
  }, [currentRoute]);

  const logout = () => {
    secureStore.removeItemAsync("accessToken");
    secureStore.removeItemAsync("refreshToken");
    AmplitudeHelper.clearUserProperties();
    SentryHelper.clearUser();
    resetLoginData();
  };

  const ButtonList = () => {
    const showModalForDisableUpload = (screenLink = "/") => {
      setModal({
        ...modal,
        type: CREATE_MODALS.fileIsUploading,
        screenLink,
      });
    };
    return (
      <FlatList
        data={routeData}
        renderItem={(item) => {
          return !isFileUploading ? (
            <Link style={{ textDecorationLine: "none" }} to={item.item.path}>
              <RouteButton
                route={item.item}
                currentRoute={currentRoute}
                setCurrentRoute={setCurrentRoute}
              />
            </Link>
          ) : (
            <RouteButton
              route={item.item}
              currentRoute={currentRoute}
              setCurrentRoute={setCurrentRoute}
              isFileUploading={isFileUploading}
              showModalForDisableUpload={() => {
                showModalForDisableUpload(item?.item?.path);
              }}
            />
          );
        }}
      />
    );
  };

  const WhatsappButton = () => (
    <Button
      variant="rounded"
      bg={colors.variants.clover}
      w={{ base: "100%", md: "90%" }}
      onPress={() => {
        open(WHATSAPP_URL);
      }}
    >
      <Text color={colors.neutral.cotton} ml={1}>
        <BsWhatsapp
          size="15px"
          color={colors.neutral.cotton}
          style={{ marginBottom: "-3px" }}
        />
        {"  "}
        {t("sideBar.whatsappSupport")}
      </Text>
    </Button>
  );

  const LogoutButton = () => (
    <Button
      variant="rounded"
      bg={colors.secondary.orchid}
      w="90%"
      onPress={logout}
      borderWidth={1}
      borderColor={colors.neutral.cotton}
    >
      <Text variant="xs" color={colors.neutral.cotton}>
        <BsPower size="15px" style={{ marginBottom: "-3px" }} />
        {"  "}
        Logout
      </Text>
    </Button>
  );

  return (
    <Hidden till="lg">
      <VStack flex={0.15} style={styles.sideBar}>
        <VStack space={4} w="90%">
          <ButtonList />
        </VStack>
        <VStack space={2} w="90%">
          <WhatsappButton />
          <LogoutButton />
        </VStack>
      </VStack>
    </Hidden>
  );
};

export default NavBar;
