import { VStack, View, Text, HStack, Button, Flex, Hidden } from "native-base";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import colors from "theme/colors";
import { useDropzone } from "react-dropzone";
import { BiArrowToTop } from "react-icons/bi";
import { formatBytes, stringLimit } from "utils/helpers";
import { AiOutlineClockCircle } from "react-icons/ai";
import AmplitudeHelper from "utils/analytics";

const DropzoneXlsx = ({
  selectedFile = [],
  setSelectedFile = () => {},
  errors = "",
  setErrors = () => {},
  isLoading = false,
  screenType = "",
}) => {
  const { t } = useTranslation("common");

  const onDrop = (acceptedFiles, fileRejections) => {
    if (acceptedFiles.length > 0) {
      setSelectedFile(acceptedFiles);
    } else {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setErrors(t("employeeCreation.bulk.maxFileSizeExceeded"));
          }

          if (err.code === "file-invalid-type") {
            setErrors(t("employeeCreation.bulk.incorrectFileFormat"));
          }
        });
      });
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    maxSize: 25 * 1024 * 1024,
    maxFiles: 1,
    multiple: false,
  });

  const wipeSelectedFile = () => {
    setErrors("");
    setSelectedFile([]);
  };

  const changeSelectedFile = () => {
    setErrors("");
    setSelectedFile([]);
    open();
  };

  const flexibleTextSectionWhenEmpty = () => {
    return (
      <>
        <Hidden till="md">
          <Flex flexDirection="row" flexWrap="wrap">
            <Text
              variant="xs-bold"
              underline
              color={colors.secondary.orchid}
              mr="4px"
            >
              {t("employeeCreation.bulk.clickHereToUpload")}
            </Text>
            <Text>{t("employeeCreation.bulk.orDragFilesToThisArea")}</Text>
          </Flex>
        </Hidden>
        <Hidden from="md">
          <Flex>
            <Text
              variant="xs"
              underline
              color={colors.secondary.orchid}
              mr="4px"
            >
              {t("employeeCreation.bulk.clickHereToUpload")}
            </Text>
            <Text>{t("employeeCreation.bulk.orDragFilesToThisArea")}</Text>
          </Flex>
        </Hidden>
      </>
    );
  };

  const fileNameSection = () => (
    <>
      <Hidden till="md">
        <Text textAlign="center">
          {selectedFile[0].path}
          {" ".concat(formatBytes(selectedFile[0].size))}
        </Text>
      </Hidden>
      <Hidden from="md">
        <Text variant="xxs" textAlign="center">
          {stringLimit(selectedFile[0].path)}
          {" ".concat(formatBytes(selectedFile[0].size))}
        </Text>
      </Hidden>
    </>
  );

  const onSelectFile = () => {
    AmplitudeHelper.logEvent("upload_file_clicked", {
      source: `bulk_employee_${screenType}_page`,
    });
    changeSelectedFile();
  };

  return (
    <VStack
      bg={colors.neutral.mist}
      borderRadius="12px"
      borderColor={colors.neutral.darkMist}
      borderWidth="1px"
      borderStyle="dashed"
      alignItems="center"
      justifyContent="center"
      p="24px"
      m="24px"
    >
      {Object.keys(selectedFile).length > 0 && (
        <HStack alignItems="center" justifyContent="center">
          {isLoading && (
            <AiOutlineClockCircle
              size="24px"
              color={colors.disabled.smoke}
              style={{ marginRight: 8 }}
            />
          )}
          <View>
            {fileNameSection()}
            {!isLoading && (
              <HStack alignItems="center" justifyContent="center">
                <Button
                  size="sm"
                  variant="link"
                  color={colors.secondary.orchid}
                  onPress={changeSelectedFile}
                >
                  {t("employeeCreation.bulk.changeDocument")}
                </Button>
                <Text px="4px">{t("employeeCreation.bulk.or")}</Text>
                <Button
                  size="sm"
                  variant="link"
                  color={colors.error.rose}
                  onPress={wipeSelectedFile}
                >
                  <Text color={colors.error.rose}>
                    {t("employeeCreation.bulk.wipe")}
                  </Text>
                </Button>
              </HStack>
            )}
            {isLoading && (
              <>
                <Hidden till="md">
                  <Text color={colors.neutral.darkMist}>
                    {t("employeeCreation.bulk.uploadingPleaseWait")}
                  </Text>
                </Hidden>
                <Hidden from="md">
                  <Text color={colors.neutral.darkMist} variant="xxs">
                    {t("employeeCreation.bulk.uploadingPleaseWait")}
                  </Text>
                </Hidden>
              </>
            )}
          </View>
        </HStack>
      )}
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {Object.keys(selectedFile).length == 0 && (
          <VStack alignItems="center" justifyContent="center">
            <View bg={colors.disabled.smoke50} borderRadius="24px" mb="8px">
              <BiArrowToTop size="70px" color={colors.disabled.smoke} p="8px" />
            </View>
            <Button
              variant="unstyled"
              textAlign="center"
              onPress={onSelectFile}
            >
              {flexibleTextSectionWhenEmpty()}
              <Text variant="xxs" mt="8px">
                {t("employeeCreation.bulk.maxFileSize25mb")}
              </Text>
            </Button>
            <p
              style={{
                color: colors.error.rose,
                padding: 5,
                margin: 0,
                fontSize: 14,
              }}
            >
              {errors}
            </p>
          </VStack>
        )}
      </div>
    </VStack>
  );
};

export default DropzoneXlsx;
