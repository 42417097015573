import { useEffect, useState } from "react";
import Breadcrumbs from "atoms/Breadcrumbs";
import ContentContainer from "atoms/ContentContainer";
import { useToast } from "native-base";
import ToastAlert from "atoms/ToastAlert";
import CreateModal from "molecules/CreateModal";
import { useTranslation } from "react-i18next";
import InfoBox from "atoms/InfoBox";
import SummaryBox from "atoms/SummaryBox";
import ProgressFilterRow from "molecules/ProgressFilterRow";
import ProgressTable from "molecules/ProgressTable";
import PaginationMui from "molecules/Pagination";
import { useMutation } from "@tanstack/react-query";
import { apiInProgress } from "api/apiCall";
import { VERIFY_STATUS_OPTIONS } from "global/constants";
import { fetchEmail } from "api/useQueryCalls";
import NoData from "atoms/NoData";
import { getEmployeeName, stringLimit } from "utils/helpers";

const InStatusCheck = () => {
  const { t } = useTranslation("common");
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [userEmail, setUserEmail] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [tableData, setTableData] = useState([]);
  const initialFilterData = {
    requestType: "update_employee_status",
    sortBy: "uploadedAt",
    sortOrder: "desc",
    limit: 50,
    skip: 0,
  };
  const [filterData, setFilterData] = useState(initialFilterData);

  const getName = (item, defaults) => {
    let found = defaults.findIndex((x) => x.data == item);
    if (found != -1) {
      return defaults[found];
    }
  };

  const mutateData = (data) => {
    const result = [];
    data?.data?.forEach((item, index) => {
      let statusReason = "";
      if (item?.statusReason) {
        const reasons = (item?.statusReason || "").split(", ");
        const translatedReasons = [];
        reasons.forEach((reason) => {
          if (reason.startsWith("GG")) {
            translatedReasons.push(t(`errorCodes.${reason}`));
          } else {
            translatedReasons.push(reason);
          }
        });
        statusReason = translatedReasons.join(", ");
      } else {
        statusReason = "-";
      }
      result.push({
        id: index + 1,
        employeeId: item?.employeeCode,
        unalteredEmpId: item?.id,
        employeeName: getEmployeeName(item),
        createdAt: new Date(item?.createdAt).toLocaleString().substring(0, 10),
        fileName: stringLimit(item?.fileName) ?? "-",
        status: getName(item?.status, VERIFY_STATUS_OPTIONS).name ?? "-",
        statusColor: getName(item?.status, VERIFY_STATUS_OPTIONS).color ?? "-",
        statusReason: stringLimit(statusReason),
        employeeStatus: item?.employeeStatus,
      });
    });
    return result;
  };

  const { data: emailData, isSuccess: emailSuccess } = fetchEmail();
  const {
    isSuccess,
    isError,
    error,
    data,
    isLoading,
    mutate: getTableData,
  } = useMutation({ mutationFn: (payload) => apiInProgress(payload) });

  useEffect(() => {
    if (isSuccess) {
      setTotalPages(Math.ceil(data.count / 50));
      setTableData(mutateData(data));
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isError) {
      toast.show({
        render: ({ id }) => (
          <ToastAlert
            id={id}
            toast={toast}
            title={`errorCodes.${error}`}
            variant="solid"
            status="error"
            isClosable
          />
        ),
        placement: "top-right",
      });
    }
  }, [isError, error]);

  useEffect(() => {
    if (currentPage == 1) {
      getTableData({ ...filterData });
    } else {
      getTableData({ ...filterData, skip: 50 * (currentPage - 1) });
    }
  }, [currentPage, filterData]);

  useEffect(() => {
    if (emailSuccess) {
      setUserEmail(emailData);
    }
  }, [emailSuccess, emailData]);

  return (
    <>
      <ContentContainer
        title={t("inStatusCheck.title")}
        flex={[1, 1, 1, 0.85, 0.85]}
      >
        <Breadcrumbs
          currentPage="sideBar.inStatusCheck"
          style={{ marginTop: "10px", marginHorizontal: "0px" }}
        />
        <InfoBox title={t("inProgress.waiting")} warning />
        <SummaryBox
          title={t("inStatusCheck.table.header.title")}
          maxWidth="1400px"
        >
          <ProgressFilterRow
            filterData={filterData}
            setFilterData={setFilterData}
            initialFilterData={initialFilterData}
            userEmail={userEmail}
          />
          {!tableData || tableData.length == 0 ? (
            <NoData />
          ) : (
            <ProgressTable
              isLoading={isLoading}
              data={tableData}
              isStatusCheck
            />
          )}
          <PaginationMui
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPageNumber={totalPages}
          />
        </SummaryBox>
        <CreateModal />
      </ContentContainer>
    </>
  );
};

export default InStatusCheck;
