import { useToast } from "native-base";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { validate } from "utils/validation";
import { getStore } from "store/storeUtils";
import {
  CREATE_MODALS,
  EMPLOYEE_UPDATE_REQUEST_STATUS,
} from "global/constants";
import dayjs from "dayjs";
import { apiSingleCreate } from "api/apiCall";
import { useMutation } from "@tanstack/react-query";
import ToastAlert from "atoms/ToastAlert";
import AmplitudeHelper from "utils/analytics";
import colors from "theme/colors";
import { getBanksList } from "api/useQueryCalls";

const useConnect = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const { modal, setModal } = getStore();

  const handleSubmit = () => {
    const newErrors = {};
    const required = [
      "name",
      "phone",
      // "birthDate",
      "ktp",
      "employeeId",
      // "group",
      "bankNumber",
      "bankName",
      "beneficiaryName",
      "employeeType",
      "status",
      "salary",
      "payDate",
      "gender",
      "dateJoined",
    ];
    const inputs = [
      "name",
      "phone",
      "ktp",
      "birthDate",
      "dateJoined",
      // 'employeeId',
      "bankNumber",
      "beneficiaryName",
      "salary",
    ];

    required.forEach((field) => {
      !formData[field] &&
        (newErrors[`${field}`] = "basicValidation.missingFields");
    });

    inputs.forEach((field) => {
      let validation = validate[`${field}`](formData[`${field}`]);
      if (
        validation != "" &&
        newErrors[`${field}`] != "basicValidation.missingFields"
      ) {
        newErrors[`${field}`] = validation;
      }
    });

    if (Object.keys(newErrors).length != 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      AmplitudeHelper.logEvent("save_button_clicked", {
        source: "employee_single_creation_page",
      });
      setModal({
        ...modal,
        type: CREATE_MODALS.singleCreate,
        data: { ...formData },
      });
    }
  };

  const handleCancel = () => {
    AmplitudeHelper.logEvent("cancel_button_clicked", {
      source: "employee_single_creation_page",
    });
    setModal({ ...modal, type: CREATE_MODALS.singleCreateCancel });
  };

  const {
    isSuccess: createSuccess,
    isError: isCreateError,
    error: createError,
    mutate: createEmployee,
    data: createEmployeeResponse,
  } = useMutation({ mutationFn: (data) => apiSingleCreate(data) });

  const {
    isError: banksListIsError,
    error: banksListError,
    data: banksListData = [],
  } = getBanksList();

  useEffect(() => {
    if (banksListIsError) {
      toast.show({
        render: ({ id }) => (
          <ToastAlert
            toast={toast}
            id={id}
            title={`errorCodes.${banksListError}`}
            status="error"
            variant="solid"
            isClosable
          />
        ),
        placement: "top-right",
      });
    }
  }, [banksListIsError, banksListError]);

  useEffect(() => {
    if (isCreateError) {
      toast.show({
        render: ({ id }) => (
          <ToastAlert
            toast={toast}
            id={id}
            title={`errorCodes.${createError}`}
            status="error"
            variant="solid"
            isClosable
          />
        ),
        placement: "top-right",
      });
    }
  }, [isCreateError, createError]);

  useEffect(() => {
    if (createSuccess) {
      let title = "";
      let status = "";
      let style = {};
      const { status: createStatus = "" } = createEmployeeResponse;
      switch (createStatus) {
        case EMPLOYEE_UPDATE_REQUEST_STATUS.COMPLETED:
          title = "employeeCreation.single.success";
          status = "success";
          style = {
            backgroundColor: colors.success.polarGreen1,
            borderColor: colors.success.polarGreen3,
            borderWidth: 1,
          };
          break;
        case EMPLOYEE_UPDATE_REQUEST_STATUS.REJECTED:
        case EMPLOYEE_UPDATE_REQUEST_STATUS.FAILED:
          title = "employeeUpdation.single.error";
          status = "error";
          style = {
            backgroundColor: colors.error.dustRed,
            borderColor: colors.error.rose,
            borderWidth: 1,
          };
          break;
        case EMPLOYEE_UPDATE_REQUEST_STATUS.PENDING:
        case EMPLOYEE_UPDATE_REQUEST_STATUS.PROCESSING:
          title = "employeeUpdation.single.pending";
          status = "warning";
          style = {
            backgroundColor: colors.warning.gold,
            borderColor: colors.warning.sunflower,
            borderWidth: 1,
          };
          break;
        default:
          title = "employeeUpdation.single.update";
          status = "success";
      }
      toast.show({
        render: ({ id }) => (
          <ToastAlert
            toast={toast}
            id={id}
            title={title}
            status={status}
            isClosable
            style={style}
          />
        ),
        placement: "top-right",
      });
      navigate("/");
    }
  }, [createSuccess]);

  const mutateData = (data) => {
    const nameParts = data?.name.split(" ");

    const firstName = nameParts[0];
    const lastName = nameParts.slice(1).join(" ");
    return {
      requestType: "create_employee",
      firstName,
      lastName,
      birthDate: data?.birthDate
        ? dayjs(data.birthDate).format("YYYY-MM-DD")
        : undefined,
      mobileNumber: data.phone,
      gender: data.gender.data,
      ktpNumber: data.ktp,
      employeeCode: data.employeeId,
      joinedAt: dayjs(data.dateJoined).format("YYYY-MM-DD"),
      collarType: data.employeeType.data,
      groupName: data.group,
      salaryType: "FIXED_MONTH",
      employeeStatus:
        data.status.name === "Aktif" || data.status.name === "active"
          ? "active"
          : "withdraw_suspended",
      payDate:
        typeof data?.payDate === "object"
          ? dayjs(data?.payDate).get("date")
          : data?.payDate,
      salaryAmount: data.salary,
      bankName: data.bankName.bankName,
      bankId: data.bankName.id,
      bankAccountNo: data.bankNumber,
      beneficiaryName: data.beneficiaryName,
    };
  };

  const handleSendData = () => {
    let sendData = mutateData(formData);
    createEmployee(sendData);
  };

  return {
    formData,
    setFormData,
    banksList: banksListData?.list || [],
    handleSendData,
    handleSubmit,
    handleCancel,
    errors,
    setErrors,
  };
};

export default useConnect;
