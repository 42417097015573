import colors from "theme/colors";

const styles = {
  box: {
    backgroundColor: colors.neutral.cotton,
    borderRadius: 12,
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  dataSummaryContainer: {
    backgroundColor: colors.neutral.cotton,
    borderRadius: 12,
    paddingHorizontal: 24,
    paddingVertical: 16,
    marginTop: 24,
    zIndex: -1,
    elevation: -1,
  },
  transactionDataSummaryHeader: {
    color: "#3863F0",
    marginBottom: 2,
  },
  filterSectionContainer: {
    backgroundColor: colors.neutral.cotton,
    borderRadius: 12,
    paddingHorizontal: 24,
    paddingVertical: 16,
    marginTop: 12,
  },
};

export default styles;
