import { useState } from "react";
import { HStack, Menu, Pressable, Text } from "native-base";
import ProgressFilterDropdown from "atoms/ProgressFilterDropdown";
import ProgressFilterContent from "molecules/ProgressFilterContent";
import { getStore } from "store/storeUtils";
import { CREATE_MODALS } from "global/constants";
import { AiOutlineDownload } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import colors from "theme/colors";

const ProgressFilterRow = ({
  filterData,
  setFilterData,
  initialFilterData,
  userEmail,
}) => {
  const { modal, setModal } = getStore();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const { t } = useTranslation("common");

  const onDownloadPress = (shouldFiltersApply) => {
    if (!shouldFiltersApply) {
      setModal({
        ...modal,
        type: CREATE_MODALS.export,
        data: { filter: initialFilterData, email: userEmail },
      });
    } else {
      setModal({
        ...modal,
        type: CREATE_MODALS.export,
        data: { filter: filterData, email: userEmail },
      });
    }
  };
  return (
    <HStack space={3} p="15px" zIndex={3}>
      <Menu
        w="190"
        trigger={(triggerProps) => {
          return (
            <Pressable
              accessibilityLabel="Employee Group Filter"
              {...triggerProps}
            >
              <HStack
                alignItems="center"
                space="2"
                borderWidth={1}
                borderColor={colors.disabled.smoke}
                borderRadius={4}
                padding={2}
              >
                <Text fontSize="xs">{t("home.filter.download")}</Text>
                <AiOutlineDownload size="17px" />
              </HStack>
            </Pressable>
          );
        }}
      >
        <Menu.Item onPress={() => onDownloadPress(false)}>
          {t("transactionPage.downloadAllData")}
        </Menu.Item>
        <Menu.Item onPress={() => onDownloadPress(true)}>
          {t("transactionPage.downloadDataWithFilters")}
        </Menu.Item>
      </Menu>
      <ProgressFilterDropdown
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      >
        <ProgressFilterContent
          filterData={filterData}
          setFilterData={setFilterData}
          initialFilterData={initialFilterData}
          setIsCollapsed={setIsCollapsed}
        />
      </ProgressFilterDropdown>
    </HStack>
  );
};

export default ProgressFilterRow;
