import {
  VStack,
  Stack,
  HStack,
  Text,
  Box,
  Radio,
  Input,
  Hidden,
  Pressable,
} from "native-base";
import dayjs from "dayjs";
import {
  formatDateToISOWithUTCOffset,
  numberWithDots,
  removeNonNumeric,
} from "utils/helpers";
import FormContainer from "atoms/FormContainer";
import { AiOutlineSolution } from "react-icons/ai";
import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import CalendarInput from "atoms/CalendarInput";
import FormMenu from "atoms/FormMenu";
import ValidatedInput from "atoms/ValidatedInput";
import InfoBox from "atoms/InfoBox";
import { STATUS_OPTIONS, TEAM_MODALS, TYPE_OPTIONS } from "global/constants";
import colors from "theme/colors";
import { useTranslation } from "react-i18next";
import AmplitudeHelper from "utils/analytics";
import { BsChevronDown } from "react-icons/bs";
import { getStore } from "store/storeUtils";

const PayDateSection = ({
  payDate = "",
  setPayDate = () => {},
  errors = null,
  isUpdate = false,
  isDisabled = false,
}) => {
  const { t } = useTranslation("common");
  const [choice, setChoice] = useState("");
  const [endDate, setEndDate] = useState("");

  let formattedPayDate = "";
  if (payDate === "-1") {
    formattedPayDate = dayjs().endOf("month").format("MM/DD/YYYY");
  } else {
    formattedPayDate = payDate;
  }

  useEffect(() => {
    if (!isUpdate && payDate === "" && choice === "") {
      setChoice("");
    } else if (payDate === "-1") {
      setChoice("one");
    } else {
      setChoice("two");
    }
  }, [payDate]);

  useEffect(() => {
    if (choice != "") {
      if (choice == "one") {
        AmplitudeHelper.logEvent("salary_date_chosen", {
          source: isUpdate
            ? "single_employee_update_page"
            : "single_employee_creation_page",
          input: "end_of_month",
        });
        setPayDate("-1");
        setEndDate("");
      } else {
        setPayDate(endDate);
      }
    }
  }, [choice, endDate]);

  useEffect(() => {
    if (endDate !== "") {
      setChoice("two");
    }
  }, [endDate]);

  return (
    <Box>
      <InfoBox
        title="employeeCreation.single.info"
        description="employeeCreation.single.infoDesc"
      />
      <Radio.Group value={choice} onChange={setChoice} flex="1">
        <HStack space={2} alignItems="center" my="5px">
          <Radio value="one" isDisabled={isDisabled}>
            <Text variant="xs">{t("employeeCreation.single.endOfMonth")}</Text>
          </Radio>
        </HStack>
        <HStack space={2} alignItems="center" my="5px" mb="10px">
          <Radio value="two" isDisabled={isDisabled}>
            <Text variant="xs">{t("employeeCreation.single.selectDate")}</Text>
          </Radio>
        </HStack>
      </Radio.Group>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disabled={isDisabled}
          views={["year", "month", "day"]}
          value={isUpdate ? dayjs(formattedPayDate) : endDate}
          onChange={(x) => {
            AmplitudeHelper.logEvent("salary_date_chosen", {
              source: "single_employee_creation_page",
              input: formatDateToISOWithUTCOffset(new Date(x)),
            });
            setEndDate(new Date(x));
          }}
          slotProps={{
            textField: {
              size: "small",
              error: Object.prototype.hasOwnProperty.call(errors, "payDate"),
            },
          }}
          renderInput={(params) => (
            <CalendarInput
              isError={Object.prototype.hasOwnProperty.call(errors, "payDate")}
              amplitude="salary_date"
              isDisabled={choice != "two"}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
};

const SalarySection = ({
  salary = "",
  setSalary = () => {},
  isUpdate = false,
  disabled = false,
}) => {
  const [input, setInput] = useState("");

  useEffect(() => {
    if (isUpdate) {
      setInput(salary);
    }
  }, [isUpdate, salary]);

  return (
    <HStack space={3}>
      <Box
        h="35px"
        w="35px"
        borderWidth={1}
        borderColor={colors.disabled.smoke}
        flex={0.1}
        alignItems="center"
        justifyContent="center"
      >
        <Text variant="xs" color={colors.disabled.smoke}>
          Rp
        </Text>
      </Box>
      <Input
        variant="home"
        flex={0.9}
        isDisabled={disabled}
        // _disabled={{
        //   backgroundColor: colors.disabled.smoke,
        // }}- TODO: commented for now as smoke color background for disabled is not reflecting in Datepicker. Discussed this with Alver.
        value={numberWithDots(input)}
        onChange={(x) => {
          setInput(numberWithDots(x.target.value));
        }}
        onFocus={() => {
          AmplitudeHelper.logEvent("salary_amount_clicked", {
            source: "single_employee_creation_page",
          });
        }}
        onBlur={() => {
          if (setSalary) {
            AmplitudeHelper.logEvent("salary_amount_filled", {
              source: "single_employee_creation_page",
              input,
            });
            setSalary(removeNonNumeric(input));
          }
        }}
      />
    </HStack>
  );
};

const EmployeeForm = ({
  errors = null,
  setErrors = () => {},
  formData = {},
  banksList = [],
  setFormData = () => {},
  isUpdate = false,
  hasPendingUpdateRequest = false,
  isRowDisabled = false,
  employeeId = 0,
}) => {
  const { t } = useTranslation("common");
  const direction = ["column", "column", "row", "row", "row"];
  const flex = [null, null, null, 0.5, 0.5];
  const space = [5, 5, 5, 10, 10];

  const { modal, setModal } = getStore();

  const handleShowModal = (id, status) => {
    AmplitudeHelper.logEvent("employee_status_clicked", {
      source: "single_employee_update_page",
      employee_id: id,
    });
    setModal({
      ...modal,
      type: TEAM_MODALS.changeStatus,
      data: { employeeId: id, employeeStatus: status },
    });
  };

  return (
    <FormContainer
      title="employeeCreation.single.employeeData"
      description="employeeCreation.single.employeeDataDesc"
      icon={<AiOutlineSolution size={25} />}
    >
      <Stack direction={direction} space={space}>
        <VStack flex={flex} space={3} maxW={{ base: "100%", md: "50%" }}>
          <ValidatedInput
            label="employeeId"
            errors={errors}
            title="employeeCreation.single.employeeId"
            required
            disabled={hasPendingUpdateRequest}
            value={formData.employeeId ?? ""}
            setValue={(x) => setFormData({ ...formData, employeeId: x })}
            amplitude="employee_code"
          />
          <ValidatedInput
            label="employeeType"
            errors={errors}
            title="employeeCreation.single.employeeType"
            component={
              <FormMenu
                data={TYPE_OPTIONS}
                isDisabled={hasPendingUpdateRequest}
                value={
                  formData.employeeType?.data
                    ? t(`home.list.rows.${formData.employeeType?.data}`)
                    : ""
                }
                setValue={(x) => setFormData({ ...formData, employeeType: x })}
                isError={Object.prototype.hasOwnProperty.call(
                  errors,
                  "employeeType"
                )}
                amplitude="employee_type"
              />
            }
            required
          />
          <ValidatedInput
            label="bankName"
            errors={errors}
            title="employeeCreation.single.bankName"
            component={
              <FormMenu
                data={banksList}
                isDisabled={hasPendingUpdateRequest}
                value={formData.bankName?.bankName ?? ""}
                setValue={(x) => setFormData({ ...formData, bankName: x })}
                isError={Object.prototype.hasOwnProperty.call(
                  errors,
                  "bankName"
                )}
                amplitude="bank_name"
              />
            }
            required
          />
          <ValidatedInput
            label="beneficiaryName"
            errors={errors}
            disabled={hasPendingUpdateRequest}
            title="employeeCreation.single.beneficiaryName"
            value={formData.beneficiaryName ?? ""}
            setValue={(x) => setFormData({ ...formData, beneficiaryName: x })}
            required
            amplitude="beneficiary_name"
          />
          <ValidatedInput
            errors={errors}
            title="employeeCreation.single.salaryType"
            label="salaryType"
            disabled
            value="Bulanan"
          />
          <ValidatedInput
            errors={errors}
            title="employeeCreation.single.payDate"
            label="payDate"
            required
            component={
              <PayDateSection
                payDate={formData.payDate ?? ""}
                isUpdate={isUpdate}
                isDisabled={hasPendingUpdateRequest}
                setPayDate={(x) => setFormData({ ...formData, payDate: x })}
                errors={errors}
              />
            }
          />
        </VStack>
        <VStack flex={flex} space={3} minW="50%">
          <ValidatedInput
            label="dateJoined"
            errors={errors}
            title="employeeCreation.single.dateJoined"
            required
            component={
              <>
                <Hidden till="lg">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={["year", "month", "day"]}
                      value={
                        formData.dateJoined ? dayjs(formData.dateJoined) : ""
                      }
                      disabled={hasPendingUpdateRequest}
                      onChange={(x) => {
                        AmplitudeHelper.logEvent("date_joined_filled", {
                          source: "single_employee_creation_page",
                          input: formatDateToISOWithUTCOffset(new Date(x)),
                        });
                        setFormData({ ...formData, dateJoined: new Date(x) });
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          error: Object.prototype.hasOwnProperty.call(
                            errors,
                            "dateJoined"
                          ),
                        },
                      }}
                      renderInput={(params) => (
                        <TextField {...params} helperText={null} />
                      )}
                    />
                  </LocalizationProvider>
                </Hidden>
                <Hidden from="lg">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled={hasPendingUpdateRequest}
                      views={["year", "month", "day"]}
                      value={
                        formData.dateJoined ? dayjs(formData.dateJoined) : ""
                      }
                      disableOpenPicker={hasPendingUpdateRequest}
                      onChange={(x) => {
                        AmplitudeHelper.logEvent("date_joined_filled", {
                          source: "single_employee_creation_page",
                          input: formatDateToISOWithUTCOffset(new Date(x)),
                        });
                        setFormData({ ...formData, dateJoined: new Date(x) });
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          error: Object.prototype.hasOwnProperty.call(
                            errors,
                            "dateJoined"
                          ),
                        },
                      }}
                      renderInput={(params) => (
                        <TextField {...params} helperText={null} />
                      )}
                    />
                  </LocalizationProvider>
                </Hidden>
              </>
            }
          />
          <ValidatedInput
            label="group"
            errors={errors}
            disabled={hasPendingUpdateRequest}
            title="employeeCreation.single.group"
            value={formData.group ?? ""}
            setValue={(x) => setFormData({ ...formData, group: x })}
            amplitude="group_name"
          />
          <ValidatedInput
            label="bankNumber"
            errors={errors}
            title="employeeCreation.single.bankNumber"
            required
            disabled={hasPendingUpdateRequest}
            value={formData.bankNumber ?? ""}
            setValue={(x) => setFormData({ ...formData, bankNumber: x })}
            amplitude="bank_account_number"
          />
          {isUpdate && (
            <ValidatedInput
              label="status"
              errors={errors}
              title="employeeCreation.single.status"
              required
              disabled
              value={formData.status?.name ?? ""}
              setValue={(x) => setFormData({ ...formData, status: x })}
              amplitude="employee_status"
              component={
                <Pressable
                  onPress={() => {
                    handleShowModal(employeeId, formData.status?.name);
                  }}
                  px="10px"
                  style={{
                    borderWidth: 1,
                    borderColor: colors.disabled.smoke,
                    marginTop: -2,
                  }}
                  isDisabled={hasPendingUpdateRequest || isRowDisabled}
                >
                  <HStack w="100%" minH="36px" alignItems="center">
                    <Text
                      variant="xs"
                      width="95%"
                      textAlign="left"
                      color={
                        hasPendingUpdateRequest
                          ? colors.disabled.smoke
                          : colors.black
                      }
                    >
                      {t(`home.list.rows.${formData.status?.name}`)}
                    </Text>
                    <BsChevronDown size="15px" />
                  </HStack>
                </Pressable>
              }
            />
          )}
          {!isUpdate && (
            <ValidatedInput
              label="status"
              errors={errors}
              title="employeeCreation.single.status"
              required
              component={
                <FormMenu
                  data={STATUS_OPTIONS}
                  isUpdate={isUpdate}
                  value={
                    formData.status?.name
                      ? t(`home.list.rows.${formData.status?.name}`)
                      : ""
                  }
                  setValue={(x) => setFormData({ ...formData, status: x })}
                  isError={Object.prototype.hasOwnProperty.call(
                    errors,
                    "status"
                  )}
                  amplitude="employee_status"
                />
              }
            />
          )}
          <ValidatedInput
            errors={errors}
            title="employeeCreation.single.salary"
            label="salary"
            required
            component={
              <SalarySection
                isUpdate={isUpdate}
                disabled={hasPendingUpdateRequest}
                salary={formData.salary ?? ""}
                setSalary={(x) => setFormData({ ...formData, salary: x })}
              />
            }
          />
        </VStack>
      </Stack>
      {hasPendingUpdateRequest && (
        <Box mt="32px">
          <InfoBox
            warning
            shouldShowDescription
            title="employeeUpdation.single.warningTitle"
            description="employeeUpdation.single.WarningDescription"
          />
        </Box>
      )}
    </FormContainer>
  );
};

export default EmployeeForm;
