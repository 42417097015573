import { Box, Text, VStack } from "native-base";
import ToolTip from "atoms/ToolTip";
import TransactionDataSummaryBox from "atoms/TransactionDataSummaryBox";

import TransactionFilterSection from "atoms/TransactionFilterSection";
import { useTranslation } from "react-i18next";
import styles from "./styles";

const TransactionPageSummary = ({
  data = {},
  txnDates = {},
  setTxnDates = () => {},
  onResetPress = () => {},
  filterData = {},
  setFilterData = () => {},
}) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Box>
        <VStack style={styles.filterSectionContainer}>
          <ToolTip />
          <Text fontSize="xs" fontWeight="bold">
            {t("transactionPage.transactionDate")}
          </Text>
          <TransactionFilterSection
            txnDates={txnDates}
            setTxnDates={setTxnDates}
            onResetPress={onResetPress}
            filterData={filterData}
            setFilterData={setFilterData}
          />
        </VStack>
      </Box>
      <Box style={styles.dataSummaryContainer}>
        <VStack space={2}>
          <Text
            fontSize="md"
            fontWeight="bold"
            style={styles.transactionDataSummaryHeader}
          >
            {t("transactionPage.dataSummary")}
          </Text>
          <TransactionDataSummaryBox summaryData={data?.summary} />
        </VStack>
      </Box>
    </>
  );
};

export default TransactionPageSummary;
