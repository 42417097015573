import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Hidden,
  Text,
  Button,
  View,
  HStack,
  // TODO: dummy text added to move to next line eslint errors,
} from "native-base";
import { AiFillFilter } from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";
import TxnPageFilterContent from "atoms/TxnPageFilterContent";
import { getStore } from "store/storeUtils";
import { TXN_MODALS } from "global/constants";
import AmplitudeHelper from "utils/analytics";
import colors from "theme/colors";

const TxnPageFilterDropdown = (props) => {
  const { t } = useTranslation("common");
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { modal, setModal } = getStore();

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignSelf: "center",
      maxWidth: props.contentWidth || 400,
    },
    dropdown: {
      button: {
        width: "auto",
        height: "auto",
        border: `solid ${props.borderWidth || 1}px ${
          props.borderColor || "#d9d9d9"
        }`,
        borderRadius: 4,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 10,
        cursor: "pointer",
        backgroundColor: props.buttonColor || "white",
        zIndex: 0,
      },
    },
    collapsible: {
      width: props.contentWidth || 400,
      marginTop: "max(1.7%, 40px)",
      marginLeft: 1,
      boxShadow: "0.5px 2px 10px #d1d1d1",
      backgroundColor: "white",
      position: "absolute",
      zIndex: 1,
      container: {
        maxWidth: props.contentWidth || 400,
        backgroundColor: props.contentBackgroundColor || "white",
        overflow: "scroll",
        overflowX: "hidden",
        transition: "all 0.2s ease-out",
        height: isCollapsed ? 0 : props.maxContentHeight || 400,
      },
    },
    background: {
      height: "100vh",
      width: "100vw",
      backgroundColor: "rgba(52, 52, 52, 0.0)",
      position: "fixed",
      left: 0,
      top: 0,
      cursor: "default",
      zIndex: 0,
    },
    redDot: {
      height: 4,
      width: 4,
      borderRadius: 2,
      marginTop: 4,
      marginLeft: 2,
      backgroundColor: colors.error.rose,
    },
  };
  const {
    filterData = {},
    setFilterData = () => {},
    onResetPress = () => {},
  } = props;
  const appliedFilters = Object.keys(filterData);

  const filterClicked = (isModal = false) => {
    AmplitudeHelper.logEvent("filter_clicked", { source: "transaction_page" });
    if (!isModal) {
      setIsCollapsed(!isCollapsed);
    } else {
      setModal({ ...modal, type: TXN_MODALS.filter });
    }
  };

  return (
    <div style={styles.container}>
      <Hidden till="md">
        <Button
          variant="outline"
          minHeight="42px"
          leftIcon={<AiFillFilter size="17px" />}
          rightIcon={<BsChevronDown size="17px" />}
          onPress={() => filterClicked(false)}
        >
          <HStack>
            <Text variant="xs">{t("home.filter.filter")}</Text>
            {appliedFilters.length > 0 && <View style={styles.redDot} />}
          </HStack>
        </Button>
      </Hidden>
      <Hidden from="md">
        <Button
          variant="outline"
          leftIcon={<AiFillFilter size="17px" />}
          rightIcon={<BsChevronDown size="17px" />}
          onPress={() => filterClicked(true)}
        >
          {appliedFilters.length > 0 && <View style={styles.redDot} />}
        </Button>
      </Hidden>

      <Hidden till="md">
        {!isCollapsed && (
          <div
            style={styles.background}
            onClick={() => {
              AmplitudeHelper.logEvent("filter_canceled", {
                source: "team_page",
              });
              setIsCollapsed(true);
            }}
          />
        )}
        <div style={styles.collapsible}>
          <div style={styles.collapsible.container}>
            <TxnPageFilterContent
              filterData={filterData}
              setFilterData={setFilterData}
              setIsCollapsed={setIsCollapsed}
              onResetPress={onResetPress}
            />
          </div>
        </div>
      </Hidden>
    </div>
  );
};

export default TxnPageFilterDropdown;
