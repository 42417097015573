import { HStack } from "native-base";
import Pagination from "@mui/material/Pagination";

const PaginationMui = ({ currentPage, setCurrentPage, totalPageNumber }) => {
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <HStack
      space={1}
      style={{ marginLeft: 20, marginBottom: 16, marginTop: 16 }}
    >
      <Pagination
        count={totalPageNumber}
        page={currentPage}
        onChange={handleChange}
      />
    </HStack>
  );
};

export default PaginationMui;
