import colors from 'theme/colors'

const styles = {
	sideBar: {
		backgroundColor: colors.secondary.orchid,
		alignItems: 'center',
		paddingTop: '15px',
		paddingBottom: '15px',
		justifyContent: 'space-between',
	},
}

export default styles
