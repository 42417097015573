import {
  HStack,
  Text,
  Center,
  FlatList,
  Spinner,
  Box,
  // TODO:  added to move to next line and remove eslint error
} from "native-base";
import colors from "theme/colors";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IN_PROGRESS, IN_STATUS_CHECK, SINGLE_UPDATE } from "global/paths";
import { VERIFY_STATUS_OPTIONS } from "global/constants";

const Cell = ({
  children = null,
  header = false,
  style = {},
  color = "",
  isPressable = false,
  linkTo = "",
  data = "",
  isRowDisabled = false,
  isStatusCheck = false,
}) => {
  const tableCellWidth = ["120px", "110px", "110px", "110px", "185px"];

  const parentScreenName = isStatusCheck
    ? [{ name: "sideBar.inStatusCheck", link: IN_STATUS_CHECK }]
    : [{ name: "sideBar.inProgress", link: IN_PROGRESS }];

  const employeeData = {
    employeeId: data,
    isEmployeeRequest: true,
    isRowDisabled,
    parentScreen: parentScreenName,
  };

  return (
    <Center bg={header ? colors.neutral.mist : colors.neutral.cotton} py="10px">
      {isPressable ? (
        <Box minW={tableCellWidth} alignItems="center">
          <Link
            to={linkTo}
            state={employeeData}
            style={{ color: colors.secondary.orchidBlue }}
          >
            <Text
              variant={header ? "xs-bold" : "xs"}
              minW={tableCellWidth}
              color={colors.secondary.orchidBlue}
              style={[style || {}, { textAlign: "center" }]}
            >
              {children}
            </Text>
          </Link>
        </Box>
      ) : (
        <Text
          variant={header ? "xs-bold" : "xs"}
          minW={tableCellWidth}
          style={[style || {}, { textAlign: "center" }]}
          color={color}
        >
          {children}
        </Text>
      )}
    </Center>
  );
};

const ConditionalHeaderElements = (isStatusCheck = false) => {
  const { t } = useTranslation("common");
  const border = { borderRightWidth: 2, borderColor: "#c1c1c1" };
  if (isStatusCheck) {
    return (
      <>
        <Cell header style={border}>
          {t("inProgress.table.header.employeeStatus")}
        </Cell>
        <Cell header>{t("inProgress.table.header.reason")}</Cell>
      </>
    );
  }
  return <Cell header>{t("inProgress.table.header.actionType")}</Cell>;
};

const Header = (isStatusCheck = false) => {
  const { t } = useTranslation("common");

  const border = { borderRightWidth: 2, borderColor: "#c1c1c1" };
  return (
    <HStack>
      <Cell header style={{ ...border, minWidth: "75px" }} no>
        {t("inProgress.table.header.no")}
      </Cell>
      <Cell header style={border}>
        {t("inProgress.table.header.employeeId")}
      </Cell>
      <Cell header style={border}>
        {t("inProgress.table.header.employeeName")}
      </Cell>
      <Cell header style={border}>
        {t("inProgress.table.header.uploadDate")}
      </Cell>
      <Cell header style={border}>
        {t("inProgress.table.header.fileName")}
      </Cell>
      <Cell header style={border}>
        {t("inProgress.table.header.status")}
      </Cell>
      <Cell header style={border}>
        {t("inProgress.table.header.note")}
      </Cell>
      {ConditionalHeaderElements(isStatusCheck)}
    </HStack>
  );
};

const ConditionalRowElement = (isStatusCheck = false, row = {}) => {
  const { t } = useTranslation("common");
  if (isStatusCheck) {
    return (
      <>
        <Cell>
          {row?.employeeStatus
            ? t(`home.list.rows.${row?.employeeStatus}`)
            : ""}
        </Cell>
      </>
    );
  }
  return <Cell>{row?.requestType}</Cell>;
};

const Row = (item) => {
  const { t } = useTranslation("common");

  const { row: { item: row = {} } = {}, isStatusCheck = false } = item;
  return (
    <HStack zIndex={2}>
      <Cell style={{ minWidth: "75px" }}>{row?.id}</Cell>
      <Cell
        isPressable
        linkTo={SINGLE_UPDATE}
        isStatusCheck={isStatusCheck}
        data={row?.unalteredEmpId}
      >
        {row?.employeeId}
      </Cell>
      <Cell>{row?.employeeName}</Cell>
      <Cell>{row?.createdAt}</Cell>
      <Cell>{row?.fileName}</Cell>
      <Cell
        isRowDisabled={row?.status !== VERIFY_STATUS_OPTIONS[0].name}
        color={row?.statusColor}
      >
        {row?.status}
      </Cell>
      <Cell>{row?.statusReason}</Cell>
      {ConditionalRowElement(isStatusCheck, row)}
    </HStack>
  );
};

const ProgressTable = ({
  data = {},
  isLoading = false,
  isStatusCheck = false,
}) => {
  const vHeight = window.innerHeight;
  const mobileHeight = vHeight < 700 ? vHeight * 0.5 : vHeight * 0.55;
  const desktopHeight = vHeight * 0.58;
  const tableContainerHeight = [
    mobileHeight,
    mobileHeight,
    mobileHeight,
    desktopHeight,
    desktopHeight,
  ];

  return (
    <>
      {isLoading ? (
        <Spinner size="sm" />
      ) : (
        <FlatList
          data={data}
          ListHeaderComponent={Header(isStatusCheck)}
          renderItem={(item) => (
            <Row row={item} isStatusCheck={isStatusCheck} />
          )}
          style={{
            marginHorizontal: "15px",
            overflowX: "scroll",
            paddingBottom: "35px",
            zIndex: 2,
            borderBottomWidth: 1,
            borderColor: colors.neutral.mist,
          }}
          maxHeight={tableContainerHeight}
        />
      )}
    </>
  );
};

export default ProgressTable;
