// Team
export const TEAM = "/";
export const SINGLE_CREATE = "/single";
export const SINGLE_UPDATE = "/single_update";
export const IN_PROGRESS = "/progress";
export const IN_STATUS_CHECK = "/check_status";
export const BULK_CREATE = "/bulk_create";
export const BULK_UPDATE = "/bulk_update";
export const BULK_UPDATE_STATUS = "/bulk_update_status";

// Transactions
export const TRANSACTIONS = "/transactions";
