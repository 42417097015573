import {
  Alert,
  Box,
  HStack,
  Hidden,
  Text,
  VStack,
  // TODO: dummy text added to move to next line eslint errors,
} from "native-base";
import { useTranslation } from "react-i18next";
import colors from "theme/colors";

const styles = {
  box: {
    backgroundColor: colors.tooltip.blueBody,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: colors.tooltip.blueBorder,
    padding: 16,
    marginBottom: 16,
  },
  infoIconContainer: { marginRight: 16 },
};

const ToolTip = () => {
  const { t } = useTranslation("common");
  return (
    <Alert status="info" style={styles.box}>
      <VStack w="100%" justifyContent="flex-start">
        <HStack alignItems="center">
          <Alert.Icon size="16px" style={styles.infoIconContainer} />
          <VStack>
            <Text fontSize="xs" fontWeight="medium">
              {t("transactionPage.notes")}
            </Text>
            <Hidden till="md">
              <Box pr="30px" mr="10px" flexWrap="wrap">
                <Text fontSize="xs">
                  {t(
                    "transactionPage.dataListedIsBasedOnSalaryWithdrawlDataInPayrollCycle"
                  )}
                </Text>
              </Box>
            </Hidden>
            <Hidden from="md">
              <Box pr="30px" mr="10px" borderRadius={12} flexWrap="wrap">
                <Text fontSize="xs" noOfLines={4} maxW="40%">
                  {t(
                    "transactionPage.dataListedIsBasedOnSalaryWithdrawlDataInPayrollCycle"
                  )}
                </Text>
              </Box>
            </Hidden>
          </VStack>
        </HStack>
      </VStack>
    </Alert>
  );
};

export default ToolTip;
