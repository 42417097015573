import {
HStack, Text, Tooltip, Button,
} from 'native-base'
// import styles from './styles'
import colors from 'theme/colors'
import SummaryBox from 'atoms/SummaryBox'
import { AiFillInfoCircle } from 'react-icons/ai'
import AmplitudeHelper from 'utils/analytics'

const InfoToolTip = ({
children, label, style, amplitude,
}) => (
		<HStack alignItems="center" space={1} style={style}>
			{children}
			<Tooltip label={label} hasArrow>
				<Button
  onHoverIn={() => AmplitudeHelper.logEvent('information_clicked', {source: 'team_page', column: amplitude || ''})}
  variant="ghost" 
  p="0"
  h="10px"
  _hover={{backgroundColor: colors.neutral.cotton}}
				>
<AiFillInfoCircle color={colors.neutral.charcoal} size="12px" />
    </Button>
			</Tooltip>
		</HStack>
	)

export default InfoToolTip
