import {
	Box, 
	Flex,
	HStack, 
	VStack, 
	Text
} from 'native-base'
import colors from 'theme/colors'
import { useTranslation } from 'react-i18next'

const DescriptionBox = ({title, description, icon}) => {
  const {t} = useTranslation('common')
  return (
    <Flex minH={75} bg={colors.secondary.softOrchid} p={15} borderRadius={12} mb={30} w="100%">
      <HStack alignItems="center" space={3} style={{ flexWrap: 'wrap' }}>
        <Box h={45} w={45} borderRadius={50} bg={colors.neutral.cotton} alignItems="center" justifyContent="center" >
          {icon}
        </Box>
        <VStack style={{ flexWrap: 'wrap', flex: 1 }}>
          <Text variant="sm-bold">{t(title)}</Text>
          <Text variant="xs" noOfLines={10} >
            {t(description)}
          </Text>
        </VStack>
      </HStack>
    </Flex>
  )
}

export default DescriptionBox
