import { Box, HStack, VStack, Text, Button } from "native-base";
import colors from "theme/colors";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FormSubmit = ({
  handleSubmit,
  handleCancel,
  isBulk = false,
  isSubmitDisabled = false,
}) => {
  const { t } = useTranslation("common");
  const container = ["100%", "100%", "100%", "85%", "85%"];
  return (
    <Box
      h="55px"
      bg={colors.neutral.cotton}
      position="fixed"
      bottom={0}
      width={container}
      ml="-17px"
      p="20px"
      alignItems="flex-end"
      justifyContent="center"
    >
      <HStack space={2}>
        {!isBulk ? (
          <Button
            variant="outline"
            borderColor={colors.neutral.darkMist}
            px="10px"
            onPress={handleCancel}
          >
            <Text variant="sm" color={colors.neutral.darkMist}>
              {t("employeeCreation.single.cancel")}
            </Text>
          </Button>
        ) : (
          <Link to="/" style={{ textDecorationLine: "none" }}>
            <Button
              variant="outline"
              borderColor={colors.neutral.darkMist}
              px="10px"
            >
              <Text variant="sm" color={colors.neutral.darkMist}>
                {t("employeeCreation.bulk.cancel")}
              </Text>
            </Button>
          </Link>
        )}
        <Button
          variant={isSubmitDisabled ? "outline" : "solid"}
          px="30px"
          disabled={isSubmitDisabled}
          bg={isSubmitDisabled ? colors.neutral.mist : colors.secondary.orchid}
          onPress={handleSubmit}
        >
          <Text
            variant="sm"
            color={
              isSubmitDisabled ? colors.neutral.darkMist : colors.neutral.cotton
            }
          >
            {isBulk
              ? t("employeeCreation.bulk.submit")
              : t("employeeCreation.single.submit")}
          </Text>
        </Button>
      </HStack>
    </Box>
  );
};

export default FormSubmit;
