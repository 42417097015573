import {
  Text,
  Button,
  HStack,
  Menu,
  ScrollView,
  // TODO:  Dummy text added here
} from "native-base";
import { BsChevronDown } from "react-icons/bs";
import colors from "theme/colors";
import AmplitudeHelper from "utils/analytics";

const FormMenu = ({
  value,
  data,
  setValue,
  isError,
  amplitude,
  isDisabled = false,
  isUpdate = false,
}) => {
  return (
    <Menu
      trigger={(triggerProps) => {
        return (
          <HStack
            alignItems="center"
            borderWidth={isError ? "2" : "1"}
            borderRadius={4}
            // backgroundColor={
            //   isDisabled ? colors.disabled.smoke : colors.neutral.cotton
            // } - TODO: commented for now as smoke color background for disabled is not reflecting in Datepicker. Discussed this with Alver.
            opacity={isDisabled ? 0.5 : 1}
            borderColor={isError ? "error.600" : colors.disabled.smoke}
            justifyContent="space-between"
            pr="10px"
            h="35px"
            width="100%"
          >
            <Button
              variant="ghost"
              w="95%"
              justifyContent="space-between"
              h="30px"
              disabled={isDisabled}
              onPress={() => {
                AmplitudeHelper.logEvent(`${amplitude}_clicked`, {
                  source: isUpdate
                    ? "detailed_employee_page"
                    : "single_employee_creation_page",
                });
              }}
              {...triggerProps}
            >
              <Text
                variant="xs"
                color={value ? "black" : colors.neutral.darkMist}
              >
                {value || ""}
              </Text>
            </Button>
            <BsChevronDown size="17px" />
          </HStack>
        );
      }}
      shouldFlip
      style={{
        width: "200px",
        maxHeight: "300px",
        backgroundColor: isDisabled
          ? colors.disabled.smoke
          : colors.neutral.cotton,
      }}
    >
      <ScrollView>
        {data?.map((item) => (
          <Menu.Item
            onPress={() => {
              if (setValue) {
                AmplitudeHelper.logEvent(
                  `${amplitude}${isUpdate ? "_changed" : "_filled"}`,
                  {
                    source: isUpdate
                      ? "detailed_employee_page"
                      : "single_employee_creation_page",
                    input: item,
                  }
                );
                setValue(item);
              }
            }}
          >
            {item?.name || item?.bankName}
          </Menu.Item>
        ))}
      </ScrollView>
    </Menu>
  );
};

export default FormMenu;
