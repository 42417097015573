import {
  VStack,
  HStack,
  Text,
  Box,
  Flex,
  // TODO: Dummy text added to move to next line
} from "native-base";
import { AiFillInfoCircle } from "react-icons/ai";
import colors from "theme/colors";
import { useTranslation } from "react-i18next";

const InfoBox = ({
  title,
  description,
  warning,
  shouldShowDescription = false,
}) => {
  const { t } = useTranslation("common");
  return (
    <Flex
      bg={warning ? colors.warning.gold : colors.secondary.daybreakLight}
      borderWidth={1}
      borderColor={
        warning ? colors.warning.sunflower : colors.secondary.daybreak
      }
      p="10px"
      borderRadius={12}
      mb={warning ? "20px" : "10px"}
      w="100%"
      maxWidth="1400px"
    >
      <HStack alignItems="center" space={3} style={{ flexWrap: "wrap" }}>
        <Box
          variant="ghost"
          alignSelf="flex-start"
          mt={warning ? "0px" : "5px"}
        >
          <AiFillInfoCircle
            size="17px"
            color={warning ? colors.warning.sunflower : colors.secondary.orchid}
          />
        </Box>
        <VStack style={{ flexWrap: "wrap", flex: 1 }}>
          <Text variant="xs-bold">{t(title)}</Text>
          {(!warning || shouldShowDescription) && (
            <Text variant="xs" noOfLines={10}>
              {t(description)}
            </Text>
          )}
        </VStack>
      </HStack>
    </Flex>
  );
};

export default InfoBox;
