import {
  Box,
  HStack,
  Image,
  Text,
  // TODO: Dummy text added to move to next line
} from "native-base";
import { AiOutlineLineChart } from "react-icons/ai";
// import { BiMoney } from "react-icons/bi";
import { BsPeople } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { WithdrawalImage } from "assets";
import TextCurrency from "atoms/TextCurrency";
import colors from "theme/colors";
import { styles } from "./styles";

const TransactionDataSummaryBox = ({ summaryData = {} }) => {
  const { t } = useTranslation("common");
  return (
    <HStack space={3} flexWrap="wrap">
      <Box my={1} style={styles.boxContainer}>
        <HStack style={{ alignItems: "center" }}>
          <AiOutlineLineChart size="16px" style={{ marginRight: 4 }} />
          <Text>{t("transactionPage.totalSalaryWithdrawals")}</Text>
        </HStack>
        <Text fontSize="md" fontWeight="medium" style={{ color: "#3863F0" }}>
          {summaryData?.transactionCount || 0}
        </Text>
      </Box>
      <Box my={1} style={styles.boxContainer}>
        <HStack style={{ alignItems: "center" }}>
          <Image src={WithdrawalImage} size="16px" style={{ marginRight: 4 }} />
          {/* <BiMoney size="16px" style={{ marginRight: 4 }} /> */}
          <Text>{t("transactionPage.numberOfTransactions")}</Text>
        </HStack>
        <TextCurrency
          showCurrencySymbol
          amountCurrency={summaryData?.totalTransactionAmount ?? 0}
          fontSize="md"
          fontWeight="medium"
          style={{ color: colors.variants.clover }}
        />
      </Box>
      <Box my={1} style={styles.boxContainer}>
        <HStack style={{ alignItems: "center" }}>
          <BsPeople size="16px" style={{ marginRight: 4 }} />
          <Text>{t("transactionPage.totalEmployeesTransacting")}</Text>
        </HStack>
        <Text fontSize="md" fontWeight="medium" style={{ color: "#F56B57" }}>
          {summaryData?.employeeCount || 0}
        </Text>
      </Box>
    </HStack>
  );
};

export default TransactionDataSummaryBox;
