import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
Text, Image, Box, Drawer, VStack, Button, Hidden, FlatList, HStack,
} from 'native-base'
import { Logo } from 'assets'
import routeData from 'global/routes'
import {AiOutlineMenu} from 'react-icons/ai'
import MobileNavBar from 'molecules/MobileNavBar'
import styles from './styles'

const TopBar = ({name}) => {
	const {t} = useTranslation('common')
	const [drawerOpen, setDrawerOpen] = useState(false)

	return (
		<>
			<Box style={styles.bar}>
				<Image 
  height={styles.breakpoints.logoSize} 
  width={styles.breakpoints.logoSize} 
  resizeMode="contain" 
  source={Logo} 
  alt="logo" 
				/>
				<HStack alignItems="center">
					<Text variant="xs-bold" style={styles.text}>
						{`${t('home.hello')}${name ? `, ${name}` : ''}`} 
{' '}
{'  '}
					</Text>
					<Hidden from="lg">
						<Button variant="outline" w="35px" onPress={() => setDrawerOpen(true)}>
							<AiOutlineMenu size="15px" />
						</Button>
					</Hidden>
				</HStack>
			</Box>
			<MobileNavBar
  drawerOpen={drawerOpen}	
  setDrawerOpen={setDrawerOpen}
			/>
		</>
	)
}

export default TopBar
