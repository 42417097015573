import {VStack, Text, ScrollView} from 'native-base'
import styles from './styles'

const ContentContainer = (props) => (
		<VStack style={styles.content} {...props}>
			<Text variant="md-bold" style={styles.title} pt={styles.title.paddingTop}>{props.title ? props.title : ''}</Text>
			{props.children}
		</VStack>
	)

export default ContentContainer
