import Breadcrumbs from "atoms/Breadcrumbs";
import ContentContainer from "atoms/ContentContainer";
import FormSubmit from "atoms/FormSubmit";
import { useLocation, useNavigate } from "react-router-dom";
import { VStack, ScrollView } from "native-base";
import PersonalUserDetails from "molecules/PersonalUserDetails";
import TeamModal from "molecules/TeamModal";
import EmployeeForm from "molecules/EmployeeForm";
import CreateModal from "molecules/CreateModal";
import { useEffect } from "react";
import AmplitudeHelper from "utils/analytics";
import useUpdateConnect from "./connect";

const SingleUpdate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    employeeId = 0,
    isEmployeeRequest = false,
    isRowDisabled = false,
    parentScreen = [],
  } = location.state || {};
  const {
    formData = {},
    banksList = [],
    setFormData = () => {},
    getEmployeeId = () => {},
    getEmployeeRequestById = () => {},
    handleSubmit = () => {},
    handleCancel = () => {},
    handleSendData = () => {},
    errors = {},
    setErrors = () => {},
  } = useUpdateConnect({ isEmployeeRequest });

  useEffect(() => {
    AmplitudeHelper.logEvent("single_employee_update_page");
    if (!employeeId || employeeId === 0) {
      navigate("/");
    }
    if (isEmployeeRequest) {
      getEmployeeRequestById(employeeId);
    } else {
      getEmployeeId(employeeId);
    }
  }, []);
  const { hasPendingUpdateRequest = false } = formData;
  return (
    <>
      <ContentContainer flex={[1, 1, 1, 0.85, 0.85]}>
        <Breadcrumbs
          currentPage="sideBar.changeEmployeeData"
          parentScreen={parentScreen}
        />
        <ScrollView pb="50px">
          <VStack space={5}>
            <PersonalUserDetails
              errors={errors}
              setErrors={setErrors}
              formData={formData}
              setFormData={setFormData}
              hasPendingUpdateRequest={hasPendingUpdateRequest}
            />
            <EmployeeForm
              errors={errors}
              setErrors={setErrors}
              formData={formData}
              banksList={banksList}
              setFormData={setFormData}
              isUpdate
              hasPendingUpdateRequest={hasPendingUpdateRequest}
              isRowDisabled={isRowDisabled}
              employeeId={employeeId}
            />
          </VStack>
        </ScrollView>
        <FormSubmit handleSubmit={handleSubmit} handleCancel={handleCancel} />
        <TeamModal />
      </ContentContainer>
      <CreateModal handleSendData={handleSendData} />
    </>
  );
};

export default SingleUpdate;
