import {
	Center, Box, HStack, Text, Button, Hidden,
} from 'native-base'
import colors from 'theme/colors'
import { IoMdClose } from 'react-icons/io'

const Modal = ({
	height, width, children, style, closeable, reset, title,
}) => {
	const modalHeight = '300px'
	const modalWidth = '400px'

	return (
		<>
			<Hidden till="md">
				<Center h="100vh" w="100vw" bg="rgba(52, 52, 52, 0.3)" position="fixed" left="0" top="0">
					<Box h={height || modalHeight} w={width || modalWidth} bg={colors.neutral.cotton} borderRadius={10} style={style}>
						<HStack alignItems="center" justifyContent="space-between" py="10px" px="10px">
							<Text variant="sm-bold">{title}</Text>
							{closeable
								&& (
									<Button variant="unstyled" onPress={reset}>
										<IoMdClose size="17px" />
									</Button>
								)}
						</HStack>
						{children}
					</Box>
				</Center>
			</Hidden>
			<Hidden from="md">
				<Box h="91vh" w="100vw" bg={colors.neutral.cotton} position="absolute" bottom="0" left="0">
					<HStack alignItems="center" justifyContent="space-between" py="10px" px="10px">
						<Text variant="sm-bold">{title}</Text>
						{closeable
							&& (
								<Button variant="unstyled" onPress={reset}>
									<IoMdClose size="17px" />
								</Button>
							)}
					</HStack>
					{children}
				</Box>
			</Hidden>
		</>
	)
}

export default Modal
