import i18next from "i18next";

export const validate = {
  name: (val) => {
    if (val == "") return "basicValidation.missingFields";
    const numAndChar = /[!@#$%^&*\.\\\/\(\)\-\+\=\_\:\;\'\"\,0-9\`\[\]\{\}]/;
    const hasNum = numAndChar.test(val);
    if (hasNum) {
      return "basicValidation.lettersOnly";
    }
    return "";
  },
  phone: (val, countryCode) => {
    // if(val.charAt(0) === '0'){
    //     return "Don't start with 0"
    if (val == "") return "basicValidation.missingFields";
    // console.log('FORMAT PASS', /^(^\+62|62|^08)(\d{3,4}-?){2}\d{2,4}$/.test(val))

    if (countryCode === "+60") {
      if (/[a-zA-Z!@#$%^&*\.\\\/\(\)\-\+\=\_\:\;\'\"\,\`\[\]\{\}]/.test(val)) {
        return "basicValidation.numbersOnly";
      }
      if (val.length > 10) {
        return "basicValidation.tooLong";
      }
      if (val.length < 9 && val.length > 0) {
        return "basicValidation.tooShort";
      }
      if (val.charAt(0) === ".") {
        return "basicValidation.numbersOnly";
      }
      return "";
    }
    if (countryCode === "+65") {
      if (/[a-zA-Z!@#$%^&*\.\\\/\(\)\-\+\=\_\:\;\'\"\,\`\[\]\{\}]/.test(val)) {
        return "basicValidation.numbersOnly";
      }
      if (val.length > 10) {
        return "basicValidation.tooLong";
      }
      if (val.length < 7 && val.length > 0) {
        return "basicValidation.tooShort";
      }
      if (val.charAt(0) === ".") {
        return "basicValidation.numbersOnly";
      }
      return "";
    }
    if (countryCode === "+91") {
      if (/[a-zA-Z!@#$%^&*\.\\\/\(\)\-\+\=\_\:\;\'\"\,\`\[\]\{\}]/.test(val)) {
        return "basicValidation.numbersOnly";
      }
      if (val.length > 10) {
        return "basicValidation.tooLong";
      }
      if (val.length < 10 && val.length > 0) {
        return "basicValidation.tooShort";
      }
      if (val.charAt(0) === ".") {
        return "basicValidation.numbersOnly";
      }
      return "";
    }
    if (/[a-zA-Z!@#$%^&*\.\\\/\(\)\-\+\=\_\:\;\'\"\,\`\[\]\{\}]/.test(val)) {
      return "basicValidation.numbersOnly";
    }
    if (val.length > 13) {
      return "basicValidation.tooLong";
    }
    if (val.length < 8 && val.length > 0) {
      return "basicValidation.tooShort";
    }
    if (val.charAt(0) === ".") {
      return "basicValidation.numbersOnly";
    }
    if (!/^(^\+62|62|^08)(\d{3,4}-?){2}\d{2,4}$/.test(val)) {
      return "basicValidation.properFormat";
    }
    return "";
  },
  otp: (val) => {
    if (/[a-zA-Z!@#$%^&*\.\\\/\(\)\-\+\=\_\:\;\'\"\,\`\[\]\{\}]/.test(val)) {
      return "basicValidation.numbersOnly";
    }
    if (val > 0 && val.length < 4) {
      return "basicValidation.tooShort";
    }
    return "";
  },
  // date: (val) => {
  // 		// If last day of the month, change it to 31
  // 		const day = moment(val).format('DD')
  // 		if (day === moment(val).endOf('month').format('DD')) {
  // 				return 31
  // 		}
  // 				return parseInt(day, 10)
  // },
  salary: (val, currency = "Rp") => {
    if (val == "") return "basicValidation.missingFields";
    if (val?.includes("-")) {
      return "basicValidation.noMinus";
    }
    if (
      currency == "Rp" &&
      /[a-zA-Z!@#$%^&*\\\\/\(\)\-\+\=\_\:\;\'\"\,\`\[\]\{\}]/.test(val)
    ) {
      return "basicValidation.numbersOnly";
    }
    if (val?.length > 13) {
      return "basicValidation.tooLong";
    }
    if (currency == "Rp" && val?.charAt(0) === "0") {
      return "basicValidation.noZeros";
    }
    if (currency == "Rp" && val?.charAt(0) === ".") {
      return "basicValidation.numbersOnly";
    }
    return "";
  },
  ktp: (val) => {
    if (/[a-zA-Z!@#$%^&*\.\\\/\(\)\-\+\=\_\:\;\'\"\,\`\[\]\{\}]/.test(val)) {
      return "basicValidation.numbersOnly";
    }
    if (val.length > 16) {
      return "basicValidation.tooLong";
    }
    if (val.length < 16 && val.length > 0) {
      return "basicValidation.tooShort";
    }
    if (val.charAt(0) === ".") {
      return "basicValidation.numbersOnly";
    }
    return "";
  },
  bankNumber: (val) => {
    if (/[a-zA-Z!@#$%^&*\.\\\/\(\)\-\+\=\_\:\;\'\"\,\`\[\]\{\}]/.test(val)) {
      return "basicValidation.numbersOnly";
    }

    return "";
  },
  beneficiaryName: (val) => {
    if (val == "") return "basicValidation.missingFields";
    const numAndChar = /[!@#$%^&*\.\\\/\(\)\-\+\=\_\:\;\'\"\,0-9\`\[\]\{\}]/;
    const hasNum = numAndChar.test(val);
    if (hasNum) {
      // console.log('HAS NUM!')
      return "basicValidation.lettersOnly";
    }
    return "";
  },
  birthDate: (val) => {
    let currentDate = new Date();
    if (val?.getTime() > currentDate?.getTime()) {
      return "basicValidation.futureDate";
    }

    return "";
  },
  dateJoined: (val) => {
    let currentDate = new Date();
    if (val?.getTime() > currentDate?.getTime()) {
      return "basicValidation.futureDate";
    }

    return "";
  },
};
