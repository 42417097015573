import dayjs from "dayjs";

export const removeNonNumeric = (num) => num?.toString().replace(/[^0-9]/g, "");

export const numberWithDots = (num) => {
  const addCommas = (num) => {
    return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };
  return `${num < 0 ? "-" : ""}${addCommas(removeNonNumeric(num))}`;
};

export const numberWithDotsAndEmptyCaseZero = (num) => {
  const addCommas = (num) => {
    const stringWithCommas = num
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    if (stringWithCommas) return stringWithCommas;
    return "0";
  };
  return `${num <= 0 ? "Rp0" : "Rp"}${addCommas(removeNonNumeric(num))}`;
};

export const currencyFormatting = (num) => {
  return num <= 0 || !num ? "Rp0" : `Rp${num.toLocaleString()}`;
};

export const getName = (item, defaults) => {
  let found = defaults.findIndex((x) => x.data == item);
  if (found != -1) {
    return defaults[found];
  }
};

export const formatDateToISOWithUTCOffset = (date = "") => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = "00";
  const minutes = "00";
  const seconds = "00";
  const timeZoneOffset = "+00:00";

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timeZoneOffset}`;
};

export const getFormattedStartDate = (date = "") => {
  const selectedDate = dayjs(date);

  const fromDate = selectedDate
    .startOf("day")
    .subtract(selectedDate.utcOffset(), "minutes");

  const formattedFromDate = fromDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  return formattedFromDate.toString();
};

export const getFormattedEndDate = (date = "") => {
  const selectedDate = dayjs(date);

  const toDateTime = selectedDate
    .endOf("day")
    .subtract(selectedDate.utcOffset(), "minutes");

  const formattedToDateTime = toDateTime.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  return formattedToDateTime.toString();
};

export const getDifferenceBetweenObjects = ({ object1 = {}, object2 = {} }) => {
  let differences = {};
  Object.keys(object2).forEach((key) => {
    if (
      Object.prototype.hasOwnProperty.call(object2, key) &&
      object1[key] !== object2[key] &&
      !!object2[key]
    ) {
      differences[key] = object2[key];
    }
  });
  return differences;
};

/**
Formats bytes into human-readable string.
@param {number} bytes - Number of bytes.
@param {number} [decimals=2] - Number of decimals to keep.
@param {number} [base=1024] - Base for size calculations (1024 for binary, 1000 for metric).
@returns {string} Formatted string.
*/
export const formatBytes = (bytes, decimals = 2, base = 1024) => {
  if (typeof bytes !== "number") {
    // console.error("Expected 'bytes' to be a number.");
    return "";
  }
  if (bytes === 0) return "0 Bytes";

  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const factor = Math.log(base);
  const index = Math.floor(Math.log(bytes) / factor);

  if (index < 0 || index >= sizes.length) {
    // console.error("The 'bytes' value is outside of the expected range.");
    return "";
  }

  return `${parseFloat((bytes / base ** index).toFixed(decimals))} ${
    sizes[index]
  }`;
};

/**
Truncates a string if its length exceeds 15 characters
@param {string} str - string to truncate.
@returns {string} Formatted string.
*/
export const stringLimit = (str = "") => {
  if (typeof str !== "string") {
    // console.error("Expected input to be a string.");
    return "";
  }
  return str.length > 15 ? `${str.slice(0, 15)}...` : str;
};

/**
De-structures and gets the name of the employee from the employee data
@param {object} employeeData - data of the employee.
@returns {string} Formatted string.
*/
export const getEmployeeName = (employeeData) => {
  if (typeof employeeData !== "object" || Array.isArray(employeeData)) {
    // console.error("Expected input to be an object.");
    return "";
  }
  const { employee = {} } = employeeData || {};
  return `${employee?.user?.firstName || ""}${employee?.user?.lastName || ""}`
    ? stringLimit(
        `${employee?.user?.firstName || ""} ${employee?.user?.lastName || ""}`
      )
    : stringLimit(
        `${employeeData?.firstName ?? ""} ${employeeData?.lastName ?? ""}`
      );
};
