import { useState, forwardRef, useEffect } from "react";
import {
  Text,
  Button,
  VStack,
  HStack,
  Input,
  Radio,
  ScrollView,
  Checkbox,
} from "native-base";
import { BsArrowRight } from "react-icons/bs";
import { BiTrashAlt } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import { CiCalendar } from "react-icons/ci";
import colors from "theme/colors";
import { ACCOUNT_TYPE_FILTER, STATUS_FILTER } from "global/constants";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AmplitudeHelper from "utils/analytics";
import GroupMenu from "molecules/GroupMenu";
import dayjs from "dayjs";

const CalendarInput = forwardRef(({ value, onClick }, ref) => (
  <HStack
    alignItems="center"
    borderWidth="1"
    borderRadius={4}
    borderColor={colors.disabled.smoke}
    w="320px"
    justifyContent="space-between"
    pr="10px"
    h="35px"
  >
    <Button
      variant="ghost"
      onPress={onClick}
      w="90%"
      justifyContent="flex-start"
      h="30px"
    >
      <Text variant="xs" color={value ? "black" : colors.neutral.darkMist}>
        {value || "Tanggal Tertentu"}
      </Text>
    </Button>
    {!value && <CiCalendar size="20px" />}
  </HStack>
));

const FilterContent = ({
  filterData,
  setFilterData,
  setIsCollapsed,
  resetModal,
}) => {
  const { t } = useTranslation("common");
  const [accountType, setAccountType] = useState([]);
  const [status, setStatus] = useState([]);
  const [group, setGroup] = useState([]);
  const [payDate, setPayDate] = useState([]);
  const [endDate, setEndDate] = useState();
  const [minAmount, setMinAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");

  useEffect(() => {
    if (endDate) {
      setPayDate([new Date(endDate)?.getDate()]);
    }
  }, [endDate]);

  const handleSubmit = () => {
    const data = {};

    if (status.length != 0) {
      const statuses = [];
      if (status.includes(STATUS_FILTER.inactive.code)) {
        statuses.push([
          STATUS_FILTER.withdraw_accrual_suspended.code,
          STATUS_FILTER.withdraw_suspended.code,
        ]);
      }
      statuses.push(status);
      data.employeeStatus = statuses.join(",");
    }
    accountType.length != 0 && (data.accountType = accountType.join(","));
    // payDate == 'one' && (data.payDate = '-1')
    // payDate == 'two' && (data.payDate = endDate.getDate())
    payDate.length != 0 && (data.payDate = payDate.join(","));
    group.length != 0 && (data.employeeGroup = group.join(","));
    minAmount != "" && (data.minAmount = minAmount);
    maxAmount != "" && (data.maxAmount = maxAmount);

    setFilterData(data);

    AmplitudeHelper.logEvent("filter_saved", {
      source: "team_page",
      filter_parameter: Object.keys(data)?.join(","),
      filter_value: Object.values(data)?.join(","),
    });

    if (setIsCollapsed) {
      setIsCollapsed(true);
    }
    if (resetModal) {
      resetModal();
    }
  };

  const handleReset = () => {
    setGroup([]);
    setPayDate([]);
    setAccountType([]);
    setStatus([]);
    setEndDate();
    setMaxAmount("");
    setMinAmount("");

    setFilterData({});
    AmplitudeHelper.logEvent("filter_reset", { source: "team_page" });
    if (setIsCollapsed) {
      setIsCollapsed(true);
    }
    if (resetModal) {
      resetModal();
    }
  };

  return (
    <ScrollView vertical>
      <VStack p="15px" space={2}>
        <Text variant="sm-bold">{t("filter.title")}</Text>
        <Text variant="xs">{t("filter.walletType")}</Text>
        <Checkbox.Group onChange={setAccountType} value={accountType}>
          <HStack space={3}>
            <HStack space={2} alignItems="center">
              <Checkbox value={ACCOUNT_TYPE_FILTER.ewa.code} />
              <Text variant="xs">Akun Gaji</Text>
            </HStack>
            <HStack space={2} alignItems="center">
              <Checkbox value={ACCOUNT_TYPE_FILTER.uangKantor.code} />
              <Text variant="xs">Uang Kantor</Text>
            </HStack>
            <HStack space={2} alignItems="center">
              <Checkbox value={ACCOUNT_TYPE_FILTER.salaryDisbursement.code} />
              <Text variant="xs">Penalangan Gaji</Text>
            </HStack>
          </HStack>
        </Checkbox.Group>
        <Text variant="xs">{t("filter.status")}</Text>
        <Checkbox.Group onChange={setStatus} value={status}>
          <HStack space={3}>
            <HStack space={2} alignItems="center">
              <Checkbox value={STATUS_FILTER.active.code} />
              <Text variant="xs">Aktif</Text>
            </HStack>
            <HStack space={2} alignItems="center">
              <Checkbox value={STATUS_FILTER.inactive.code} />
              <Text variant="xs">Non Aktif</Text>
            </HStack>
          </HStack>
        </Checkbox.Group>
        <Text variant="xs">{t("filter.group")}</Text>
        <GroupMenu value={group} setValue={setGroup} />
        {/* <HStack alignItems="center" space="2" borderWidth={1} borderColor={colors.disabled.smoke} borderRadius={4}>
					<Input placeholder="Cari Disini" flex="0.97" borderWidth={0} value={group} onChange={(x) => setGroup(x.target.value)} />
					<AiOutlineSearch size="20px" />
				</HStack> */}
        <Text variant="xs">{t("filter.payDate")}</Text>
        <Checkbox.Group value={payDate} onChange={setPayDate} flex="1">
          <HStack space={2} alignItems="center" mb="5px">
            <Checkbox value="-1" />
            <Text variant="xs">Akhir Bulanan</Text>
          </HStack>
          <HStack space={2} alignItems="center">
            <Checkbox
              value={new Date(endDate)?.getDate()}
              isDisabled={endDate == null}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                openTo="day"
                views={["year", "month", "day"]}
                value={endDate ? dayjs(endDate) : ""}
                onChange={setEndDate}
                slotProps={{
                  textField: {
                    size: "small",
                    error: false,
                    helperText: endDate ? "" : "Tanggal Tertentu",
                  },
                }}
                renderInput={<CalendarInput />}
              />
            </LocalizationProvider>
          </HStack>
        </Checkbox.Group>
        <Text variant="xs">{t("filter.withdrawLimit")}</Text>
        <HStack
          alignItems="center"
          borderWidth={1}
          borderColor={colors.disabled.smoke}
          borderRadius={4}
          mb="10px"
        >
          <Input
            variant="unstyled"
            placeholder="Rp"
            value={minAmount}
            onChange={(x) => setMinAmount(x.target.value)}
          />
          <BsArrowRight />
          <Input
            variant="unstyled"
            placeholder="Rp"
            value={maxAmount}
            onChange={(x) => setMaxAmount(x.target.value)}
          />
        </HStack>
        <HStack space={3}>
          <Button
            variant="outline"
            flex="0.25"
            borderColor={colors.error.rose}
            leftIcon={<BiTrashAlt size="15px" color={colors.error.rose} />}
            onPress={handleReset}
          >
            <Text variant="sm" color={colors.error.rose}>
              {t("filter.reset")}
            </Text>
          </Button>
          <Button variant="solid" flex="0.75" onPress={handleSubmit}>
            <Text variant="sm" color={colors.neutral.cotton}>
              {t("filter.save")}
            </Text>
          </Button>
        </HStack>
      </VStack>
    </ScrollView>
  );
};

export default FilterContent;
