import colors from 'theme/colors'

const styles = {
	box: {
		backgroundColor: colors.neutral.cotton,
		borderRadius: 12,
	},
	titleGroup: {
		borderBottomWidth: 4,
		borderColor: colors.neutral.mist,
		paddingHorizontal: '15px',
		paddingRight: '5px',
		paddingVertical: '10px',
	},
	title: {
		color: colors.secondary.orchidBlue,
	},

}

export default styles
