import Breadcrumbs from "atoms/Breadcrumbs";
import ContentContainer from "atoms/ContentContainer";
import FormSubmit from "atoms/FormSubmit";
import { VStack, ScrollView } from "native-base";
import PersonalForm from "molecules/PersonalForm";
import EmployeeForm from "molecules/EmployeeForm";
import CreateModal from "molecules/CreateModal";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import AmplitudeHelper from "utils/analytics";
import useConnect from "./connect";

const SingleCreate = () => {
  const { t } = useTranslation("common");
  const {
    formData,
    banksList,
    setFormData,
    handleSendData,
    handleSubmit,
    errors,
    setErrors,
    handleCancel,
  } = useConnect();

  useEffect(() => {
    AmplitudeHelper.logEvent("single_employee_creation_page");
  }, []);

  return (
    <>
      <ContentContainer flex={[1, 1, 1, 0.85, 0.85]}>
        <Breadcrumbs currentPage="sideBar.singleCreate" />
        <ScrollView pb="50px">
          <VStack space={5}>
            <PersonalForm
              errors={errors}
              setErrors={setErrors}
              formData={formData}
              setFormData={setFormData}
            />
            <EmployeeForm
              errors={errors}
              setErrors={setErrors}
              formData={formData}
              setFormData={setFormData}
              banksList={banksList}
            />
          </VStack>
        </ScrollView>
        <FormSubmit handleSubmit={handleSubmit} handleCancel={handleCancel} />
      </ContentContainer>
      <CreateModal handleSendData={handleSendData} />
    </>
  );
};

export default SingleCreate;
