import {
  Button,
  HStack,
  Input,
  Menu,
  Text,
  Pressable,
  // TODO: dummy text added to move to next line eslint errors,
} from "native-base";
import SummaryBox from "atoms/SummaryBox";
import { useEffect, useState } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import WithdrawalTable from "atoms/WithdrawTable";
import { useTranslation } from "react-i18next";
import PaginationMui from "molecules/Pagination";
import NoData from "atoms/NoData";
import colors from "theme/colors";
import { getStore } from "store/storeUtils";
import { TXN_MODALS } from "global/constants";
import AmplitudeHelper from "utils/analytics";

const TransactionPageDownloadSection = ({
  data = {},
  loading = false,
  currentPage = 0,
  setPageNo = () => {},
  totalPageNumber = 0,
  name = "",
  setName = () => {},
}) => {
  const [search, setSearch] = useState(name);
  const { t } = useTranslation("common");
  const { modal, setModal } = getStore();
  const { data: tableData = [] } = data;

  const handleDownload = (shouldFiltersApply) => {
    if (shouldFiltersApply) {
      AmplitudeHelper.logEvent("download_filter_based_clicked", {
        source: "transaction_page",
      });
    } else {
      AmplitudeHelper.logEvent("download_all_clicked", {
        source: "transaction_page",
      });
    }
    setModal({
      ...modal,
      type: TXN_MODALS.export,
      shouldFiltersApply,
    });
  };

  useEffect(() => {
    if (name === "") {
      setSearch("");
    }
  }, [name]);

  const styles = {
    boxContainer: { marginTop: 20, zIndex: -1, elevation: -1 },
  };

  const setSearchText = (event) => {
    setSearch(event.target.value);
    AmplitudeHelper.logEvent("search_clicked", { source: "transaction_page" });
  };

  const searchButtonPress = () => {
    setName(search);
    AmplitudeHelper.logEvent("search_button_clicked", {
      source: "transaction_page",
      keyword: search,
    });
  };

  const eventForOpenDownloadMenu = () => {
    AmplitudeHelper.logEvent("download_clicked", {
      source: "transaction_page",
    });
  };

  return (
    <div style={styles.boxContainer}>
      <SummaryBox title={t("transactionPage.viewTransactions")}>
        <HStack
          w={["100%", "100%", "100%", "50%", "50%"]}
          space={4}
          style={{ padding: 24 }}
        >
          <Input
            variant="home"
            size="xs"
            flex={0.9}
            placeholder={t("transactionPage.enterEmployeeNameOrID")}
            value={search}
            onChange={setSearchText}
          />

          <Button variant="solid" flex={0.1} onPress={searchButtonPress}>
            <Text fontSize="xs" color="white">
              {t("transactionPage.search")}
            </Text>
          </Button>

          <Menu
            w="190"
            onOpen={eventForOpenDownloadMenu}
            trigger={(triggerProps) => {
              return (
                <Pressable
                  accessibilityLabel="Employee Group Filter"
                  {...triggerProps}
                >
                  <HStack
                    alignItems="center"
                    space="2"
                    borderWidth={1}
                    borderColor={colors.disabled.smoke}
                    borderRadius={4}
                    padding={2}
                  >
                    <Text fontSize="xs">
                      {t("transactionPage.downloadData")}
                    </Text>
                    <AiOutlineDownload size="17px" />
                  </HStack>
                </Pressable>
              );
            }}
          >
            <Menu.Item onPress={() => handleDownload(false)}>
              {t("transactionPage.downloadAllData")}
            </Menu.Item>
            <Menu.Item onPress={() => handleDownload(true)}>
              {t("transactionPage.downloadDataWithFilters")}
            </Menu.Item>
          </Menu>
        </HStack>
        {tableData.length === 0 ? (
          <NoData />
        ) : (
          <>
            <WithdrawalTable
              data={data}
              loading={loading}
              currentPage={currentPage}
            />
            <PaginationMui
              currentPage={currentPage}
              setCurrentPage={setPageNo}
              totalPageNumber={totalPageNumber}
            />
          </>
        )}
      </SummaryBox>
    </div>
  );
};

export default TransactionPageDownloadSection;
