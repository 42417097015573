import { FormControl, Input } from "native-base";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import colors from "theme/colors";
import AmplitudeHelper from "utils/analytics";

const ValidatedInput = ({
  title,
  errors,
  label,
  component,
  required,
  value,
  setValue,
  disabled,
  amplitude,
  allowOnlyNumbers = false,
}) => {
  const { t } = useTranslation("common");
  const [input, setInput] = useState("");

  useEffect(() => {
    if (value != "") {
      setInput(value);
    }
  }, [value]);

  const handleInputChange = (newValue) => {
    if (allowOnlyNumbers) {
      const numericValue = newValue.replace(/[^0-9]/g, "");
      setInput(numericValue);
    } else {
      setInput(newValue);
    }
  };

  return (
    <FormControl
      isInvalid={Object.prototype.hasOwnProperty.call(errors, label)}
      isRequired={required}
    >
      <FormControl.Label variant="team">{t(title)}</FormControl.Label>
      {component || (
        <Input
          variant="home"
          value={input}
          minH="40px"
          isDisabled={disabled}
          onChange={(x) => handleInputChange(x.target.value)}
          onFocus={() => {
            AmplitudeHelper.logEvent(`${amplitude}_clicked`, {
              source: "single_employee_creation_page",
            });
          }}
          // _disabled={{
          //   backgroundColor: colors.disabled.smoke,
          // }}- TODO: commented for now as smoke color background for disabled is not reflecting in Datepicker. Discussed this with Alver.
          onBlur={() => {
            if (setValue) {
              AmplitudeHelper.logEvent(`${amplitude}_filled`, {
                source: "single_emloyee_creation_page",
                input,
              });
              setValue(input);
            }
          }}
        />
      )}
      <FormControl.ErrorMessage variant="team">
        {t(errors[`${label}`]) ?? ""}
      </FormControl.ErrorMessage>
    </FormControl>
  );
};

export default ValidatedInput;
