import { SentryHelper } from "utils/performance/SentryHelper";
import apiConfig from "./apiConfig";
import {
  axiosInstance,
  defaultHeaders,
  getInvokeOptionsForHTTPMethod,
  getURLForApiCall,
  shouldAddCustomHeaders,
  shouldAddTokenForAuth,
} from "./networkUtils";

export default async function invokeApi(
  options,
  baseUrl = null,
  data = null,
  customHeaders = null,
  postData = null,
  urlEncodedData = null
) {
  // eslint-disable-next-line global-require, no-unused-vars
  // const URITemplate = require('urijs/src/URITemplate');
  try {
    let reqHeader = {
      ...defaultHeaders,
    };
    reqHeader = { ...reqHeader, ...shouldAddCustomHeaders(customHeaders) };
    const authHeader = await shouldAddTokenForAuth(options);
    reqHeader = { ...reqHeader, ...authHeader };
    let apiRequest = {
      method: options.httpMethod,
      headers: reqHeader,
    };
    apiRequest = getInvokeOptionsForHTTPMethod(
      postData,
      options,
      apiRequest,
      urlEncodedData
    );
    let sUrl = apiConfig.getApiUrl(options.name, baseUrl);
    sUrl = getURLForApiCall(options, sUrl, data);
    return axiosInstance({
      ...apiRequest,
      url: sUrl,
    });
  } catch (error) {
    SentryHelper.captureException(error);
  }
}
