import { fetchSummary } from 'api/useQueryCalls'

export const useConnect = () => {
	const {
		isLoading: summaryLoading,
		isError: summaryIsError,
		isSuccess: summarySuccess,
		error: summaryError,
		data: summaryData,
	} = fetchSummary()

	return { summaryLoading, summaryData }
}
