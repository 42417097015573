import { useTranslation } from 'react-i18next'
import {
	HStack, Text, Button, Input,
} from 'native-base'
import { useState } from 'react'
import AmplitudeHelper from 'utils/analytics'

const SearchBar = ({ onSearch }) => {
	const { t } = useTranslation('common')
	const [search, setSearch] = useState('')

	return (
		<HStack w={['100%', '100%', '100%', '50%', '50%']} space={4}>
			<Input
				variant="home"
				size="md"
				flex={0.9}
				placeholder={t('home.filter.searchPlaceholder')}
				value={search}
				onChange={(e) => setSearch(e.target.value)}
				onFocus={() => AmplitudeHelper.logEvent('search_clicked', { source: 'team_page' })}
			/>
			<Button
				variant="solid"
				height="35px"
				flex={0.1}
				onPress={() => {
					AmplitudeHelper.logEvent('search_button_clicked', { keyword: search, source: 'team_page' })
					onSearch(search)
				}}
			>
				<Text variant="xs" color="white">{t('home.filter.search')}</Text>
			</Button>
		</HStack>
	)
}

export default SearchBar
