/* Login: User Authentication */

import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import {
  VStack,
  HStack,
  Alert,
  Stack,
  Text,
  Center,
  Hidden,
  useToast,
  IconButton,
  CloseIcon,
  Flex,
  Box,
  View,
} from "native-base";
import { BannerDesktop, BannerMobile } from "assets";
import LoginForm from "molecules/LoginForm";
import OtpForm from "molecules/OtpForm";
// import ToastAlert from 'atoms/ToastAlert'
import LoginModal from "molecules/LoginModal";
import colors from "theme/colors";
import { useConnect } from "./connect";
import styles from "./style";

const ToastAlert = ({
  toast,
  id,
  status,
  variant,
  title,
  description,
  isClosable,
  ...rest
}) => {
  const { t } = useTranslation("common");
  return (
    <Hidden from="sm">
      <View position="absolute" top="-10vw" left="-47vw">
        <Flex
          bg={colors.warning.softSunflower}
          borderWidth={1}
          borderColor={colors.warning.sunflower}
          p="10px"
          w="95vw"
        >
          <HStack alignItems="center" space={3} style={{ flexWrap: "wrap" }}>
            <Box variant="ghost" alignSelf="flex-start">
              <Alert.Icon color={colors.warning.sunflower} />
            </Box>
            <VStack style={{ flexWrap: "wrap", flex: 1 }}>
              <Text variant="xs" noOfLines={10}>
                {t(description)}
              </Text>
            </VStack>
            <IconButton
              variant="unstyled"
              icon={<CloseIcon size="3" />}
              _icon={{
                color: "darkText",
              }}
              onPress={() => toast.close(id)}
            />
          </HStack>
        </Flex>
      </View>
    </Hidden>
  );
};

const Login = () => {
  const { t } = useTranslation("common");
  const { loginSuccess } = useConnect();
  const toast = useToast();
  const id = "login-toast";

  useEffect(() => {
    if (!toast.isActive(id)) {
      toast.show({
        render: ({ id }) => (
          <ToastAlert
            toast={toast}
            id={id}
            description="login.warning"
            status="warning"
            variant="solid"
            isClosable
          />
        ),
        placement: "top",
        duration: null,
      });
    }
  }, []);

  return (
    <>
      <Stack
        direction={["column", "column", "column", "row", "row"]}
        align="center"
        justify="center"
      >
        <Hidden till="lg">
          <Center style={styles.imageContainer}>
            <img
              src={BannerDesktop}
              style={styles.image}
              alt="Banner Desktop"
            />
          </Center>
        </Hidden>
        <Hidden from="lg">
          <Center style={styles.imageContainer}>
            <img
              src={BannerMobile}
              style={styles.imageMobile}
              alt="Banner Mobile"
            />
          </Center>
        </Hidden>
        <Center flex={1}>
          {!loginSuccess ? <LoginForm /> : <OtpForm />}
          <Text variant="xs" style={styles.copyright}>
            {t("login.copyright")}
          </Text>
        </Center>
      </Stack>

      <LoginModal />
    </>
  );
};

export default Login;
