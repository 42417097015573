import { fetchEmployeeGroup } from "api/useQueryCalls";
import {
  Text,
  Button,
  HStack,
  VStack,
  Menu,
  ScrollView,
  Checkbox,
  Pressable,
} from "native-base";
import { BsChevronDown } from "react-icons/bs";
import { getStore } from "store/storeUtils";
import colors from "theme/colors";
import AmplitudeHelper from "utils/analytics";

const GroupMenu = ({
  value = [],
  setValue = () => {},
  isError = false,
  amplitude = "",
}) => {
  const { data: employeeGroupData = [], isSuccess: employeeGroupSuccess } =
    fetchEmployeeGroup();
  return (
    <Menu
      closeOnSelect={false}
      trigger={(triggerProps) => (
        <HStack
          alignItems="center"
          borderWidth="1"
          borderRadius={4}
          borderColor={isError ? colors.error.rose : colors.disabled.smoke}
          justifyContent="space-between"
          pr="10px"
          h="35px"
          width="100%"
        >
          <Button
            variant="ghost"
            w="95%"
            justifyContent="space-between"
            h="30px"
            overflow="hidden"
            onPress={() => {
              if (amplitude) {
                AmplitudeHelper.logEvent(`${amplitude}_clicked`, {
                  source: "single_employee_creation_page",
                });
              }
            }}
            {...triggerProps}
          >
            {!value || value.length == 0 ? (
              <Text variant="xs" color={colors.neutral.darkMist}>
                Cari Disini
              </Text>
            ) : (
              <Text variant="xs">
                {value.length == employeeGroupData.length
                  ? "Semua"
                  : value?.join(",")}
              </Text>
            )}
          </Button>
          <BsChevronDown size="17px" />
        </HStack>
      )}
      style={{
        height: "auto",
        maxHeight: "300px",
        width: "auto",
        padding: "15px",
        backgroundColor: colors.neutral.cotton,
      }}
    >
      <ScrollView>
        <VStack space={3}>
          <Checkbox
            isChecked={value.length == employeeGroupData.length}
            onChange={() => {
              if (value.length == employeeGroupData.length) setValue([]);
              else setValue(employeeGroupData);
            }}
          >
            <Text variant="xs">Semua</Text>
          </Checkbox>
          <Checkbox.Group onChange={setValue} value={value}>
            <VStack space={3}>
              {employeeGroupData?.map((item) => (
                <Pressable>
                  <HStack space={2} alignItems="center">
                    <Checkbox value={item} />
                    <Text variant="xs">{item}</Text>
                  </HStack>
                </Pressable>
              ))}
            </VStack>
          </Checkbox.Group>
        </VStack>
      </ScrollView>
    </Menu>
  );
};

export default GroupMenu;
