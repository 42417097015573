import * as Sentry from "@sentry/browser";
import { CaptureConsole } from "@sentry/integrations";
import { beforeBreadCrumbEvent } from "./SentryHelper";
import config from "../../config";

function initializePerformanceTool() {
  Sentry.init({
    dsn: "https://cc1f07bd99f48f909f871e165ad6d956@o4505288154021888.ingest.sentry.io/4505980546842624",
    debug: false,
    maxBreadcrumbs: 150, // Extend from default 100 breadcrumbs.
    environment: config.SENTRY_ENV,
    // Release Health
    enableAutoSessionTracking: true,
    // For testing, session close when 5 seconds (instead of the default 30) in the background.
    sessionTrackingIntervalMillis: 5000,
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    enableTracing: true,
    tracePropagationTargets: ["localhost", config.API_URL],
    integrations: [
      new CaptureConsole({ levels: ["error"] }),
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing({
        traceFetch: true,
      }),
      // new Sentry.Replay(),
    ],
    beforeBreadcrumb: beforeBreadCrumbEvent(),
  });
}

export default initializePerformanceTool;
