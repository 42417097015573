import { Center, Text } from "native-base";
import { GoInbox } from "react-icons/go";
import colors from "theme/colors";
import { useTranslation } from "react-i18next";

const NoData = () => {
  const { t } = useTranslation("common");
  return (
    <Center m="15px" my="90px">
      <GoInbox size="50px" color={colors.neutral.darkMist} />
      <Text mt="10px" variant="sm" color={colors.neutral.darkMist}>
        {t("filter.noData")}
      </Text>
    </Center>
  );
};

export default NoData;
